import React from "react";
import { Grid, Modal, Box } from "@mui/material";
import {
  DeploymentsByUsers,
  DeploymentsByPeriod,
  DeploymentsCalendarView,
  DeploymentsByStatus,
  DashboardStats,
} from "../components/charts";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  margin: 0,
  padding: 0,
  p: 4,
};

const Dashboard = () => {
  const [open, setOpen] = React.useState('');
  const handleClose = () => setOpen('');
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <DashboardStats />
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DeploymentsCalendarView />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DeploymentsByStatus setOpen={setOpen} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DeploymentsByUsers setOpen={setOpen} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DeploymentsByPeriod />
        </Grid>
      </Grid>
      {open && <Modal
        open={open ? true : false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {open === 'user' && <DeploymentsByUsers popup setOpen={setOpen} />}
          {open === 'status' && <DeploymentsByStatus popup setOpen={setOpen} />}
        </Box>
      </Modal>}
    </div>
  );
};

export default Dashboard;
