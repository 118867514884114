import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";

const SearchItemsPreview = ({ filterItems, extraFilters, setExtraFilters }) => {
  const handleRemovefilterItem = (item) => {
    const newFilterItems = extraFilters.filter((i) => {
      return i.columnField !== item.columnField;
    });
    setExtraFilters(newFilterItems);
  };

  return (
    <div style={extraFilters?.length ? { marginBottom: "16px" } : {}}>
      {extraFilters?.length
        ? extraFilters.map((i) => (
            <span key={i.columnField}>
              {i.displayValue ? (
                <span
                  style={{
                    display: "inline-block",
                    background: "#ececec",
                    padding: "4px 12px 4px 8px",
                    fontSize: "0.8em",
                    marginTop: "2px",
                    marginRight: "2px",
                    borderRadius: "5px",
                    position: "relative",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>{i?.label}</div>
                  <div style={{ display: "block", overflowWrap: "anywhere" }}>
                    {i?.displayValue}
                  </div>
                  <SearchItemClearButton
                    item={i}
                    onClear={handleRemovefilterItem}
                  />
                </span>
              ) : null}
            </span>
          ))
        : null}
    </div>
  );
};

export default SearchItemsPreview;

const SearchItemClearButton = ({ item, onClear }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <CancelIcon
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        position: "absolute",
        top: "1px",
        right: "1px",
        width: "0.5em",
        height: "0.5em",
        transform: isHovered ? "scale(1.2)" : "scale(1.0)",
      }}
      onClick={() => {
        onClear?.(item);
      }}
    />
  );
};
