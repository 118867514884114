import React, { Fragment, useContext } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Skeleton,
} from "@mui/material";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { grey } from "@mui/material/colors";
import { getStatsForDashboard } from "../../services/Dashboard";
import { WizardContext, ACTIONS } from "../wizard/Context";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { useTheme } from '@mui/material/styles';
import { MODULES } from "../../services/Constants";
import useModule from "../../hooks/useModule";
import dualbg from '../../images/dual-bg.svg';
import DottedTile from "../common/DottedTile";

const DashboardStats = () => {
  const { validateModuleWithMessage } = useModule();
  const { dispatch } = useContext(WizardContext);
  const theme = useTheme();
  const { data, isLoading } = useQuery(
    "getStatsForDashboard",
    getStatsForDashboard,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Fragment>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/vehicles`}>
          <Card>
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
              {isLoading ? (
                <Skeleton animation="wave" />
              ) : (
                <Typography color="text.secondary">
                  Vehicles Provisioned
                </Typography>
              )}
              {isLoading ? (
                <Skeleton animation="wave" height={90} width={60} />
              ) : (
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.vehicles ? data.vehicles : "-"}
                </Typography>
              )}
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/images`}>
          <Card>
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
              {isLoading ? (
                <Skeleton animation="wave" />
              ) : (
                <Typography color="text.secondary">Images uploaded</Typography>
              )}
              {isLoading ? (
                <Skeleton animation="wave" height={90} width={60} />
              ) : (
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.images ? data.images : "-"}
                </Typography>
              )}
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/releases`}>
          <Card>
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
              {isLoading ? (
                <Skeleton animation="wave" />
              ) : (
                <Typography color="text.secondary">Releases</Typography>
              )}
              {isLoading ? (
                <Skeleton animation="wave" height={90} width={60} />
              ) : (
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.releases ? data.releases : "-"}
                </Typography>
              )}
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/deployments`}>
          <Card>
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
              {isLoading ? (
                <Skeleton animation="wave" />
              ) : (
                <Typography color="text.secondary">Deployments</Typography>
              )}
              {isLoading ? (
                <Skeleton animation="wave" height={90} width={60} />
              ) : (
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.deployments ? data.deployments : "-"}
                </Typography>
              )}
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Check I={CAN.CREATE} a={SUBJECTS.DEPLOYMENT}>
        <Grid item xs={12} sm={6} md={2}>
          <CardActionArea
            onClick={() => {
              if (validateModuleWithMessage(MODULES.OTA)) {
                dispatch({
                  type: ACTIONS.TOGGLE_WIZARD,
                  payload: {
                    showWizard: true,
                  },
                })
              }
            }
            }
          >
            <DottedTile title={"CREATE A DEPLOYMENT"}>
              <RocketLaunchIcon
                sx={{ fontSize: "5.4rem", color: grey[500] }}
              />
            </DottedTile>
          </CardActionArea>
        </Grid>
      </Check>
    </Fragment >
  );
};

DashboardStats.defaultProps = {};

DashboardStats.propTypes = {};

export default DashboardStats;
