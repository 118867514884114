import React from "react";
import { Grid, Card, CardContent, Typography, Skeleton } from "@mui/material";
import { getStatsForVehiclesView } from "../../services/vehicle";
import { useTheme } from '@mui/material/styles';
import { useQuery } from "react-query";
import InfoTile from "../common/InfoTile";
import styled from "styled-components";

const SkeletonWrapper = styled.div`
  margin-top:4%;
`;

const VehicleStats = () => {
  const theme = useTheme();
  const { data, isLoading, isFetching } = useQuery(
    "getStatsForDashboard",
    getStatsForVehiclesView,
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <>
      <Grid item xs={6} md={3}>
        <InfoTile
          title={"Visteon Provisioned"}
          info={data?.visteonProvisioned}
          isLoading={isLoading || isFetching}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <InfoTile
          title={"Customer Provisioned"}
          info={data?.customerProvisioned}
          isLoading={isLoading || isFetching}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <Card>
          <CardContent className="tile-cards">
            <Grid container>
              <Grid item xs={6}>
                {isLoading || isFetching ?
                  <>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      sx={{ fontSize: 14 }}
                      width={200}
                    />
                    <SkeletonWrapper>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={100}
                        height={44}
                      />
                    </SkeletonWrapper>
                  </>
                  :
                  <>
                    <Typography color="text.secondary">
                      Active vehicles
                    </Typography>
                    <Typography
                      className="tile-number"
                      color={theme.palette.primary['A700']}
                      sx={{ fontSize: 30, fontWeight: 400 }}
                    >
                      {data?.activeVehicles}
                    </Typography>
                  </>
                }
              </Grid>
              <Grid item xs={6}>
                {isLoading || isFetching ?
                  <>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      sx={{ fontSize: 14 }}
                      width={200}
                    />
                    <SkeletonWrapper>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={100}
                        height={44}
                      />
                    </SkeletonWrapper>
                  </>
                  :
                  <>
                    <Typography color="text.secondary">
                      Inactive vehicles
                    </Typography>
                    <Typography
                      className="tile-number"
                      color={theme.palette.primary['A700']}
                      sx={{ fontSize: 30, fontWeight: 400 }}
                    >
                      {data?.inActiveVehicles}
                    </Typography>
                  </>
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} md={2}>
        <InfoTile
          title={"Subscriptions(Vehicles)"}
          info={data?.vehiclesWithActiveSubscriptions}
          isLoading={isLoading || isFetching}
        />
      </Grid>
    </>
  );
};

export default VehicleStats;
