export const CAN = {
  READ: "read",
  CREATE: "create",
  MANAGE: "manage",
  PUBLISH: "publish",
  UPDATE: "update",
  CANCEL: "cancel",
};

export const SUBJECTS = {
  DEPLOYMENT: "deployment",
  PROVISION: "provision",
  CONFIGURATION: "configuration",
  USER: "user",
  IMAGE: "image",
  RELEASE: "release",
  GROUP: "group",
  ALL: "all",
};

export const USER_GROUPS = {
  ADMIN: "ota-admin-group",
  GUEST: "ota-guest-group",
  CAMPAIGN_CREATOR: "ota-campaign-creator",
  CAMPAIGN_APPROVER: "ota-campaign-approver",
};

