import React, { Fragment, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useQuery } from "react-query";
import {
  TextField,
  MenuItem,
  FormControl,
  Select,
  Card,
  CardHeader,
  CardContent,
  Typography,
  InputLabel,
  Paper,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getDeploymentsByPeriod } from "../../services/Dashboard";
import { getLast365DaysDateRange } from "../../services/Utils";
import { useTheme } from "@mui/material/styles";
import moment from "moment";

const PRIMARY_OPTIONS = [
  { value: "last_365_days", label: "Last 365 Days" },
  { value: "current_year", label: "This Year" },
  { value: "date_range", label: "Date Range" },
];

const DeploymentsByPeriod = () => {
  const last365DaysDateRange = getLast365DaysDateRange();
  const [period, setPeriod] = useState(new Date().getFullYear());
  const [primaryFilter, setPrimaryFilter] = useState("last_365_days");
  const handlePrimaryFilterChange = ({ target: { value } }) => {
    setPrimaryFilter(value);
    if (value === "current_year") {
      setPeriod(new Date().getFullYear());
    } else if (value === "date_range") {
      setPeriod(typeof period === 'number' ? last365DaysDateRange : period);
    } else if (value === "last_365_days") {
      setPeriod(last365DaysDateRange);
    }
  };

  const handleDateChange = (newValue, type) => {
    setPeriod({
      ...period,
      ...(type === "start" && { from: newValue }),
      ...(type === "end" && { to: newValue }),
    });
  };

  const theme = useTheme();
  const commonProperties = {
    margin: { top: 80, right: 120, bottom: 80, left: 120 },
    colors: [theme.custom.barChartColor],
    animate: false,
    activeOuterRadiusOffset: 8,
    legends: [
      {
        dataFrom: "keys",
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 1,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ],
    axisLeft: { tickValues: [] },
    axisBottom:
      !!primaryFilter && primaryFilter === "date_range"
        ? null
        : { tickRotation: -60 }, // null hides the bottom label // { tickRotation: -60 } use this to rotate bottom label,
    enableLabel:
      !!primaryFilter && primaryFilter === "date_range" ? false : true,
  };

  const getTickValues = (data) => {
    if (data && data.length) {
      const maxValue = Math.max(...data.map((item) => item.value));
      const result = Array(maxValue + 1)
        .fill()
        .map((_, ndx) => ndx);
      return result;
    }
  };

  const validatePeriodData = () => {
    if (primaryFilter === 'date_range') {
      return !!(period?.from && period?.to);
    }
    return true;
  }

  const { data = [], isLoading } = useQuery(
    ["deploymentsByPeriod", primaryFilter, period],
    getDeploymentsByPeriod,
    {
      refetchOnWindowFocus: false,
      enabled: validatePeriodData()
    }
  );

  const getDataForLast365Days = (items) => {
    const getMonthAndYear = (dtString) => {
      return moment(new Date(dtString)).format("MMM YY");
    };
    const sumMonthsValue = (months) => {
      return months?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      );
    };
    const monthsData = items.reduce((acc, obj) => {
      const key = getMonthAndYear(obj["id"]);
      const curGroup = acc[key] ?? [];
      return { ...acc, [key]: [...curGroup, obj] };
    }, {});
    const finalData = Object.keys(monthsData)?.map((key) => ({
      id: key,
      value: sumMonthsValue(monthsData[key]),
    }));
    return finalData;
  };

  const CustomTooltip = ({ data: { id, value }, color }) => {
    return (
      <Paper
        style={{
          padding: "6px",
        }}
      >
        <div>
          <Typography
            color="text.primary"
            sx={{ fontSize: 16, textAlign: "right" }}
          >
            {id}
          </Typography>
          <Typography
            color="text.secondary"
            sx={{ fontSize: 12, textAlign: "right" }}
          >{` ${value} deployment${value > 1 ? "s" : ""}`}</Typography>
        </div>
      </Paper>
    );
  };

  const disablePastOneYearDates = (date) => {
    const date1 = moment(new Date(period.to)).subtract(1, 'year');
    const date2 = moment(new Date(date));
    return date2.isSameOrBefore(date1);
  }

  return (
    <Card>
      <CardHeader
        title={
          <Typography color="text.secondary">Deployments by period</Typography>
        }
        action={
          <Fragment>
            {primaryFilter === "date_range" && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <DatePicker
                    label="From"
                    value={period?.from}
                    onChange={(val) => handleDateChange(val, "start")}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        id="standard-basic"
                        variant="standard"
                      />
                    )}
                    required
                    inputFormat="dd-MM-yyyy"
                    disableFuture
                    shouldDisableDate={disablePastOneYearDates}
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <DatePicker
                    label="To"
                    value={period?.to}
                    onChange={(val) => handleDateChange(val, "end")}
                    disableFuture
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        id="standard-basic"
                        variant="standard"
                      />
                    )}
                    minDateTime={period?.from}
                    required
                    inputFormat="dd-MM-yyyy"
                  />
                </FormControl>
              </LocalizationProvider>
            )}
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Filter
              </InputLabel>
              <Select
                label="Filter"
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={primaryFilter}
                onChange={handlePrimaryFilterChange}
              >
                {PRIMARY_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Fragment>
        }
      ></CardHeader>
      <CardContent style={{ height: "350px" }}>
        {data.length > 0 ? (
          <>
            {!isLoading && (
              <ResponsiveBar
                data={data || []}
                {...commonProperties}
                tooltip={CustomTooltip}
              />
            )}
          </>
        ) : (
          <>
            {!isLoading && (
              <ResponsiveBar
                data={[]}
                {...commonProperties}
                tooltip={CustomTooltip}
              />
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

DeploymentsByPeriod.defaultProps = {};

DeploymentsByPeriod.propTypes = {};

export default DeploymentsByPeriod;
