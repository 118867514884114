import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import CancelVehicleDeployment from "./CancelVehicleDeployment";
import { Button, Tooltip } from "@mui/material";

const getColumnsData = ({ vehicleId, deploymentId, type }) => {
  return [
    {
      field: "deploymentId",
      headerName: "Deployment ID",
      flex: 2,
      editable: false,
      renderCell: () => {
        if (vehicleId) {
          return deploymentId;
        }
      },
    },
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 2,
      renderCell: (vehicle) => {
        const { row = {} } = vehicle;
        return (
          <Tooltip title={row.serialNo}>
            <Button
              size="medium"
              disabled={!row?.vehicleKey}
              sx={{
                justifyContent: "flex-start",
                textTransform: "none",
              }}
              component={RouterLink}
              to={`/vehicles/${row.serialNo}/deployments/${deploymentId}`}
            >
              {row.serialNo}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      flex: 6,
      editable: false,
      renderCell: (vehicle) => {
        if (!vehicleId) {
          return vehicle.vehicleKey;
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
      renderCell: (vehicle) => {
        const { row: { jobExecutionSummary = {} } = {} } = vehicle || {};
        return jobExecutionSummary?.status ? jobExecutionSummary?.status : "-";
      },
    },
    {
      field: "group",
      headerName: "Group",
      flex: 1,
      editable: false,
      renderCell: (data) => {
        const { row: vehicle = {} } = data || {};
        if (type === "deployment") {
          if (!!vehicle?.vehicleGroupName && !!vehicle?.vehicleGroupId) {
            return (
              <Tooltip title={vehicle?.vehicleGroupName}>
                <Button
                  disabled={vehicle?.vehicleGroupIsDeleted}
                  size="medium"
                  sx={{
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  component={RouterLink}
                  to={`/groups/${vehicle.vehicleGroupId}/deployments/${deploymentId}`}
                >
                  {vehicle.vehicleGroupName}
                </Button>
              </Tooltip>
            );
          } else {
            return "-";
          }
        }
      },
    },
    {
      field: "queue",
      headerName: "Queue At",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (vehicle) => {
        const { row: { jobExecutionSummary = {} } = {} } = vehicle || {};
        return jobExecutionSummary?.queuedAt ? (
          <DateAndTimeGridCell value={jobExecutionSummary?.queuedAt} />
        ) : (
          "-"
        );
      },
    },
    {
      field: "started",
      headerName: "Started At",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (vehicle) => {
        const { row: { jobExecutionSummary = {} } = {} } = vehicle || {};
        return jobExecutionSummary?.startedAt ? (
          <DateAndTimeGridCell value={jobExecutionSummary?.startedAt} />
        ) : (
          "-"
        );
      },
    },
    {
      field: "lastUpated",
      headerName: "Last Updated At",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (vehicle) => {
        const { row: { jobExecutionSummary = {} } = {} } = vehicle || {};
        return jobExecutionSummary?.lastUpdatedAt ? (
          <DateAndTimeGridCell value={jobExecutionSummary?.lastUpdatedAt} />
        ) : (
          "-"
        );
      },
    },
    {
      field: "none",
      headerName: "Actions",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (data) => <CancelVehicleDeployment data={data} />,
    },
  ];
};

export { getColumnsData };
