import React, { useState } from "react";
import {
  Grid,
  CardActionArea,
} from "@mui/material";
import { getStatsForVehicleGroupsView } from "../../services/vehicle";
import { useQuery } from "react-query";
import { grey } from "@mui/material/colors";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import SpokeIcon from "@mui/icons-material/Spoke";
import CreateVehicleGroup from "./group/CreateVehicleGroup";
import CreateVehicleGroupFromCsv from "./group/CreateVehicleGroupFromCsv";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DottedTile from "../common/DottedTile";
import InfoTile from "../common/InfoTile";

const VehicleGroupsStats = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [openModalCsv, setOpenModalCsv] = useState(false);
  const handleOpenCsv = () => setOpenModalCsv(true);
  const handleOpenCsvClose = () => setOpenModalCsv(false);

  const { data, isLoading, isFetching } = useQuery(
    "getStatsForVehicleGroupsView",
    getStatsForVehicleGroupsView,
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <>
      <Grid item xs={6} md={2}>
        <InfoTile
          title={"Total Vehicle Groups"}
          info={data?.totalGroups || "0"}
          isLoading={isLoading || isFetching}
        />
      </Grid>
      <Check I={CAN.CREATE} a={SUBJECTS.GROUP}>
        <Grid item xs={6} md={2}>
          <CardActionArea onClick={handleOpen}>
            <DottedTile title={"CREATE A GROUP"}>
              <SpokeIcon sx={{ fontSize: "3.5rem", color: grey[500] }} />
            </DottedTile>
          </CardActionArea>
        </Grid>
        <Grid item xs={6} md={2}>
          <CardActionArea onClick={handleOpenCsv}>
            <DottedTile title={"CREATE A GROUP FROM CSV"}>
              <CloudUploadIcon
                sx={{ fontSize: "3.5rem", color: grey[500] }}
              />
            </DottedTile>
          </CardActionArea>
        </Grid>
      </Check>
      <Grid item xs={3}></Grid>
      {openModal && (
        <CreateVehicleGroup
          open={openModal}
          toggleDialog={() => setOpenModal(!openModal)}
        />
      )}
      {openModalCsv && (
        <CreateVehicleGroupFromCsv
          open={openModalCsv}
          toggleDialog={() => setOpenModalCsv(!openModalCsv)}
        />
      )}
    </>
  );
};

export default VehicleGroupsStats;
