import React, { useState, useEffect } from "react";
import { Alert, Box, Grid, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useToast } from "../../toast";
import { getVehicleShadow } from "../../../services/vehicle";
import VehicleShadowList from "./VehicleShadowList";
import { detailsTabsBoxStyle } from "../../../services/Utils";
import { getConfigItems } from "./shadowUtils";
import Check from "../../../auth/ability";
import { CAN, SUBJECTS } from "../../../auth/permissions";
import { updateFeatureStatus } from "../../../services/configuration";

const FeatureWrapper = styled.div`
  margin: 4% 0
`;

const VehicleFeatures = ({ vehicleId, vehicleData }) => {
  const { addToast } = useToast();
  const [shadowState, setShadowState] = useState({});
  const [activeFeature, setActiveFeature] = useState('');
  let configItems = [];
  const { features = [], device: { vehicleKey = '', serialNo = '' } = {} } = vehicleData;

  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const {
    data: { data: shadowData = {} } = {},
    isLoading: getShadowLoading,
    refetch,
  } = useQuery(["getVehicleShadow", vehicleId], getVehicleShadow, {
    refetchOnWindowFocus: false,
    retry: 1,
  });

  // Mutations
  const {
    mutate: updateFeature,
    isLoading: updateFeatureLoading
  } = useMutation(updateFeatureStatus, {
    onSuccess: () => {
      addToast({
        type: "success",
        message: `Feature status updated!`,
        autoClose: 3000,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["vehicleDetails", serialNo]);
      queryClient.invalidateQueries(["getVehicleShadow", serialNo]);
    },
  });

  const {
    state: {
      desired: {
        features: desiredConf = {}
      } = {},
    } = {}
  } = shadowState;

  useEffect(() => {
    if (shadowData && shadowData.payload) {
      const shadowPayload = JSON.parse(shadowData?.payload);
      setShadowState(shadowPayload);
    }
  }, [shadowData]);

  const processFeature = (featureName, active) => {
    setActiveFeature(featureName);
    updateFeature({
      vehicleKey,
      features: [
        {
          name: featureName,
          enabled: !active
        }
      ],
      pos: 'Portal',
    });
  }

  if (getShadowLoading) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="info">
          Fetching shadow properties...
        </Alert>
      </Box>
    );
  }

  if (!vehicleData?.device?.certAvailable && !getShadowLoading) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="error">
          Device setup incomplete!
        </Alert>
      </Box>
    );
  }

  if (!getShadowLoading && !shadowData.payload) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="warning">
          Vehicle shadow not found!
        </Alert>
      </Box>
    );
  } else {
    configItems = getConfigItems(shadowState, 'features');
  }

  if (Object.keys(desiredConf).length === 0) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="warning">
          Features not found!
        </Alert>
      </Box>
    );
  }

  const getFeatureActions = () => {
    return features.map(e =>
      <FeatureWrapper>
        <Button onClick={() => processFeature(e.feature.featureName, e.active)} variant={e.active ? 'outlined' : 'contained'}
          disabled={
            (activeFeature === e.feature.featureName && updateFeatureLoading)
              ? true : false
          }>
          {
            (activeFeature === e.feature.featureName && updateFeatureLoading) ?
              `Loading...` : e.active ? `Disable ${e.feature.featureName}` : `Enable ${e.feature.featureName}`
          }

        </Button>
      </FeatureWrapper>
    );
  }

  return (
    <Box {...detailsTabsBoxStyle}>
      {getShadowLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box {...detailsTabsBoxStyle}>
              <Alert variant="outlined" severity="info">
                OEM defined configurations, enable or disable features ondemand
              </Alert>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <VehicleShadowList configItems={configItems} refetch={refetch} />
          </Grid>
          <Check I={CAN.MANAGE} a={SUBJECTS.CONFIGURATION}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" gutterBottom>
                Configurations Action
              </Typography>
              {getFeatureActions()}
            </Grid>
          </Check>
        </Grid>
      )}
    </Box >
  );
};

export default VehicleFeatures;
