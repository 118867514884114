import React, { Fragment } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  CardContent,
  Card,
  CardHeader,
  Button,
  Grid,
} from "@mui/material";
import {
  getUserActivityByUserId,
  getCurrentUserActivity,
} from "../../services/Users";
import {
  formatTimeStampFromNow,
  formatTimeStamp,
  getInitialsForAvatar,
} from "../../services/Utils";
import { COMPONENTS } from "../../services/Constants";

export default function UserActivityList({
  showItems,
  showHeader,
  data,
  isMyProfile = false,
}) {
  const navigate = useNavigate();
  const { data: userActivity = [] } = useQuery(
    ["userActivity", data?.userId],
    isMyProfile ? getCurrentUserActivity : getUserActivityByUserId,
    {
      refetchOnWindowFocus: false,
    }
  );

  const userModuleActivityPhrase = ({
    component,
    subjectId,
    subject,
    action,
    description,
  }) => {
    let componentPath = "";
    switch (component) {
      case COMPONENTS.Deployment:
        componentPath = `deployments`;
        break;
      case COMPONENTS.Image:
        componentPath = `images`;
        break;
      case COMPONENTS.PrimaryDevice:
        componentPath = `vehicles`;
        break;
      case COMPONENTS.Release:
        componentPath = `releases`;
        break;
      case COMPONENTS.VehicleGroup:
        componentPath = `groups`;
        break;
      case COMPONENTS.User:
        componentPath = `users`;
        break;
      default:
        componentPath = `${component} `;
        break;
    }

    const parts = description?.split(subject);
    return (
      <>
        {parts?.[0]}
        <Link to={!!subjectId ? `/${componentPath}/${subjectId}` : ""}>
          {subject}
        </Link>
        {parts?.[1]}
      </>
    );
  };

  const SubjectLink = ({
    component,
    subjectId,
    subject,
    action,
    description,
  }) => {
    const activityProps = {
      component,
      subjectId,
      subject,
      action,
      description,
    };

    return (
      <div
        style={{
          paddingBottom: "1em",
        }}
      >
        {userModuleActivityPhrase(activityProps)}
      </div>
    );
  };

  const ActivityItem = ({ item }) => {
    return (
      <>
        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar children={getInitialsForAvatar(data?.userName)} />
          </ListItemAvatar>
          <ListItemText
            primary={<SubjectLink {...item} />}
            secondary={
              <>
                <div>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  ></Typography>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div>{formatTimeStamp(item.actionTime)}</div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div style={{ textAlign: "right" }}>
                      {formatTimeStampFromNow(item.actionTime)}
                    </div>
                  </Grid>
                </Grid>
              </>
            }
          />
        </ListItem>
      </>
    );
  };

  return (
    <Card>
      {showHeader && (
        <CardHeader
          title={
            <Typography color="text.secondary">Recent Activities</Typography>
          }
          action={
            userActivity && userActivity.length ? (
              <Fragment>
                <Button
                  variant="text"
                  onClick={() => {
                    const newPath = isMyProfile
                      ? `/users/myprofile/activity`
                      : `/users/${data?.userId}/activity`;
                    navigate(newPath, {
                      replace: true,
                    });
                  }}
                >
                  View All
                </Button>
              </Fragment>
            ) : null
          }
        ></CardHeader>
      )}

      <CardContent>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {userActivity && userActivity.length ? (
            <>
              {showItems
                ? userActivity
                    .slice(0, showItems)
                    .map((i) => <ActivityItem item={i} />)
                : userActivity.map((i) => <ActivityItem item={i} />)}
            </>
          ) : (
            <div>No activities</div>
          )}
        </List>
      </CardContent>
    </Card>
  );
}
