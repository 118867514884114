import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Tooltip, Button } from "@mui/material";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import CancelVehicleDeployment from "./CancelVehicleDeployment";

// New Deployment List Columns
const getColumnsData = () => {
  return [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 2,
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "vehicleGroupName",
      headerName: "Group Name",
      flex: 1.5,
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (data) => {
        const { row: vehicle = {} } = data || {};
        if (!!vehicle?.vehicleGroupName && !!vehicle?.vehicleGroupId) {
          return vehicle?.vehicleGroupName;
        } else {
          return "-";
        }
      },
    },
    {
      field: "jobExecutionStatus",
      headerName: "Status",
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Started At",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (vehicle) => {
        const { row: { createdAt = "" } = {} } = vehicle || {};
        return createdAt ? <DateAndTimeGridCell value={createdAt} /> : "-";
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated At",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (vehicle) => {
        const { row: { updatedAt = "" } = {} } = vehicle || {};
        return updatedAt ? <DateAndTimeGridCell value={updatedAt} /> : "-";
      },
    },
    {
      field: "none",
      headerName: "Actions",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (data) => <CancelVehicleDeployment data={data} />,
    },
  ];
};

// Old Deployment List Columns
const getColumnsDataV1 = () => {
  return [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 2,
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "vehicleGroupName",
      headerName: "Group Name",
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (data) => {
        const { row: vehicle = {} } = data || {};
        if (!!vehicle?.vehicleGroupName && !!vehicle?.vehicleGroupId) {
          return vehicle?.vehicleGroupName;
        } else {
          return "-";
        }
      },
    },
    {
      field: "jobExecutionSummaryStatus",
      headerName: "Status",
      flex: 2,
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (data) => {
        return data?.row?.jobExecutionSummary?.status;
      },
    },
    {
      field: "jobExecutionSummaryQueuedAt",
      headerName: "Started At",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (vehicle) => {
        const { row: { jobExecutionSummary: { queuedAt = "" } = {} } = {} } =
          vehicle || {};
        return queuedAt ? <DateAndTimeGridCell value={queuedAt} /> : "-";
      },
    },
    {
      field: "jobExecutionSummaryLastUpdatedAt",
      headerName: "Last Updated At",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (vehicle) => {
        const {
          row: { jobExecutionSummary: { lastUpdatedAt = "" } = {} } = {},
        } = vehicle || {};
        return lastUpdatedAt ? (
          <DateAndTimeGridCell value={lastUpdatedAt} />
        ) : (
          "-"
        );
      },
    },
    {
      field: "none",
      headerName: "Actions",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (data) => <CancelVehicleDeployment data={data} />,
    },
  ];
};

export { getColumnsData, getColumnsDataV1 };
