import React from "react";
import { Paper, Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import { getJobDocument } from "../../services/deployment";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import ReactJson from "react-json-view";

const DeploymentJobDocumentTab = () => {
  const params = useParams();
  const { deploymentId = "" } = params;

  // Queries
  const {
    data: { data: { document: jobDocument = null } = {} } = {},
    isLoading,
  } = useQuery(["jobDocument", deploymentId], getJobDocument);

  const PrettyPrintJson = (document) => {
    if (document && document.data) {
      try {
        const jobDocument = JSON.parse(document.data);
        if (Object.keys(jobDocument).length > 0) {
          return (
            <div className="job-document-container">
              <ReactJson
                name={false}
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
                collapsed={false}
                src={jobDocument}
              />
            </div>
          );
        }
      } catch (e) {
        <Alert variant="outlined" severity="warning">
          Invalid Document
        </Alert>;
      }
    }
    return (
      <Alert variant="outlined" severity="warning">
        Document not found!
      </Alert>
    );
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Paper elevation={0} sx={{ overflow: "auto", maxHeight: 700 }}>
          <PrettyPrintJson data={jobDocument}></PrettyPrintJson>
        </Paper>
      )}
    </>
  );
};

DeploymentJobDocumentTab.defaultProps = {};

DeploymentJobDocumentTab.propTypes = {};

export default DeploymentJobDocumentTab;
