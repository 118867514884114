import React, { useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DialogContentText, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { DataGrid } from "@mui/x-data-grid";
import { createGroup, getVehicleListFromCsv } from "../../../services/vehicle";
import { Box } from "@mui/material";
import { useToast } from "../../toast";
import FileUploadPickerPane from "../../image/FileUploadPickerPane";
import {
  FILE_TYPES,
  VEHICLE_GROUP_NAME_REGEX,
} from "../../../services/Constants";
import styled from "styled-components";

const CreateVehicleGroupFromCsv = (props) => {
  const { open, toggleDialog } = props;

  //const [file, setFile] = useState();
  const [vehicleList, setVehicleList] = useState("");
  //const [fileName, setFileName] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [vehicleGroupName, setVehicleGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState();
  const [error, setError] = useState("");

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const ActiveText = styled.span`
    color: #43a047;
  `;

  const InactiveText = styled.span`
    color: #c1c1c1;
  `;

  const onFileSelected = async (selectedFileData) => {
    //setIsFilePicked(true);
    fetchVehicleListFromCsv(selectedFileData, selectedFileData.name);
  };

  const onGroupNameChange = ({ target }) => {
    if (!target?.value.match(VEHICLE_GROUP_NAME_REGEX)) {
      setError("");
      setVehicleGroupName(target?.value); // only set when successful
    } else {
      setError("Special characters are not allowed, except (-) hyphens");
    }
  };

  const columns = [
    {
      field: "serialNo",
      headerName: "SerialNo",
      width: 250,
      disableColumnMenu: true,
    },
    {
      field: "vehicleKey",
      headerName: "VehicleKey",
      width: 250,
      disableColumnMenu: true,
    },
    {
      field: "valid",
      headerName: "Status",
      width: 120,
      disableColumnMenu: true,
      renderCell: ({ row }) =>
        row.valid ? (
          <ActiveText>Valid</ActiveText>
        ) : (
          <InactiveText>Invalid</InactiveText>
        ),
    },
    {
      field: "msg",
      headerName: "Message",
      width: 350,
      disableColumnMenu: true,
    },
  ];

  // Access the client
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createGroup, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries("vehicleGroup");
      queryClient.invalidateQueries("vehicleGroupsList");
      queryClient.invalidateQueries("getStatsForVehicleGroupsView");
    },
  });

  const handleCreate = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    mutate(
      {
        vehicleGroupName: vehicleGroupName,
        description: groupDesc,
        vehicleKeys: vehicleList
          .filter((item) => item.valid)
          .map((item) => item.vehicleKey),
      },
      {
        onSuccess: (group) => {
          toggleDialog();
          addToast({
            type: "success",
            message: `Group ${group?.data?.vehicleGroupName} created successfully.`,
            autoClose: 3000,
          });
        },
        onError: (e) => {
          const { response } = e;
          addToast({
            type: "error",
            message: response?.data?.message || "Failed to create",
            autoClose: 3000,
          });
        },
        onSettled: () => {
          setLoading(false);
        },
      }
    );
  };

  const fetchVehicleListFromCsv = async (file, fileName) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    try {
      const res = await getVehicleListFromCsv(formData);
      setVehicleList(res.data);
      setIsFilePicked(true);
    } catch (error) {
      console.log("error", error);
      addToast({
        type: "error",
        message: error?.response?.data?.message || "Invalid csv",
        autoClose: 3000,
      });
    }
  };

  const restForm = () => {
    setIsFilePicked(false);
    console.log("restForms");
  };

  return (
    <Dialog
      open={open}
      onClose={toggleDialog}
      PaperProps={{
        sx: {
          width: "55%",
          maxWidth: "1200px",
          maxHeight: "700px",
        },
      }}
    >
      <DialogTitle>
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <div>Vehicle group</div> */}
          <Typography variant="h6" gutterBottom>
            {"Create Vehicle Group"}
          </Typography>
          <div style={{ marginLeft: "1em" }}> </div>
        </span>
        <IconButton
          aria-label="close"
          onClick={toggleDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Tooltip title="Close create dialog">
            <CloseIcon />
          </Tooltip>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflowY: "auto" }}>
        <Grid container>
          {!isFilePicked ? (
            <Grid item xs={12}>
              <FileUploadPickerPane
                onFileSelected={onFileSelected}
                title="Vehicle Group From CSV"
                fileType={FILE_TYPES.CSV}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <DialogContentText style={{ width: "50%" }}>
                  Please enter a name for a group of vehicles, which can be
                  managed together.
                </DialogContentText>
                <TextField
                  id="vehicle-group-name"
                  label="Vehicle Group Name"
                  placeholder="Type in a name for the group"
                  type="text"
                  autoFocus
                  margin="dense"
                  value={vehicleGroupName}
                  onChange={onGroupNameChange}
                  helperText={error}
                  error={!!error}
                  variant="standard"
                  multiline
                  style={{ width: "50%", marginTop: 32 }}
                  rows={1}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 4 }}
                  id="vehicle-group-description"
                  label="Description"
                  multiline
                  fullWidth
                  rows={1}
                  value={groupDesc}
                  onChange={({ target }) => setGroupDesc(target?.value)}
                  variant="standard"
                  placeholder="Describe the group"
                  style={{ marginBottom: 32 }}
                />
              </Grid>
              <Box sx={{ height: 300, width: "100%" }}>
                <DataGrid
                  rows={vehicleList}
                  columns={columns}
                  getRowId={(row) => row?.id}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                />
              </Box>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {isFilePicked ? (
          <>
            <div>
              <Button onClick={restForm}>Back</Button>
            </div>
            <div>
              <Button onClick={toggleDialog}>Cancel</Button>
              <Button
                onClick={handleCreate}
                disabled={vehicleGroupName?.length < 1}
              >
                Create
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}

        {/*  */}
      </DialogActions>
    </Dialog>
  );
};

export default CreateVehicleGroupFromCsv;
