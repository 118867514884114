import React from "react";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    CircularProgress,
    Box,
    Tooltip,
    IconButton
} from "@mui/material";
import {
    orange,
    green,
    lime,
    yellow,
    teal,
} from "@mui/material/colors";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { JOB_STATUS } from "../../services/Constants";

const level = 500;

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 3,
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
    },
}));

export const CustomTooltip = (item) => {
    const {
        datum: { id, value, color },
    } = item;
    return (
        <Paper
            style={{
                padding: "6px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContents: "center",
                }}
            >
                <div
                    style={{
                        backgroundColor: color,
                        height: "4px",
                        width: "4px",
                        padding: "10px",
                        marginRight: "4px",
                    }}
                ></div>
                <Typography
                    color="text.primary"
                    sx={{ fontSize: 16, textAlign: "right" }}
                >
                    {JOB_STATUS[id]}
                </Typography>
            </div>
            <Typography
                color="text.secondary"
                sx={{ fontSize: 12, textAlign: "right" }}
            >{` ${value} Vehicle${value > 1 ? "s" : ""}`}</Typography>
        </Paper>
    );
};