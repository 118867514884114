import React, { useState, useContext } from "react";
import { func } from "prop-types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { getVehicleGroupData } from "../../services/deployment";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { WizardContext, ACTIONS } from "../wizard/Context";

const VehicleGroupList = (props) => {
  const { state, dispatch } = useContext(WizardContext);
  const { vehicleGroups = [] } = state;
  const { onValueUpdated, register, errors, wizard } = props;
  const [checked, setChecked] = useState(wizard ? vehicleGroups : []);

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    if (wizard) {
      dispatch({
        type: ACTIONS.UPDATE_VEHICLE_GROUP,
        payload: {
          vehicleGroups: newChecked,
          disableNextBtn: newChecked.length <= 0,
        },
      });
    } else {
      onValueUpdated(newChecked);
    }
  };

  // Queries
  const {
    data: { data: { thingGroups: vehicleGroupData = [] } = {} } = {},
    isLoading,
  } = useQuery("vehicleGroups", getVehicleGroupData, {
    refetchOnWindowFocus: false,
  });

  if (isLoading) return <CircularProgress size={25} />;

  return (
    <FormControl
      component="fieldset"
      sx={{
        width: "100%",
        maxHeight: 300,
        overflow: "auto",
        bgcolor: "background.paper",
        mt: 2,
      }}
      variant="standard"
      {...(errors.vehicleGroup && {
        error: true,
        required: true,
      })}
    >
      {!wizard && (
        <FormLabel component="legend">Choose Vehicle Group</FormLabel>
      )}
      <p className="helper-text">(Choose atleast one vehicle group)</p>
      <FormGroup>
        {vehicleGroupData.map((ob) => (
          <FormControlLabel
            control={
              <Checkbox
                name="vehicles"
                defaultChecked={
                  vehicleGroups.includes(ob.groupName) ? true : false
                }
                onClick={() => handleToggle(ob.groupName)}
                {...register("vehicleGroup", {
                  required: true,
                })}
              />
            }
            label={ob.groupName}
            key={ob.groupName}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

VehicleGroupList.defaultProps = {
  onValueUpdated: () => {},
  errors: {
    vehicleGroup: undefined,
  },
  register: () => {},
  wizard: false,
};

VehicleGroupList.propTypes = {
  onValueUpdated: func,
};

export default VehicleGroupList;
