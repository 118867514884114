import axios from "axios";
import Constants from "../services/Constants";
import { constructQueryUrl } from "./Utils";

export const getReleaseData = async ({ queryKey }) => {
  const [
    _,
    page,
    pageSize,
    field,
    sort,
    filterValue,
    columns,
    extraFilters,
    fetchAll
  ] = queryKey;

  let query =
    Constants.LIST_RELEASES +
    `?page=${page}&take=${pageSize}&sortby=${field}&order=${sort.toUpperCase()}`;

  if (fetchAll) {
    query = `${query}&fetchAll=true`
  }

  query = constructQueryUrl({ filterValue, query, columns });
  return await axios.get(query);
};

export const createRelease = async (payload) => {
  return axios.post(Constants.ADD_RELEASE, payload);
};

export const validateReleaseName = async ({ queryKey }) => {
  const [_, releaseName] = queryKey;
  const url = Constants.VALIDATE_RELEASE.replace(":name", releaseName);
  return await axios.get(url);
};

export const getReleasesData = async () => {
  let query = Constants.RELEASE_REPORT;
  return await axios.get(query);
};

export const getReleasesByImageId = async ({ queryKey }) => {
  const [_, imageId] = queryKey;
  const url = Constants.GET_RELEASES_BY_IMAGE_ID.replace(":imageId", imageId);
  return await axios.get(url);
};

export const getReleaseDetails = async ({ queryKey }) => {
  const [_, releaseId] = queryKey;
  const url = Constants.DESCRIBE_RELEASE.replace(":id", releaseId);
  return await axios.get(url);
};
