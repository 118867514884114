import React, { useState } from "react";
import { Box } from "@mui/material";
import { detailsTabsBoxStyle } from "../../services/Utils";
import Constants from "../../services/Constants";
import { DataGrid } from "@mui/x-data-grid";
import { getColumnsData } from "./VehiclesListInVehicleGroupColumns";

const VehiclesInGroupTab = (props) => {
  const [pageSize, setPageSize] = React.useState(Constants.PAGE_SIZE);

  let vehicleData = [];

  if (props?.groupData?.devices?.flat().length) {
    vehicleData = props?.groupData?.devices?.flat()?.map((vehicle) => {
      return vehicle;
    });
  }

  return (
    <Box {...detailsTabsBoxStyle}>
      <DataGrid
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPT}
        pagination
        loading={props.groupDataLoading}
        rows={vehicleData}
        columns={getColumnsData()}
        sx={{ height: "calc(100vh - 290px)" }}
        checkboxSelection={false}
        getRowId={(row) => row?.serialNo}
      />
    </Box>
  );
};

export default VehiclesInGroupTab;
