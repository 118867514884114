import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getNavigationReport } from "../../services/Dashboard";
import { getColumnsData } from "./NavigationReportColumns";
import { useQuery } from "react-query";
import { isValidDate } from "../../services/Utils";
import SearchIcon from "@mui/icons-material/Search";
import DataGridTitle from "../common/DataGridTitle";
import CustomToolbar from "../common/CustomToolbar";

const NavigationReport = (props) => {
  const columns = getColumnsData();
  const [picker, setPicker] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0)
  );
  const [pickerInputErrorText, setPickerInputErrorText] = useState(null);
  const [month, setMonth] = useState(picker.getMonth() + 1);
  const [year, setYear] = useState(picker.getFullYear());
  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [fetchData, setFetchData] = useState(true);
  const { isLoading: logsLoading, isFetching } = useQuery(
    [month, year],
    getNavigationReport,
    {
      enabled: fetchData,
      onSuccess: ({ data }) => {
        setRows(data);
      },
      onSettled: () => {
        setFetchData(false);
      },
      refetchOnWindowFocus: false,
    }
  );

  const handleMonthChange = (val = null) => {
    if (!isValidDate(new Date(val))) {
      setPickerInputErrorText("Invalid Month!");
      return;
    }
    setPickerInputErrorText(null);
    setPicker(val);
    console.log(val);
  };

  const processSearch = () => {
    if (!isValidDate(new Date(picker))) {
      return;
    }
    setMonth(picker.getMonth() + 1);
    setYear(picker.getFullYear());
    setFetchData(true);
  };

  const commonProps = { refresh, setRefresh };

  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Card sx={{ height: "96px" }}>
            <CardContent className="tile-cards">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <DatePicker
                      disableFuture
                      label="Month"
                      views={["month", "year"]}
                      value={picker}
                      timezone="UTC"
                      onChange={(val) => handleMonthChange(val)}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          id="standard-basic"
                          variant="standard"
                          error={!!pickerInputErrorText}
                          helperText={pickerInputErrorText}
                        />
                      )}
                      maxDate={new Date()}
                      minDate={new Date("01-01-2023")}
                      required
                    />
                  </FormControl>
                  <Button
                    disabled={!!pickerInputErrorText}
                    sx={{ ml: "8px" }}
                    variant="outlined"
                    startIcon={<SearchIcon />}
                    onClick={() => processSearch()}
                  >
                    Search
                  </Button>
                </div>
              </LocalizationProvider>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <DataGridTitle title="Navigation Report" />
            <CardContent sx={{
              padding: 0, ":last-child": {
                padding: 0
              }
            }}>
              <Box sx={{ height: "calc(100vh - 280px)", width: "100%" }}>
                <DataGrid
                  loading={logsLoading || isFetching}
                  getRowId={(row) => row?.vehicleKey}
                  rows={rows || []}
                  columns={columns}
                  checkboxSelection
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  components={{ Toolbar: CustomToolbar }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default NavigationReport;
