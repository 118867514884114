import React, { useState, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { CustomThemeProvider } from "./components/themes/CustomThemeProvider";
import PreLoader from "./layout/PreLoader";
import { useQuery } from "react-query";
import { getUserDetails } from "./services/Users";
import AppContent from "./layout/AppContent";
import { getCurrentUserCustomerDetails, getUserOrgs } from "./services/Users";
import { getAppVersion } from "./services/Dashboard";
import "./styles/common.css";
import "./styles/wizard.css";

function App() {
  const [progress, setProgress] = useState(0);

  useQuery("getUserDetails", getUserDetails, {
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setProgress(progress + 25);
    }
  });

  useQuery("currentUserCustomerData", getCurrentUserCustomerDetails, {
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setProgress(progress + 25);
    }
  });

  useQuery("getAppVersion", getAppVersion, {
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setProgress(progress + 25);
    }
  });

  useQuery("getcustomersforuser", getUserOrgs, {
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setProgress(progress + 25);
    }
  });

  useEffect(() => {
    setTimeout(() => setProgress(100), 7000);
  }, [])

  return (
    <CustomThemeProvider>
      <CssBaseline />
      {progress < 100 && <PreLoader progress={progress} />}
      {progress >= 100 && <AppContent />}
    </CustomThemeProvider>
  );
}

export default App;
