import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import styled from "styled-components";
import { AppLogo } from '../components/common/AppLogo';

const Wrapper = styled.div`
  margin: 0;
  width: 20%;
  top: 40%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -60%);
`;

const PreLoader = (props) => {
    const { progress } = props;
    return (
        <Wrapper>
            <AppLogo />
            <Box sx={{ width: '100%', paddingTop: '5%' }}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
        </Wrapper>
    );
}

export default PreLoader;