import React from "react";
import { Box , Typography} from "@mui/material";
import { formatDateWithoutTime, formatDateToTime } from "../../services/Utils";
import { grey } from "@mui/material/colors";

const DateAndTimeGridCell = (props) => (
    <Box>
      <Typography sx={{fontSize: "1em" }}>{formatDateWithoutTime(props?.value)}</Typography>
      <Typography sx={{fontSize: "0.9em", color: grey[600] }}>{formatDateToTime(props?.value)}</Typography>
    </Box>
  );

export {DateAndTimeGridCell};
