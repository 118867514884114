import { Box } from '@mui/material';
import { teal } from "@mui/material/colors";
import React from "react";

const DeploymentStatus = ({ status }) => {
    let background = teal[700];
    let colors = {
        "COMPLETED": "#3ba272",
        "PUBLISHED": "#5470c6",
        "SCHEDULED": "#73c0de",
        "NOT_PUBLISHED": "#fc8452",
        "EXPIRED": "#f44336",
        "CANCELED": "#ee6666"
    };

    let color = colors[status] || background;
    return (
        <Box sx={{ height: '20px', width: "100px", textAlign: 'center', display: "inline", fontSize: 10, background: color, borderRadius: '5px', color: 'white', padding: "4px 7px", fontWeight: 'bold' }}>
            {status.replace(/_/g, " ")}
        </Box>
    )
}

export default DeploymentStatus;