import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Alert,
  ListItemText,
  Card,
  CardHeader,
  CardContent,
  Button,
} from "@mui/material";
import { detailsTabsBoxStyle } from "../../services/Utils";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useModule from "../../hooks/useModule";
import { MODULES, MODULE_TEXT } from "../../services/Constants";
import { getGroupsAssociatedToVehicle } from "../../services/vehicle";
import AddGroupToVehiclePopup from "./AddGroupToVehiclePopup";
import RemoveVehicleFromGroupConfirmDialog from "./RemoveVehicleFromGroupConfirmDialog";
import { CAN, SUBJECTS } from "../../auth/permissions";
import Check from "../../auth/ability";

const AssociatedGroupsForVehicle = ({ vehicleData }) => {
  const { validate } = useModule();
  const [openRemoveConfirm, setOpenRemoveConfirm] = useState();
  const [openAddConfirm, setOpenAddConfirm] = useState();

  const { data } = useQuery(
    ["associatedVehicles", vehicleData?.device?.vehicleKey],
    getGroupsAssociatedToVehicle,
    {
      refetchOnWindowFocus: false,
      enabled: !!vehicleData?.device?.vehicleKey,
    }
  );

  if (!validate(MODULES.SUBSCRIPTION)) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="warning">
          {MODULE_TEXT.SUBSCRIPTION} module not enabled for{" "}
          {localStorage.selectedCustomer} customer
        </Alert>
      </Box>
    );
  }

  return (
    <Box {...detailsTabsBoxStyle}>
      <Card>
        <CardHeader
          action={
            <Check I={CAN.MANAGE} a={SUBJECTS.GROUP}>
              <Button
                title="Add to Group"
                disabled={data?.["vehicleGroups"]?.length > 9}
                variant="outlined"
                onClick={() => {
                  setOpenAddConfirm({
                    serialNo: vehicleData?.device?.serialNo,
                    vehicleKey: vehicleData?.device?.vehicleKey,
                  });
                }}
              >
                <AddCircleOutlineIcon />
              </Button>
            </Check>
          }
          title={`Associated Vehicle groups (${data?.["vehicleGroups"]?.length ?? 0
            }/10)`}
          subheader={`A vehicle can be part of maximum 10 vehicle groups.`}
        />

        <CardContent>
          <List dense={false}>
            {data?.["vehicleGroups"]?.map((i) => (
              <ListItem
                key={i?.vehicleGroupId}
                secondaryAction={
                  <Check I={CAN.MANAGE} a={SUBJECTS.GROUP}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        setOpenRemoveConfirm({
                          vehicleGroupName: i?.vehicleGroupName,
                          vehicleGroupId: i?.vehicleGroupId,
                          serialNo: vehicleData?.device?.serialNo,
                          vehicleKey: vehicleData?.device?.vehicleKey,
                        });
                      }}
                    >
                      <RemoveCircleOutlineIcon
                        titleAccess={`Remove ${vehicleData?.device?.serialNo
                          } from ${i?.vehicleGroupName || "-"}`}
                      />
                    </IconButton>
                  </Check>
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemText
                    id={i?.vehicleGroupId}
                    primary={`${i?.vehicleGroupName || "-"} `}
                    secondary={`${i?.vehicleGroupId || "-"} `}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
      <Check I={CAN.MANAGE} a={SUBJECTS.GROUP}>
        {openRemoveConfirm?.vehicleGroupId ? (
          <RemoveVehicleFromGroupConfirmDialog
            {...openRemoveConfirm}
            toggleDialog={() => setOpenRemoveConfirm(null)}
          />
        ) : null}
        {openAddConfirm?.vehicleKey ? (
          <AddGroupToVehiclePopup
            {...openAddConfirm}
            toggleDialog={() => setOpenAddConfirm(null)}
            singleSelectMode={true}
            groupsToExclude={data?.["vehicleGroups"]}
          />
        ) : null}
      </Check>
    </Box >
  );
};

export default AssociatedGroupsForVehicle;
