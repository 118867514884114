import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    TextField,
    Card,
    CardContent,
    IconButton,
    CardHeader
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { MODULES } from '../../../services/Constants';
import { CAN, SUBJECTS } from '../../../auth/permissions';
import Check from '../../../auth/ability';

export default class SchemaEditor extends React.Component {
    static propTypes = {
        rows: PropTypes.arrayOf(PropTypes.shape({
            keyItem: PropTypes.string,
            valueItem: PropTypes.string
        })),
        keyInputPlaceholder: PropTypes.string,
        valueInputPlaceholder: PropTypes.string,
        onChange: PropTypes.func,
        submitConfig: PropTypes.func,
        validateModuleWithMessage: PropTypes.func,
        addToast: PropTypes.func,
        disableSubmit: PropTypes.bool,
    }

    static defaultProps = {
        rows: [],
        features: [],
        keyInputPlaceholder: '',
        valueInputPlaceholder: '',
        onChange: () => { },
        submitConfig: () => { },
        validateModuleWithMessage: () => { },
        addToast: () => { },
        disableSubmit: false
    }

    constructor(props) {
        super(props);
        this.state = {
            rows: [
                ...this.props.rows
            ]
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.rows.length === 0) {
            return {
                rows: [
                    ...props.rows
                ]
            };
        }
        return null;
    }

    handleReservedKeyword(value) {
        if (this.props.features.includes(value)) {
            this.props.addToast({
                type: "warning",
                message: `Warning: ${value} is a reserved keyword!`,
                autoClose: 5000,
            });
        }
    }

    handleAddNew() {
        if (!this.props.validateModuleWithMessage(MODULES.CONFIGURATION)) return;
        this.setState({
            rows: [
                ...this.state.rows,
                {
                    keyItem: '',
                    valueItem: ''
                }
            ]
        }, () => {
            this.props.onChange(this.toJSON());
        });
    }

    handleKeyItemChange(index, value) {
        this.setState({
            rows: this.state.rows.map((row, i) => {
                if (index !== i) {
                    return row;
                }
                return {
                    ...row,
                    keyItem: value
                };
            })
        }, () => {
            this.props.onChange(this.toJSON());
        });
        this.handleReservedKeyword(value);
    }

    handleValueItemChange(index, value) {
        this.setState({
            rows: this.state.rows.map((row, i) => {
                if (index !== i) {
                    return row;
                }
                return {
                    ...row,
                    valueItem: value
                };
            })
        }, () => {
            this.props.onChange(this.toJSON());
        });
        this.handleReservedKeyword(value);
    }

    handleRemove(index) {
        this.setState({
            rows: this.state.rows.filter((row, i) => i !== index)
        }, () => {
            this.props.onChange(this.toJSON());
        });
    }

    toJSON() {
        const { rows = [] } = this.state;
        return rows.reduce((acc, row) => {
            if (row.keyItem !== '' && row.valueItem !== '') {
                acc[row.keyItem] = row.valueItem;
            }
            return acc;
        }, {});
    }

    renderKeyItem(index, value) {
        return (
            <TextField
                value={value}
                size="small"
                style={{ width: "40%" }}
                placeholder={this.props.keyInputPlaceholder}
                onChange={(e) => this.handleKeyItemChange(index, e.currentTarget.value)}
                disabled={this.props.features.includes(value)}
            />
        );
    }

    renderValueItem(index, key, value) {
        return (
            <TextField
                value={value}
                size="small"
                style={{ width: "40%" }}
                placeholder={this.props.valueInputPlaceholder}
                onChange={(e) => this.handleValueItemChange(index, e.currentTarget.value)}
                disabled={this.props.features.includes(key)}
            />
        );
    }

    renderRows() {
        const { rows = [] } = this.state;
        return rows.map((row, i) => (
            <div className="schema-block" key={`key-value-row-${i}`}>
                {this.renderKeyItem(i, row.keyItem)}
                <span>:</span>
                {this.renderValueItem(i, row.keyItem, row.valueItem)}
                {row.removeDelete ?
                    <span></span>
                    :
                    <IconButton
                        color="primary"
                        component="label"
                        onClick={() => this.handleRemove(i)}
                        disabled={this.props.features.includes(row.keyItem)}
                    >
                        <DeleteIcon fontSize='small' />
                    </IconButton>
                }
            </div>
        ));
    }

    renderAddButton() {
        return (
            <div className="schema-button">
                <Check I={CAN.MANAGE} a={SUBJECTS.CONFIGURATION}>
                    <Button onClick={() => this.handleAddNew()} variant="outlined">+ New Config</Button>
                    {!this.props.disableSubmit &&
                        (this.state.rows.length !== 0) &&
                        <Button onClick={() => this.props.submitConfig()} variant="contained">update</Button>
                    }
                </Check>
            </div>
        );
    }

    render() {
        return (
            <Card>
                <CardHeader
                    title="Configuration State"
                    titleTypographyProps={{ variant: 'subtitle2' }}
                    sx={{
                        backgroundColor: (theme) => theme.palette.tertiary,
                    }}
                />
                <CardContent>
                    <Box sx={{ width: '100%' }}>
                        {this.renderRows()}
                        {this.renderAddButton()}
                    </Box>
                </CardContent>
            </Card>
        );
    }
}