import React from "react";
import { List, ListItem, ListItemText, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  formatDate_12h,
  detailsTabsBoxStyle,
  overviewListItemStyles,
} from "../../services/Utils";
import { Grid } from "@mui/material";

const VehicleGroupDetailsTab = ({ groupData, isLoading }) => {
  if (isLoading) return <CircularProgress />;

  return (
    <Box {...detailsTabsBoxStyle}>
      <Grid container>
        <Grid item xs={6} sx={{ borderRight: 1, borderColor: "divider" }}>
          <List>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Group Name"
                secondary={groupData?.vehicleGroupName || "-"}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Group Id"
                secondary={groupData?.vehicleGroupId || "-"}
              />
            </ListItem>

            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Description"
                secondary={groupData?.description || "-"}
              />
            </ListItem>

            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Created Date"
                secondary={formatDate_12h(groupData?.createdAt)}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Created By"
                secondary={groupData?.createdBy || "-"}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={6}>
          <List>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Group Updated Date"
                secondary={formatDate_12h(groupData?.updatedAt)}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Updated By"
                secondary={groupData?.updatedBy || "-"}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VehicleGroupDetailsTab;
