import axios from "axios";
import { Auth } from "aws-amplify";
import {
  getIDToken,
  isIdTokenExpired,
  refreshIdToken,
} from "../services/Users";

export default function JwtInterceptor() {
  axios.interceptors.request.use(
    async (req) => {
      let idToken = getIDToken();
      if (idToken) {
        const isTokenExpired = isIdTokenExpired();
        if (isTokenExpired) {
          try {
            idToken = await refreshIdToken();
          } catch (err) {
            Auth.signOut();
            console.log("ERROR", err);
          }
        }
        req.headers.Authorization = "Bearer " + idToken;
        req.headers["X-Customer-Id"] =
          localStorage.selectedCustomer || "visteon";
        return req;
      }
    },
    (err) => {
      return Promise.reject(err);
    }
  );
}
