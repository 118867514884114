import React from "react";
import {
  Button,
  DialogContent,
  DialogTitle,
  Box,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Tooltip,
  DialogActions,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import {
  ReleaseWizard,
  DeploymentWizard,
  VehicleWizard,
  ReviewWizard,
} from "./software";
import {
  ConfigStateWizard,
  CreateConfigWizard,
  ConfigReviewWizard,
} from "./config";

const SelectionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`;

const SelectionItem = styled.div`
    padding: 6%;
    border-radius: 5px;
    border: 3px solid #eee;
    text-align: center;
    width: 40%;
    &:hover {
        border: ${(props) => `3px solid ${props.theme.palette.primary.main}`};
        cursor: pointer;
        background-color: ${(props) => props.theme.palette.tertiary}};
    }
`;

export const DialogHeader = ({ wizardType, handleClose }) => {
  let titleText = "Choose deployment type";
  switch (wizardType) {
    case "software":
      titleText = "Create a software deployment";
      break;

    case "configuration":
      titleText = "Create a configuration deployment";
      break;

    default:
      titleText = "Choose deployment type";
      break;
  }

  return (
    <DialogTitle>
      {titleText}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Tooltip title="Wizard Data will be lost by closing">
          <CloseIcon />
        </Tooltip>
      </IconButton>
    </DialogTitle>
  );
};

export const DialogFooter = (props) => {
  const {
    activeStep,
    launch,
    handleBack,
    steps,
    hidePublish,
    isLoading,
    handlePublish,
    handleNext,
    disableNextBtn,
    getBtnText,
    wizardType = "",
  } = props;
  if (wizardType === "") {
    return null;
  }

  return (
    <DialogActions className="wizard-action">
      <Button
        color="inherit"
        disabled={activeStep === 0 || launch}
        onClick={handleBack}
        sx={{ mr: 1 }}
      >
        Back
      </Button>
      <div>
        {activeStep === steps.length - 1 && launch && (
          <Check I={CAN.PUBLISH} a={SUBJECTS.DEPLOYMENT}>
            {!hidePublish && (
              <Button
                variant="contained"
                disableElevation
                disableRipple
                onClick={() => handlePublish()}
                style={{ marginLeft: "2%" }}
                disabled={isLoading || disableNextBtn}
              >
                {isLoading ? <HourglassTopIcon /> : "Publish"}
              </Button>
            )}
          </Check>
        )}
        {(!launch || hidePublish) && (
          <Button
            variant="contained"
            onClick={handleNext}
            disableElevation
            disableRipple
            disabled={disableNextBtn}
          >
            {getBtnText()}
          </Button>
        )}
      </div>
    </DialogActions>
  );
};

const getDialogContent = (props) => {
  const { activeStep, wizardType } = props;
  if (wizardType === "software") {
    switch (activeStep) {
      case 0:
        return <VehicleWizard />;

      case 1:
        return <ReleaseWizard />;

      case 2:
        return <DeploymentWizard />;

      case 3:
        return <ReviewWizard />;

      default:
        return "Invalid Active Step";
    }
  } else if (wizardType === "configuration") {
    switch (activeStep) {
      case 0:
        return <VehicleWizard />;

      case 1:
        return <CreateConfigWizard />;

      case 2:
        return <ConfigStateWizard />;

      case 3:
        return <ConfigReviewWizard />;

      default:
        return "Invalid Active Step";
    }
  }
};

const ChooseDeploymentType = ({ typeListener }) => {
  const theme = useTheme();
  return (
    <SelectionContainer>
      <Check I={CAN.MANAGE} a={SUBJECTS.DEPLOYMENT}>
        <SelectionItem
          data-name="software"
          onClick={(e) => typeListener(e)}
          theme={theme}
        >
          <Typography variant="h5" data-name="software">
            {" "}
            Deploy Software{" "}
          </Typography>
          <Typography
            className="selection-text"
            variant="body2"
            data-name="software"
          >
            Use this to deploy the software to your vehicles
          </Typography>
        </SelectionItem>
      </Check>
      <Check I={CAN.MANAGE} a={SUBJECTS.CONFIGURATION}>
        <SelectionItem
          data-name="configuration"
          onClick={(e) => typeListener(e)}
          theme={theme}
        >
          <Typography variant="h5" data-name="configuration">
            {" "}
            Deploy Configuration{" "}
          </Typography>
          <Typography
            className="selection-text"
            data-name="configuration"
            variant="body2"
          >
            Use this to deploy the configurations to your vehicles
          </Typography>
        </SelectionItem>
      </Check>
    </SelectionContainer>
  );
};

export const DialogContainer = (props) => {
  const { wizardType, typeListener } = props;
  return (
    <DialogContent>
      {wizardType === "" ? (
        <ChooseDeploymentType typeListener={typeListener} />
      ) : (
        <Box sx={{ width: "100%" }}>
          <DialogStepper {...props} />
          <div className="wizard-wrapper">
            {getDialogContent({
              ...props,
            })}
          </div>
        </Box>
      )}
    </DialogContent>
  );
};

const DialogStepper = ({ steps, activeStep }) => {
  return (
    <Stepper
      style={{
        position: "sticky",
        top: "0",
        backgroundColor: "#ffffff",
        zIndex: "1",
      }}
      activeStep={activeStep}
      alternativeLabel
    >
      {steps.map((label) => {
        const stepProps = {};
        const labelProps = {};
        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
