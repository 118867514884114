import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { getJobExecutions } from "../../services/vehicle";
import Constants from "../../services/Constants";
import { getColumnsData, getColumnsDataV1 } from "./GroupDeploymentListColumns";
import { detailsTabsBoxStyle } from "../../services/Utils";
import { DataGrid } from "@mui/x-data-grid";

const GroupDeploymentList = (data = {}) => {
  let { deploymentId, groupId } = useParams();

  const [pageSize, setPageSize] = React.useState(Constants.PAGE_SIZE);
  const [executionSummaries, setExecutionSummaries] = useState([]);

  const [columns, setColumns] = useState([]);
  // jobExecutions Queries
  let { isLoading: summaryLoading } = useQuery(
    ["jobExecutions", deploymentId],
    getJobExecutions, // to get the execution summary of the whole deployment
    {
      refetchOnWindowFocus: false,
      retry: 0,
      refetchInterval: 5000,
      onSuccess: (data) => {
        if (data?.data) {
          let respData;
          if (data?.data?.executionSummaries) {
            //old deployment list
            respData = data?.data?.executionSummaries;
            setColumns(getColumnsDataV1());
          } else {
            // new deployment list
            respData = data?.data;
            setColumns(getColumnsData());
          }
          respData = respData.filter(
            (item) => item?.vehicleGroupId === groupId
          );
          setExecutionSummaries(respData);
        }
      },
      onError: (error) => {
        setExecutionSummaries([]);
      },
    }
  );

  if (summaryLoading) {
    return <CircularProgress />;
  }

  return (
    <Box {...detailsTabsBoxStyle}>
      <DataGrid
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPT}
        pagination
        rows={executionSummaries}
        columns={columns}
        sx={{ height: "calc(100vh - 290px)" }}
        checkboxSelection={false}
        getRowId={(row) => row?.serialNo}
      />
    </Box>
  );
};

export default GroupDeploymentList;
