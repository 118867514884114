import { Box } from "@mui/material";
import { teal } from "@mui/material/colors";
import React from "react";
import Constants from "../../services/Constants";
let { UPLOAD_STATUS: UploadStatus } = Constants;

const FileUploadStatus = ({ status }) => {
  let background = teal[700];

  let colors = {
    Completed: "#3ba272",
    Started: "#5470c6",
    InProgress: "#73c0de",
    Failed: "#ee6666",
  };

  let color = colors[status] || background;
  return (
    <Box
      sx={{
        height: "20px",
        width: "100px",
        textAlign: "center",
        display: "inline",
        fontSize: 10,
        background: color,
        borderRadius: "5px",
        color: "white",
        padding: "4px 7px",
        fontWeight: "bold",
      }}
    >
      {UploadStatus[status]?.replace(/_/g, " ").toUpperCase()}
    </Box>
  );
};

export default FileUploadStatus;
