import { Box } from "@mui/material";
import React from "react";

const StatusActiveInactive = ({ active }) => {
  let colors = {
    green: "#3ba272",
    red: "#f44336",
    gray: "#f44336",
  };

  let col = active ? colors.green : colors.red;
  return (
    <Box
      sx={{
        height: "20px",
        width: "60px",
        textAlign: "center",
        textTransform: "upperCase",
        display: "inline",
        fontSize: 10,
        background: col,
        borderRadius: "5px",
        color: "white",
        padding: "4px 7px",
        fontWeight: "bold",
      }}
    >
      {active ? "Active" : "Inactive"}
    </Box>
  );
};

export default StatusActiveInactive;
