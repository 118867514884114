import React from "react";
import { TextField } from "@mui/material";

const GroupDescription = (props) => {
  const { groupDesc, setGroupDesc } = props;
  return (
    <TextField
      sx={{ mt: 4 }}
      id="vehicle-group-description"
      label="Description"
      multiline
      fullWidth
      rows={1}
      value={groupDesc}
      onChange={({ target }) => setGroupDesc(target?.value)}
      variant="standard"
      placeholder="Describe the group"
    />
  );
};

export default GroupDescription;
