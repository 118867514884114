import React, { Fragment } from "react";
import {
  Grid,
  CardActionArea,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useQueryClient } from "react-query";
import CreateUserForm from "./CreateUserForm";
import Check from "../../auth/ability";
import { useQuery } from "react-query";
import { getAllUsersData } from "../../services/Users";
import { grey } from "@mui/material/colors";
import { CAN, SUBJECTS } from "../../auth/permissions";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DottedTile from "../common/DottedTile";
import InfoTile from "../common/InfoTile";

const UserStats = () => {
  const queryClient = useQueryClient();
  const {
    data: users = [],
    isLoading,
    isFetching
  } = useQuery("getAllUsersData", getAllUsersData, {
    refetchOnWindowFocus: true,
  });

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    // Invalidate and refetch
    queryClient.invalidateQueries('getAllUsersData');
    queryClient.invalidateQueries('getAllUserGroups');
  };

  return (
    <Fragment>
      <Grid item xs={6} md={2}>
        <InfoTile
          title={"Total No. of Users"}
          info={users?.length}
          isLoading={isLoading || isFetching}
        />
      </Grid>
      <Check I={CAN.CREATE} a={SUBJECTS.RELEASE}>
        <Grid item xs={6} md={2}>
          <CardActionArea onClick={handleOpen}>
            <DottedTile title={"CREATE A USER"}>
              <PersonAddIcon
                sx={{
                  fontSize: "3.5rem",
                  color: grey[500]
                }}
              />
            </DottedTile>
          </CardActionArea>
          <Dialog
            open={openModal}
            onClose={handleClose}
            scroll="paper"
            PaperProps={{
              style: {
                maxHeight: "90%",
              },
            }}
          >
            <DialogTitle>
              Create new user
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <CreateUserForm onUserCreated={handleClose} />
            </DialogContent>
          </Dialog>
        </Grid>
      </Check>
      <Grid item xs={3}></Grid>
    </Fragment>
  );
};

UserStats.defaultProps = {};

UserStats.propTypes = {};

export default UserStats;
