import React from "react";
import { getVehicleGroupsList } from "../../services/vehicle";
import Constants from "../../services/Constants";
import OTAPagination from "../OTAPagination";
import { getVehicleGroupsDataColumns } from "./VehicleGroupsDataColumns";

const VehicleGroupsDataGrid = () => {
  const columns = getVehicleGroupsDataColumns();

  return (
    <OTAPagination
      columns={columns}
      endpoint={Constants.LIST_VEHICLE_GROUPS}
      endpointFn={getVehicleGroupsList}
      queryName={"vehicleGroupsList"}
      filterMode="server"
      sortByDefault={"createdAt"}
      getRowId={(row) => row.vehicleGroupId}
    />
  );
};

export default VehicleGroupsDataGrid;
