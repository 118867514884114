import * as React from 'react';
import moment from "moment";
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
    IconButton,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import styled from "styled-components";

const TimestampText = styled.p`
  margin: 0;
  padding: 0;
  margin: 2% 0;
  font-size: 10px;
`;

const VehicleShadowList = (props) => {
    const { configItems = [], refetch = () => { } } = props;

    const getListClass = (stateMatched) => {
        if (stateMatched === undefined) return 'shadow-items';
        return stateMatched ?
            `shadow-items shadow-matched` :
            `shadow-items shadow-mismatch`
    }

    return (
        <Grid container spacing={0} alignItems="flex-end">
            {configItems.map((item, i) => (
                <Grid
                    item
                    key={`${item.title.replace(/\s+/g, ' ').trim()}-${i}`}
                    xs={12}
                    sm={12}
                    md={Math.round(12 / configItems.length)}
                >
                    <Card style={{ borderRadius: 0 }}>
                        <CardHeader
                            title={item.title}
                            titleTypographyProps={{ align: 'center', variant: 'subtitle2' }}
                            action={item.title === 'Reported' ? <IconButton aria-label="refresh" className="shadow-refresh" onClick={() => refetch()}>
                                <RefreshIcon fontSize='small' color="primary" />
                            </IconButton> : null}
                            sx={{
                                backgroundColor: (theme) => theme.palette.tertiary,
                            }}
                        />
                        <CardContent sx={{ padding: '0', ':last-child': { padding: 0 } }}>
                            <ul className='shadow-list' style={{ padding: 0, margin: 0 }}>
                                {item.description && item.description.map((des, i) => (
                                    <Typography
                                        component="li"
                                        variant="subtitle1"
                                        align="center"
                                        key={`${des.value}-${i}`}
                                        className={
                                            getListClass(des.stateMatched)
                                        }
                                    >
                                        {`${des.value}`}
                                        <TimestampText>
                                            Last updated: {
                                                des.timestamp === null ? '-' :
                                                    moment.unix(des.timestamp).format("MM/DD/YYYY h:m A")
                                            }
                                        </TimestampText>
                                    </Typography>
                                ))}
                            </ul>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
};

export default VehicleShadowList;