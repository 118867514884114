import React, { useState, useContext } from "react";
import { func } from "prop-types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { getVehicleData } from "../../services/deployment";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { WizardContext, ACTIONS } from "../wizard/Context";

const VehicleList = (props) => {
  const { state, dispatch } = useContext(WizardContext);
  const { vehicles = [] } = state;
  const { onValueUpdated, register, errors, wizard } = props;
  const [checked, setChecked] = useState(wizard ? vehicles : []);

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);

    if (wizard) {
      dispatch({
        type: ACTIONS.UPDATE_VEHICLE,
        payload: {
          vehicles: newChecked,
          disableNextBtn: newChecked.length <= 0,
        },
      });
    } else {
      onValueUpdated(newChecked);
    }
  };

  // Queries
  let { data: { data: vehicleData } = [], isLoading } = useQuery(
    "vehicles",
    getVehicleData,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) return <CircularProgress size={25} />;

  return (
    <FormControl
      component="fieldset"
      sx={{
        width: "100%",
        maxHeight: 300,
        overflow: "auto",
        bgcolor: "background.paper",
        mt: 2,
      }}
      variant="standard"
      {...(errors.vehicles && {
        error: true,
        required: true,
      })}
    >
      {!wizard && <FormLabel component="legend">Choose Vehicles</FormLabel>}
      <p className="helper-text">(Choose atleast one vehicle)</p>
      <FormGroup>
        {vehicleData?.map((ob) => (
          <FormControlLabel
            control={
              <Checkbox
                name="vehicles"
                defaultChecked={vehicles.includes(ob.thingName) ? true : false}
                onClick={() => handleToggle(ob.thingName)}
                {...register("vehicles", {
                  required: true,
                })}
              />
            }
            label={ob.thingName}
            key={ob.thingName}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

VehicleList.defaultProps = {
  onValueUpdated: () => {},
  errors: {
    vehicles: undefined,
  },
  register: () => {},
  wizard: false,
};

VehicleList.propTypes = {
  onValueUpdated: func,
};

export default VehicleList;
