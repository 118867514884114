import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  TextField,
} from "@mui/material";
import {
  DataGrid
} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getReportsForSchedulerByDateRange } from "../../services/Dashboard";
import { getColumnsData } from "./SchedulerLogsColumns";
import { useQuery } from "react-query";
import CsvProvisioingList from "./CsvProvisioingList";
import { isValidDate } from "../../services/Utils";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import DataGridTitle from "../common/DataGridTitle";
import CustomToolbar from "../common/CustomToolbar";

const SchedulerReport = (props) => {
  const columns = getColumnsData();
  const [showCsvList, setShowCsvList] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [fetchData, setFetchData] = useState(true);
  const from = new Date(
    new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(
      0,
      0,
      0,
      0
    )
  );
  const to = new Date(new Date().setHours(23, 59, 59, 59));
  const [dateRange, setDateRange] = useState({
    from: from,
    to: to,
  });
  const [rows, setRows] = useState([]);
  const [fromDateInputErrorText, setFromDateInputErrorText] = useState(null);
  const [toDateInputErrorText, setToDateInputErrorText] = useState(null);
  const [searchObj, setSearchObj] = useState({
    ...dateRange,
  });

  const { isLoading: logsLoading, isFetching } = useQuery(
    ["schedulerLogsReport", searchObj],
    getReportsForSchedulerByDateRange,
    {
      enabled: fetchData,
      onSuccess: ({ data }) => {
        setRows(data);
      },
      onSettled: () => {
        setFetchData(false);
      },
      refetchOnWindowFocus: false,
    }
  );

  const handleDateChange = (newValue = null, type) => {
    const newDates = dateRange;
    const newFrom = !!newValue ? new Date(newValue.setHours(0, 0, 0, 0)) : null;
    const newTo = !!newValue
      ? new Date(newValue.setHours(23, 59, 59, 59))
      : null;
    if (!!newValue && !!type && type === "from") {
      if (moment(newValue).isBefore("2001-01-01")) {
        setFromDateInputErrorText("Error");
        setDateRange({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      if (moment(newValue).isAfter(newDates.to)) {
        setFromDateInputErrorText("Date greater than To date!");
        setDateRange({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      if (!isValidDate(new Date(newValue))) {
        setFromDateInputErrorText("Invalid date!");
        setDateRange({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      setDateRange({ from: newFrom, to: newDates.to });
      setFromDateInputErrorText(null);
    } else if (!!newValue && !!type && type === "to") {
      if (moment(newValue).isBefore(newDates.from)) {
        setToDateInputErrorText("Date less than From date!");
        setDateRange({ from: newDates.from, to: newTo });
        return;
      }
      if (!isValidDate(new Date(newValue))) {
        setToDateInputErrorText("Invalid date!");
        setDateRange({ from: newDates.from, to: newTo });
        return;
      }
      setDateRange({ from: newDates.from, to: newTo });
      setToDateInputErrorText(null);
    } else if (!newValue && type === "to") {
      setDateRange({ from: newDates.from, to: newTo });
    } else if (!newValue && type === "from") {
      setDateRange({ from: newFrom, to: newDates.to });
    }
  };

  const processSearch = () => {
    const searchData = JSON.parse(JSON.stringify(dateRange));
    setSearchObj({
      from: new Date(searchData?.from),
      to: new Date(searchData?.to),
    });
    setFetchData(true);
  };

  const commonProps = { refresh, setRefresh };

  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Card sx={{ height: "96px" }}>
            <CardContent className="tile-cards">
              <Grid container spacing={2}>
                <Grid item xs={10} md={10} lg={10}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <DatePicker
                          disableFuture
                          label="From"
                          value={dateRange.from}
                          onChange={(val) => handleDateChange(val, "from")}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              id="standard-basic"
                              variant="standard"
                              error={!!fromDateInputErrorText}
                              helperText={fromDateInputErrorText}
                            />
                          )}
                          maxDate={new Date()}
                          required
                        />
                      </FormControl>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <DatePicker
                          disableFuture
                          label="To"
                          value={dateRange.to}
                          onChange={(val) => handleDateChange(val, "to")}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              id="standard-basic"
                              variant="standard"
                              error={!!toDateInputErrorText}
                              helperText={toDateInputErrorText}
                            />
                          )}
                          minDate={dateRange.from}
                          required
                        />
                      </FormControl>

                      <Button
                        disabled={
                          !!fromDateInputErrorText || !!toDateInputErrorText
                        }
                        sx={{ ml: "8px" }}
                        variant="outlined"
                        startIcon={<SearchIcon />}
                        onClick={() => processSearch()}
                      >
                        Search
                      </Button>
                    </div>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  lg={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Check I={CAN.MANAGE} a={SUBJECTS.PROVISION}>
                    <Button
                      sx={{ minWidth: "94px" }}
                      onClick={() => {
                        setShowCsvList(true);
                      }}
                      variant="contained"
                    >
                      Instant Provision
                    </Button>
                  </Check>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <DataGridTitle title="Scheduler Report" />
            <CardContent sx={{
              padding: 0, ":last-child": {
                padding: 0
              }
            }}>
              <Box sx={{ height: "calc(100vh - 280px)", width: "100%" }}>
                <DataGrid
                  loading={logsLoading || isFetching}
                  getRowId={(row) => row?.schedulerLogId}
                  rows={rows || []}
                  columns={columns}
                  checkboxSelection
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  components={{ Toolbar: CustomToolbar }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {showCsvList && (
          <CsvProvisioingList
            open={showCsvList}
            handleClose={() => setShowCsvList(false)}
          />
        )}
      </Grid>
    </div>
  );
};

export default SchedulerReport;
