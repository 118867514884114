import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  TextField,
  Grid,
  Typography,
  Box,
  Chip,
  Tab,
  Tabs,
  Autocomplete,
  List,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import TabPanel, { a11yProps } from "../common/TabPanel";
import ImageChecklist from "../../image/ImageChecklist";
import { getAllReleases } from "../../../services/deployment";
import {
  validateReleaseName,
  getReleaseDetails,
} from "../../../services/Release";
import { getAllCompletedImages } from "../../../services/Image";
import { WizardContext, ACTIONS, TABS } from "../Context";
import useDebounce from "../../../hooks/useDebounce";
import { getDefaultReleaseName } from "../../vehicle/Utils";

const ReleaseWizard = () => {
  const [validate, setValidate] = useState({
    helperText: "",
    hasError: false,
  });
  const [selectedRelease, setSelectedRelease] = useState({});
  const { state, dispatch } = useContext(WizardContext);
  const {
    toggleRelease,
    releaseName = getDefaultReleaseName(),
    release = {},
    images = [],
  } = state;
  const { label: releaseLabel = "" } = release;
  const debounceValidate = useDebounce(releaseName, 500);

  // Queries
  const { data: { data: releaseData = [] } = {} } = useQuery(
    "releases",
    getAllReleases,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: { data: { res: imageData = [] } = {} } = {} } = useQuery(
    "ImageList",
    getAllCompletedImages,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: releaseDetailsLoading, refetch: fetchReleaseDetails } =
    useQuery(["fetchReleaseDetails", selectedRelease?.id], getReleaseDetails, {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: 0,
      onSuccess: (data) => {
        const {
          data: { images },
        } = data || {};
        setSelectedRelease({
          ...selectedRelease,
          images,
        });
      },
    });

  const { isFetching, isLoading, refetch } = useQuery(
    ["validateReleaseName", releaseName],
    validateReleaseName,
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (error) => {
        const {
          response: { data: { message = "", statusCode = "" } = {} } = {},
        } = error;
        if (statusCode === 409) {
          setValidate({
            helperText: message,
            hasError: true,
          });
          dispatch({
            type: ACTIONS.UPDATE_RELEASE_NAME,
            payload: {
              disableNextBtn: true,
            },
          });
        }
      },
    }
  );

  useEffect(() => {
    if (debounceValidate && !validate.hasError) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValidate]);

  useEffect(() => {
    const { id: releaseId = "" } = selectedRelease;
    if (releaseId !== "") {
      fetchReleaseDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRelease?.id]);

  useEffect(() => {
    if (
      (toggleRelease === TABS.CHOOSE_RELEASE && releaseLabel !== "") ||
      (toggleRelease === TABS.CREATE_RELEASE &&
        releaseName !== "" &&
        images.length > 0)
    ) {
      dispatch({
        type: ACTIONS.UPDATE_RELEASE,
        payload: {
          disableNextBtn: false,
        },
      });
    }

    if (
      Object.keys(selectedRelease).length === 0 &&
      Object.keys(release).length !== 0
    ) {
      setSelectedRelease(release);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nameValidation = (value) => {
    let invalid = false;
    if (!!value) {
      const re = /^[a-zA-Z0-9_-]+$/;
      if (!re.test(value)) {
        setValidate({
          hasError: true,
          helperText:
            "White space and special characters not allowed, except underscores (_) and hypens (-).",
        });
        invalid = true;
      } else if (value.length > 32) {
        setValidate({
          hasError: true,
          helperText:
            "Maximum length of the name cannot be over 32 characters!",
        });
        invalid = true;
      } else {
        setValidate({
          hasError: false,
          helperText: "",
        });
        invalid = false;
      }
    } else {
      setValidate({
        hasError: false,
        helperText: "",
      });
      invalid = true;
    }
    dispatch({
      type: ACTIONS.UPDATE_RELEASE_NAME,
      payload: {
        releaseName: value,
        disableNextBtn: !!invalid || images.length === 0,
      },
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
      className="wizard-grid"
    >
      <Grid item xs={10}>
        <Tabs
          centered
          value={toggleRelease}
          onChange={(_, newValue) => {
            dispatch({
              type: ACTIONS.TOGGLE_RELEASE,
              payload: {
                toggleRelease: newValue,
                disableNextBtn: true,
                release: {},
                releaseName: getDefaultReleaseName(),
                images: [],
              },
            });
            if (newValue === TABS.CREATE_RELEASE) {
              setSelectedRelease({});
            }
          }}
          aria-label="tabs auto"
          style={{
            position: "sticky",
            top: "80px",
            backgroundColor: "#ffffff",
            zIndex: "1",
          }}
        >
          <Tab disableRipple label={`Choose Release`} {...a11yProps(0)} />
          <Tab disableRipple label={`Create Release`} {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={toggleRelease} index={0}>
          {toggleRelease === TABS.CHOOSE_RELEASE && (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  disableClearable
                  value={releaseLabel || ""}
                  onChange={(_, release) => {
                    const { id = "" } = release;
                    setSelectedRelease(release);
                    dispatch({
                      type: ACTIONS.UPDATE_RELEASE,
                      payload: {
                        release,
                        disableNextBtn: id === "",
                      },
                    });
                  }}
                  options={releaseData
                    .sort((a, b) => {
                      return new Date(b?.createdAt) - new Date(a?.createdAt);
                    })
                    .map((option) => {
                      return {
                        label: option.releaseName,
                        id: option.releaseId,
                        images: [],
                      };
                    })}
                  sx={{ width: "100%" }}
                  renderInput={(params) => {
                    return <TextField {...params} label="Choose Release" />;
                  }}
                />
              </Grid>
              <Box sx={{ mt: "32px", width: "100%" }}>
                {selectedRelease && selectedRelease.id ? (
                  <>
                    {releaseDetailsLoading && (
                      <Typography variant="overline">
                        Fetching Release images...
                      </Typography>
                    )}
                    {selectedRelease?.images?.length > 0 && (
                      <>
                        <List
                          sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                            overflow: "auto",
                            maxHeight: 400,
                          }}
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          subheader={
                            <ListSubheader
                              component="div"
                              id="nested-list-subheader"
                            >
                              Images in {selectedRelease?.label}
                            </ListSubheader>
                          }
                        >
                          {selectedRelease?.images?.map((i) => (
                            <ListItemButton>
                              <ListItemIcon>-</ListItemIcon>
                              <ListItemText primary={i.fileName} />
                            </ListItemButton>
                          ))}
                        </List>
                      </>
                    )}
                    {selectedRelease?.images?.length === 0 &&
                      !releaseDetailsLoading && (
                        <Typography variant="overline">
                          No Images available.
                        </Typography>
                      )}
                  </>
                ) : null}
              </Box>
            </>
          )}
        </TabPanel>
        <TabPanel value={toggleRelease} index={1}>
          {toggleRelease === TABS.CREATE_RELEASE && (
            <Grid item xs={12}>
              <Grid item xs container spacing={4} justifyContent="center">
                <Grid item xs={12}>
                  <TextField
                    id="Release name"
                    required
                    label="Release Name"
                    variant="standard"
                    value={releaseName}
                    onInput={(e) => {
                      nameValidation(e.target.value);
                    }}
                    fullWidth
                    error={validate.hasError}
                    helperText={validate.helperText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {(isFetching || isLoading) && (
                            <CircularProgress size={20} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <p className="wizard-infotext">
                    Choose images to create a new release
                  </p>
                  <Grid item xs container spacing={2} justifyContent="center">
                    <Grid item xs={6} className="wizard-release-container">
                      <ImageChecklist wizard />
                    </Grid>
                    <Grid item xs={6} className="wizard-release-container">
                      {images.length > 0 && (
                        <>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "300" }}
                            gutterBottom
                            component="div"
                          >
                            Images selected
                          </Typography>
                          <Box>
                            {images.map((ob) => {
                              const { fileName = "", version = "" } =
                                imageData.find((i) => i.imageUploadId === ob);

                              return (
                                <Chip
                                  key={ob}
                                  label={`${fileName} - V${version}`}
                                  sx={{ m: 1 }}
                                ></Chip>
                              );
                            })}
                          </Box>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </TabPanel>
      </Grid>
    </Grid>
  );
};

ReleaseWizard.defaultProps = {};

export default ReleaseWizard;
