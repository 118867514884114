import React from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { updateVehiclesInGroup } from "../../services/vehicle";
import { useToast } from "../toast";
import CloseIcon from "@mui/icons-material/Close";

const RemoveVehicleFromGroupConfirmDialog = (props) => {
  const { addToast } = useToast();
  // Access the client
  const queryClient = useQueryClient();
  const {
    vehicleGroupId,
    vehicleGroupName,
    serialNo,
    vehicleKey,
    toggleDialog,
  } = props;

  const { mutate: updateMutation, isLoading: updateLoading } = useMutation(
    updateVehiclesInGroup,
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries("associatedVehicles");
        toggleDialog();
        addToast({
          type: "success",
          message: `Vehicle ${serialNo} removed from group ${vehicleGroupName} successfully.`,
          autoClose: 3000,
        });
      },
      onError: (e) => {
        const { response } = e;
        addToast({
          type: "error",
          message: response?.data?.message || "Failed to remove",
          autoClose: 3000,
        });
      },
    }
  );
  const onConfirm = () => {
    updateMutation({
      vehicleGroupId: vehicleGroupId,
      vehicleGroupName: vehicleGroupName,
      vehicleKeys: {
        removeVehicles: [vehicleKey],
      },
    });
  };
  const onCancel = () => {
    toggleDialog();
  };
  return (
    <Dialog
      open={!!vehicleGroupId}
      onClose={onCancel}
      aria-labelledby="delete-confirm-dialog-title"
      aria-describedby="delete-confirm-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Confirm Removal`}
        <IconButton
          aria-label="close"
          onClick={toggleDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Tooltip title="Close delete dialog">
            <CloseIcon />
          </Tooltip>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure, you want to remove the vehicle "${serialNo}" from the vehicle group "${vehicleGroupName}"?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={updateLoading} onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={updateLoading} onClick={onConfirm} autoFocus>
          {updateLoading ? (
            <CircularProgress size="1rem" color="secondary" />
          ) : (
            "Confirm"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveVehicleFromGroupConfirmDialog;
