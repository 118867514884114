import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Paper,
  Button,
  CardActions,
} from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { useQuery } from "react-query";
import { getDeploymentsByStatus } from "../../services/Dashboard";
import { useTheme } from '@mui/material/styles';

//gradients generated from https://gradients.cssgears.com/?ref=producthunt


const DeploymentsByStatus = ({ setOpen, popup }) => {
  const theme = useTheme();

  const { data = [] } = useQuery("deploymentByStatus", getDeploymentsByStatus, {
    refetchOnWindowFocus: false,
  });
  const commonProperties = {
    margin: { top: 20, right: 20, bottom: 20, left: 20 },
    sortByValue: true,
    innerRadius: 0.5,
    padAngle: 1,
    cornerRadius: 1,
    activeOuterRadiusOffset: 8,
    colors: theme.custom.pieChartColors,
    borderWidth: 1,
    borderColor: {
      from: "color",
      modifiers: [["darker", 0.2]],
    },
    arcLinkLabelsSkipAngle: 10,
    arcLinkLabelsTextColor: "#333333",
    enableArcLinkLabels: popup ? true : false,
    arcLinkLabelsThickness: 2,
    arcLinkLabelsColor: { from: "color" },
    arcLabelsSkipAngle: 10,
    arcLabelsTextColor: {
      from: "color",
      modifiers: [["darker", 2]],
    },
  };

  const CustomTooltip = (item) => {
    const {
      datum: { id, value, color },
    } = item;
    return (
      <Paper
        style={{
          padding: "6px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContents: "center",
          }}
        >
          <div
            style={{
              backgroundColor: color,
              height: "4px",
              width: "4px",
              padding: "10px",
              marginRight: "4px",
            }}
          ></div>
          <Typography
            color="text.primary"
            sx={{ fontSize: 16, textAlign: "right" }}
          >
            {id}
          </Typography>
        </div>
        <Typography
          color="text.secondary"
          sx={{ fontSize: 12, textAlign: "right" }}
        >{` ${value} deployment${value > 1 ? "s" : ""}`}</Typography>
      </Paper>
    );
  };

  const popupStyle = {
    sx: {
      boxShadow: 'none',
      padding: 0,
      margin: 0,
    }
  }

  return (
    <Card {...(popup && popupStyle)}>
      <CardHeader
        title={
          <Typography color="text.secondary">
            Total deployments by Status
          </Typography>
        }
      ></CardHeader>
      <CardContent style={{ height: "336px" }}>
        {data.length > 0 &&
          <ResponsivePie
            data={data || []}
            {...commonProperties}
            tooltip={CustomTooltip}
          />
        }
      </CardContent>
      {
        !popup && <CardActions>
          <Button size="small" onClick={() => setOpen('status')}>More info</Button>
        </CardActions>
      }
    </Card>
  );
};

DeploymentsByStatus.defaultProps = {};

DeploymentsByStatus.propTypes = {};

export default DeploymentsByStatus;
