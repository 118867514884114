import React from 'react';
import styled from "styled-components";
import { useTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";

const DottedTile = (props) => {
    const { title = '' } = props;
    const theme = useTheme();

    const DottedCard = styled.div`
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        box-sizing: border-box;
        padding: 13px;
        &:hover .dotted-icon svg {
            color: ${theme.custom.dualTheme ? theme.palette.primary[600] : grey[500]}
        }
    `;

    return (
        <DottedCard style={{ border: `2px dashed ${theme.palette.primary[200]}` }}>
            <p className="dotted-text">{title}</p>
            <div className="dotted-icon">
                {props.children}
            </div>
        </DottedCard>
    );
}

export default DottedTile;