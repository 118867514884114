import React, { useState } from "react";
import {
  useParams,
  Link as RouterLink,
  useLocation,
  Routes,
  Route,
} from "react-router-dom";
import {
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  detailsTabsBoxStyle,
  getCurrentPathTab,
  overviewListItemStyles,
} from "../services/Utils";
import { getConfigurationDetails } from "../services/configuration";
import { useQuery } from "react-query";
import BreadCrumbsRendered from "../components/BreadCrumbsRendered";
import ConfigurationStatus from "../components/configuration/ConfigurationStatus";
import VehicleShadowList from "../components/vehicle/shadow/VehicleShadowList";
import ConfigurationVehicleTab from "../components/configuration/ConfigurationVehicleTab";
import ConfigurationJobDocumentTab from "../components/configuration/ConfigurationJobDocumentTab";

const PATHS = [
  "overview",
  "config-state",
  "vehicles",
  "jobdocument",
  "jobstatus",
];

export default function Configuration() {
  let params = useParams();
  const { pathname } = useLocation();
  const [value, setValue] = useState(
    getCurrentPathTab(pathname, PATHS, "overview")
  );

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const notifyLinkClick = (toPath) => {
    setValue(getCurrentPathTab(toPath, PATHS, "overview"));
  };

  const { configId = "" } = params || {};

  // Queries
  const { data: { data: configurationDetails = {} } = {} } = useQuery(
    ["configurationDetails", configId],
    getConfigurationDetails
  );

  const {
    configName = "",
    configDescription = "",
    configStatus = "",
    vehicleNos = "",
    createdBy = "",
    campaignCreationDate = "",
    configState = "",
  } = configurationDetails;

  const Overview = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <List>
          <ListItem {...overviewListItemStyles}>
            <ListItemText primary="Configuration Name" secondary={configName} />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="Configuration Description"
              secondary={configDescription}
            />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText primary="Configuration Id" secondary={configId} />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText primary="Vehicle Nos" secondary={vehicleNos} />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="Configuration Status"
              secondary={<ConfigurationStatus status={configStatus} />}
            />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText primary="Created by" secondary={createdBy} />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="Creation Date"
              secondary={moment(campaignCreationDate).format(
                "DD-MMM-yyyy hh:mm A"
              )}
            />
          </ListItem>
        </List>
      </Box>
    );
  };

  const ConfigState = () => {
    const data = JSON.parse(configState);
    const { state: { desired: { configurations = {} } = {} } = {} } =
      data || {};
    const configItems = [];

    configItems.push({
      title: "Key",
      description: Object.keys(configurations).map((i) => {
        return { value: i };
      }),
    });
    configItems.push({
      title: "Value",
      description: Object.values(configurations).map((i) => {
        return { value: i };
      }),
    });

    return (
      <Box {...detailsTabsBoxStyle} width={"40%"}>
        <Typography sx={{ marginBottom: "3%" }}>
          {" "}
          Below Configuration states are{" "}
          <ConfigurationStatus status={configStatus} />
        </Typography>
        <VehicleShadowList configItems={configItems} />
      </Box>
    );
  };

  const Vehicles = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <ConfigurationVehicleTab configurationDetails={configurationDetails} />
      </Box>
    );
  };

  const JobDocument = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <ConfigurationJobDocumentTab />
      </Box>
    );
  };

  return (
    <div className="fragmentContainer">
      <BreadCrumbsRendered
        pathname={pathname}
        notifyLinkClick={notifyLinkClick}
      />

      <Box
        sx={{ width: "100%", marginTop: 4, padding: "2%" }}
        component={Paper}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Overview"
              value={"overview"}
              component={RouterLink}
              to={"overview"}
            />
            <Tab
              label="Config State"
              value={"config-state"}
              component={RouterLink}
              to={"config-state"}
            />
            <Tab
              label="Vehicles"
              value={"vehicles"}
              component={RouterLink}
              to={"vehicles"}
            />
            <Tab
              label="Job Document"
              value={"jobdocument"}
              component={RouterLink}
              to={"jobdocument"}
            />
          </Tabs>
        </Box>
        <div className="tabs-container">
          <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="overview" element={<Overview />} />
            <Route path="config-state" element={<ConfigState />} />
            <Route path="vehicles" element={<Vehicles />} />
            <Route path="jobdocument" element={<JobDocument />} />
          </Routes>
        </div>
      </Box>
    </div>
  );
}
