import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import Constants from "../../services/Constants";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import StatusActiveInactive from "../common/StatusActiveInactive";
import { getVehicleFeaturesAudit } from "../../services/vehicle";

const VehicleFeaturesAudit = ({ vehicleData }) => {
    const [pageSize, setPageSize] = useState(Constants.PAGE_SIZE);
    const getAuditColumnsData = () => {
        return [
            {
                field: "createdAt",
                headerName: "Created On",
                width: 200,
                renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
            },
            {
                field: "featureId",
                headerName: "Feature ID",
                width: 200,
            },
            {
                field: "featureName",
                headerName: "Feature Name",
                width: 200,
                renderCell: ({ row }) => row?.feature?.featureName
            },
            {
                field: "action",
                headerName: "Action",
                width: 150,
            },
            {
                field: "active",
                headerName: "Status",
                width: 200,
                renderCell: ({ row }) => <StatusActiveInactive active={row?.active} />,
            },
            {
                field: "userName",
                headerName: "Created by",
                width: 200,
                renderCell: ({ row }) => row?.userCreated?.userName
            },
        ];
    };

    const auditColumns = getAuditColumnsData();

    const { data: auditData } = useQuery(
        ["vehicleFeaturesAudit", vehicleData?.device?.serialNo],
        getVehicleFeaturesAudit,
        {
            refetchOnWindowFocus: false,
            enabled: !!vehicleData?.device?.serialNo,
            retry: 1
        }
    );

    return (
        <DataGrid
            initialState={{
                sorting: {
                    sortModel: [{ field: "createdAt", sort: "desc" }],
                },
            }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPT}
            pagination
            rows={auditData || []}
            columns={auditColumns}
            sx={{ minHeight: 700 }}
            getRowId={(row) => row.VehicleFeaturesAuditId}
        />
    );
};

export default VehicleFeaturesAudit;