export const getConfigItems = (shadowState = {}, type) => {
    const configItems = [];
    // formating shadow state
    const desiredList = [];
    const reportedList = [];
    const configList = [];
    const {
        state: {
            desired = {},
            reported = {},
        } = {},
        metadata: {
            desired: desiredMData = {},
            reported: reportedMData = {}
        } = {}
    } = shadowState;

    const desiredConf = desired[`${type}`] || {};
    const reportedConf = reported[`${type}`] || {};

    const desiredMeta = desiredMData[`${type}`] || {};
    const reportedMeta = reportedMData[`${type}`] || {};

    const desiredKeys = Object.keys(desiredConf);
    desiredKeys.forEach((i) => {
        const property = desiredConf.hasOwnProperty(i);
        desiredList.push(
            {
                stateMatched: property,
                value: property ? desiredConf[i] : '-',
                timestamp: property ? desiredMeta[i]?.timestamp : null
            }
        )
        configList.push({
            value: i,
            timestamp: null
        })
    });
    desiredKeys.forEach((i) => {
        const property = reportedConf.hasOwnProperty(i);
        reportedList.push(
            {
                stateMatched: property,
                value: property ? reportedConf[i] : '-',
                timestamp: property ? reportedMeta[i]?.timestamp : null
            }
        )
    });
    configItems.push({
        title: "Configurations",
        description: configList,
    });
    configItems.push({
        title: "Desired",
        description: desiredList,
    });
    configItems.push({
        title: "Reported",
        description: reportedList,
    });
    return configItems;
}