import React from "react";
import { getColumnsData } from "./ConfigurationColumns";
import { getConfigurationData } from "../../services/configuration";
import OTAPagination from "../OTAPagination";

const ConfigurationList = () => {
    const columns = getColumnsData();

    return (
        <OTAPagination
            columns={columns}
            endpointFn={getConfigurationData}
            queryName={"configurations"}
            filterMode="server"
            sortByDefault={"campaignCreationDate"}
            getRowId={(row) => row.configId}
        />
    );
};

ConfigurationList.defaultProps = {};

ConfigurationList.propTypes = {};

export default ConfigurationList;
