import React from "react";
import Lottie from "react-lottie";
import { Box, Typography } from "@mui/material";
import { formatFileSize } from "../../services/Utils";
import uploadAnimation from "../../lottieAnimations/lf20_mbe3iiji.json";
import LinearProgress from "@mui/material/LinearProgress";

function FileUploadProgressPane({ uploadProgress }) {
  let { uploadPct = 0 } = uploadProgress;

  return (
    <Box
      sx={{
        width: "100%",
        height: 600,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Box>
        <Typography color="text.primary" sx={{ textAlign: "center", mt: 4 }}>
          Upload In Progress ( {Math.round(uploadPct * 100)}% )
        </Typography>

        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: uploadAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={500}
          width={500}
        />

        <LinearProgress variant="determinate" value={uploadPct * 100} />
        <Typography>
          {formatFileSize(uploadProgress.loaded)} of{" "}
          {formatFileSize(uploadProgress.total)} Uploaded
        </Typography>
      </Box>
    </Box>
  );
}

export default FileUploadProgressPane;
