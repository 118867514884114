import { Box } from '@mui/material';
import React from "react";

const SuccessFailStatus = ({ text, color }) => {    
    let colors = {
        "green": "#3ba272",
        "red": "#f44336",
        "gray": "#f44336",                
    };
        
    let col = colors[color] || 'black';
    return (        
        <Box sx={{ height: '20px', width:"60px",textAlign:'center', textTransform:'upperCase', display: "inline", fontSize: 10, background: col, borderRadius: '5px', color: 'white', padding: "4px 7px", fontWeight: 'bold' }}>
            {text}
        </Box>        
    )
}

export default SuccessFailStatus;