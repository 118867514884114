import React, { useState } from "react";
import { ResponsiveCalendar } from "@nivo/calendar";
import { ResponsiveTimeRange } from "@nivo/calendar";
import { useQuery } from "react-query";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { getDeploymentsCalendar } from "../../services/Dashboard";
import moment from "moment";
import { getLast365DaysDateRange } from "../../services/Utils";

export const randColor = () =>
  `hsl(${Math.round(Math.random() * 360)}, 70%, 50%)`;

const PRIMARY_OPTIONS = [
  { value: "last_365_days", label: "Last 365 Days" },
  { value: "year", label: "By Year" },
];

const DeploymentsCalendarView = () => {
  const last365DaysDateRange = getLast365DaysDateRange();
  const [year, setYear] = useState(new Date().getFullYear());
  const [primaryFilter, setPrimaryFilter] = useState("last_365_days");
  const [period, setPeriod] = useState(last365DaysDateRange);

  const { data, isLoading } = useQuery(
    ["deploymentsCalendar", primaryFilter, year, period],
    getDeploymentsCalendar,
    {
      refetchOnWindowFocus: false,
    }
  );

  const [dates, setDates] = useState(last365DaysDateRange);

  const changeDates = (yr) => {
    setDates({
      from: new Date(`01-01-${yr}`),
      to: new Date(`01-12-${yr}`),
    });
  };

  const theme = useTheme();

  const commonProperties = {
    animate: true,
    activeOuterRadiusOffset: 0,
    height: 200,
    emptyColor: theme.palette.secondary[200],
    colors: Object.values(theme.palette.secondary),
    margin: { top: 0, right: 0, bottom: 30, left: 10 },
    monthBorderColor: "#ffffff",
    monthLegendPosition: "after",
    monthLegendOffset: 15,
    monthBorderWidth: 3,
    dayBorderWidth: 3,
    dayBorderColor: "#ffffff",
  };

  const ResponsiveTimeRangeProps = {
    weekdayTicks: [], // hide weekdays text in chart
    animate: true,
    activeOuterRadiusOffset: 0,
    emptyColor: theme.palette.secondary[200],
    height: 200,
    colors: Object.values(theme.palette.primary),
    align: "center",
    margin: { top: 20, left: 10, right: 10 },
    dayBorderWidth: 3,
    dayBorderColor: "#ffffff",
  };

  const CalendarTooltip = (data) => {
    if (data.value === undefined) return null;
    return (
      <Paper
        style={{
          padding: "6px",
        }}
      >
        <div>
          <Typography color="text.primary" sx={{ fontSize: 16 }}>
            {moment(data.day).format("DD-MMM-yy")}
          </Typography>
          <Typography color="text.secondary" sx={{ fontSize: 12 }}>{`${data.value
            } Deployment${data.value > 1 ? "s" : ""}`}</Typography>
        </div>
      </Paper>
    );
  };

  const handlePrimaryFilterChange = ({ target: { value } }) => {
    setPrimaryFilter(value);
    if (value === "year") {
      setYear(new Date().getFullYear());
      setDates({
        from: new Date(`01-01-${new Date().getFullYear()}`),
        to: new Date(`01-12-${new Date().getFullYear()}`),
      });
      setPeriod(new Date().getFullYear());
    } else if (value === "date_range") {
      setPeriod({
        from: new Date(),
        to: new Date(),
      });
    } else if (value === "last_365_days") {
      const dates365 = getLast365DaysDateRange();
      setDates(dates365);
      setPeriod(dates365);
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography color="text.secondary">Deployments calendar</Typography>
        }
        action={
          <div>
            {primaryFilter === "year" ? (
              <span>
                <Tooltip title={year - 1}>
                  <IconButton
                    color="primary"
                    component="span"
                    size="large"
                    onClick={() => {
                      const yearNew = year - 1;
                      setYear(yearNew);
                      changeDates(yearNew);
                    }}
                  >
                    <ArrowLeft style={{ fontSize: "32px" }} />
                  </IconButton>
                </Tooltip>
                <Chip label={year} />
                <Tooltip title={year + 1}>
                  <IconButton
                    color="primary"
                    component="span"
                    size="large"
                    onClick={() => {
                      const yearNew = year + 1;
                      setYear(yearNew);
                      changeDates(yearNew);
                    }}
                    disabled={new Date().getFullYear() === year}
                  >
                    <ArrowRight style={{ fontSize: "32px" }} />
                  </IconButton>
                </Tooltip>
              </span>
            ) : null}
            <span>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                {/* <InputLabel id="demo-simple-select-standard-label">
                  Filter
                </InputLabel> */}
                <Select
                  // label="Filter"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={primaryFilter}
                  onChange={handlePrimaryFilterChange}
                >
                  {PRIMARY_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </span>
          </div>
        }
      ></CardHeader>
      <CardContent style={{ height: "250px" }}>
        {primaryFilter === "year" ? (
          <>
            {!isLoading && (
              <ResponsiveCalendar
                height={400}
                data={data || []}
                from={dates.from}
                to={dates.to}
                {...commonProperties}
                tooltip={CalendarTooltip}
              />
            )}
          </>
        ) : (
          <>
            {!isLoading && (
              <ResponsiveTimeRange
                data={data || []}
                from={dates.from}
                to={dates.to}
                {...ResponsiveTimeRangeProps}
                tooltip={CalendarTooltip}
              />
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

DeploymentsCalendarView.defaultProps = {};

DeploymentsCalendarView.propTypes = {};

export default DeploymentsCalendarView;
