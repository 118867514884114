import React from "react";
import DetailsButton from "./DetailsButton";
import MakeDeployment from "./MakeDeployment";
import { capitalize, formatDate_12h } from "../../services/Utils";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";

const getColumnsData = () => {
  return [
    {
      field: "releaseName",
      headerName: "Release Name",
      flex: 2,
      editable: false,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      editable: false,
      valueFormatter: ({ value }) => capitalize(value),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      editable: false,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.createdAt} />
      ),
    },
    {
      field: "imagesCount",
      headerName: "No. of images",
      flex: 0.5,
      type: "number",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (data) => <p>{data?.row?.imagesCount}</p>,
    },
    {
      field: "none",
      headerName: "Actions",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (data) => (
        <>
          <Check I={CAN.CREATE} a={SUBJECTS.DEPLOYMENT}>
            <MakeDeployment data={data} />
          </Check>
          <DetailsButton size="small" id={data.id}>
            Details
          </DetailsButton>
        </>
      ),
    },
  ];
};

export { getColumnsData };
