import React, { createContext, useMemo, useState, useCallback } from "react";
import { ToastList } from "./ToastList";
export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const removeToast = (id) => {
    const newList = toasts.filter((i) => i.id !== id);
    setToasts([...newList]);
  };

  const addToast = useCallback(
    (toast) => {
      setToasts([...toasts, { ...toast, id: new Date().getTime() }]);
    },
    [toasts]
  );

  const contextValue = useMemo(() => {
    return { addToast };
  }, [addToast]);

 
  const toastListProps = { removeToast, toasts };

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <ToastList {...toastListProps} />
    </ToastContext.Provider>
  );
};
