import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { WizardContext, TABS } from "../Context";
import { getAllCompletedImages } from "../../../services/Image";
import { formatDate } from "../../../services/Utils";

const ReviewDetails = () => {
  const { state } = useContext(WizardContext);
  const {
    toggleVehicle,
    vehicles,
    vehicleGroups,
    toggleRelease,
    releaseName,
    release: { label: releaseLabel },
    images,
    campaignEndDate,
    campaignStartDate,
    deploymentName,
    deploymentType,
  } = state;

  // Queries
  const { data: { data: { res: imageData = [] } = {} } = {} } = useQuery(
    "ImageList",
    getAllCompletedImages,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Grid container justifyContent="center" spacing={2} className="wizard-grid">
      <Grid item xs={10}>
        <p className="wizard-heading">Deployment summary</p>
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={4} className="wizard-grid">
          <Grid item xs={6}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "500" }}
              gutterBottom
              component="div"
            >
              Deployment name
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginBottom: "5%" }}
            >
              {deploymentName}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "500" }}
              gutterBottom
              component="div"
            >
              Deployment Type
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginBottom: "5%" }}
            >
              {deploymentType}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "500" }}
              gutterBottom
              component="div"
            >
              Campaign start date
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginBottom: "5%" }}
            >
              {formatDate(campaignStartDate)}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "500" }}
              gutterBottom
              component="div"
            >
              Campaign end date
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginBottom: "5%" }}
            >
              {campaignEndDate ? formatDate(campaignEndDate) : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {toggleRelease === TABS.CREATE_RELEASE && (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500" }}
                  gutterBottom
                  component="div"
                >
                  Release Name
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ marginBottom: "5%" }}
                >
                  {releaseName}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500" }}
                  gutterBottom
                  component="div"
                >
                  Selected Images ({images.length})
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ marginBottom: "5%" }}
                >
                  {images.map((ob) => {
                    const { fileName = "", version = "" } = imageData.find(
                      (i) => i.imageUploadId === ob
                    );
                    return (
                      <span key={ob}>{`${fileName} - V${version}, `}</span>
                    );
                  })}
                </Typography>
              </>
            )}
            {toggleRelease === TABS.CHOOSE_RELEASE && (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500" }}
                  gutterBottom
                  component="div"
                >
                  Selected Release
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ marginBottom: "5%" }}
                >
                  {releaseLabel}
                </Typography>
              </>
            )}

            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "500" }}
              gutterBottom
              component="div"
            >
              Selected{" "}
              {toggleVehicle === TABS.CHOOSE_VEHICLES
                ? `Vehicles (${vehicles.length})`
                : `Vehicle Groups (${vehicleGroups.length})`}
            </Typography>
            {toggleVehicle === TABS.CHOOSE_VEHICLES && (
              <Typography
                variant="body2"
                gutterBottom
                sx={{ marginBottom: "5%" }}
              >
                {vehicles.map((v, i) => (
                  <span key={v}>{`${v}${
                    vehicles.length - 1 > i ? ", " : ""
                  }`}</span>
                ))}
              </Typography>
            )}
            {toggleVehicle === TABS.CHOOSE_VEHICLE_GROUPS && (
              <Typography
                variant="body2"
                gutterBottom
                sx={{ marginBottom: "5%" }}
              >
                {vehicleGroups.map((g, i) => (
                  <span key={g?.vehicleGroupId}>{`${g?.vehicleGroupName
                    ?.split("_")
                    .pop()}${vehicleGroups.length - 1 > i ? ", " : ""}`}</span>
                ))}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewDetails;
