import React from "react";
import { Button } from "@mui/material";
import { useMutation } from "react-query";
import { useToast } from "../toast";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { downloadfile } from "../../services/FileDownload";
import { CAN, SUBJECTS } from "../../auth/permissions";
import Check from "../../auth/ability";

const DownloadCsv = ({ data }) => {
  const { filePath, fileName } = data;
  const { addToast } = useToast();

  const { mutate: download, isLoading } = useMutation(downloadfile, {
    onSuccess: ({ data }) => {
      window.open(data?.url, "_top"); // "_top" option downloads the url by loading it in the same window view.
    },
    onError: (e) => {
      console.log("error", e);
      const { response } = e;
      addToast({
        type: "error",
        message: response?.data?.message || "Failed to download!",
        autoClose: 3000,
      });
    },
  });

  const handleDownload = () => {
    download({ filePath, fileName });
  };

  return (
    <div>
      <Check I={CAN.MANAGE} a={SUBJECTS.PROVISION}>
        {filePath && (
          <Button
            size="small"
            onClick={() => handleDownload()}
            disabled={isLoading}
          >
            {isLoading ? <HourglassTopIcon /> : "Download"}
          </Button>
        )}
      </Check>
    </div>
  );
};

export default DownloadCsv;
