import { Tooltip } from "@mui/material";
import React from "react";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";

const getColumnsData = () => {
  // Column props reference available at - ota-portal_v2\node_modules\.pnpm\@mui+x-data-grid@5.15.2_rrttaqilrsb3aifveu7wjrwxnm\node_modules\@mui\x-data-grid\models\colDef\gridColDef.d.ts
  return [
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      flex: 2,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 1.25,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value || "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "systemUser",
      headerName: "System User",
      flex: 0.75,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "availableFeatures",
      headerName: "Available Features",
      flex: 0.75,
      renderCell: (data) => {
        return <Tooltip title={data?.row?.active ? "NAVIGATION" : "-"}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.row?.active ? "NAVIGATION" : "-"}
          </p>
        </Tooltip>
      },
    },
    {
      field: "createdOn",
      headerName: "Created At",
      type: "dateTime",
      flex: 0.75,
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.createdOn} />
      ),
    },
    {
      field: "onOfCSV",
      headerName: "CSV",
      flex: 0.5,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 2,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      ),
    },
  ];
};

export { getColumnsData };
