import React, { useState } from "react";
import { Box, Button, Grid, Stack, Tooltip } from "@mui/material";
import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import AllVehiclesList from "./AllVehiclesList";
import VehiclesInGroupList from "./VehiclesInGroupList";

const VehiclesTransferList = (props) => {
  const {
    isUpdate,
    vehicleGroups,
    vehiclesList,
    vehiclesListMeta,
    vehiclesInGroupList,
    vehiclesInGroupListMeta,
    selectedVehicles = [],
    setSelectedVehicles,
    removedVehicles = [],
    setRemovedVehicles,
    selectedVehicleGroup,
    setSelectedVehicleGroup,
    primaryPaginationData,
    setPrimaryPaginationData,
    secondaryPaginationData,
    setSecondaryPaginationData,
    onSearchInputPrimary,
    onSearchInputSecondary,
    handlePrimaryPageChange,
    handleSecondaryPageChange,
  } = props;
  const [showTransfer, setShowTransfer] = useState(false);
  const [allVehiclesSelected, setAllVehiclesSelected] = useState([]);
  const [existingVehiclesSelected, setExistingVehiclesSelected] = useState([]);
  const toggleAddRemove = () => {
    setShowTransfer(!showTransfer);
  };

  const allVehiclesListProps = {
    isUpdate,
    isPrimaryList: true,
    isSearchable: true,
    items: vehiclesList,
    meta: vehiclesListMeta,
    paginationData: primaryPaginationData,
    setPaginationData: setPrimaryPaginationData,
    handlePageChange: handlePrimaryPageChange,
    onSearchInput: onSearchInputPrimary,
    selectedVehicles,
    setSelectedVehicles,
    selected: allVehiclesSelected,
    setSelected: setAllVehiclesSelected,
    vehiclesInGroupList,
    removedVehicles,
  };

  const vehiclesInGroupListProps = {
    isUpdate,
    isSearchable: true,
    items: vehiclesInGroupList,
    meta: vehiclesInGroupListMeta,
    paginationData: secondaryPaginationData,
    setPaginationData: setSecondaryPaginationData,
    handlePageChange: handleSecondaryPageChange,
    onSearchInput: onSearchInputSecondary,
    selectedVehicles,
    setSelectedVehicles,
    removedVehicles,
    setRemovedVehicles,
    selected: existingVehiclesSelected,
    setSelected: setExistingVehiclesSelected,
  };
  return (
    <Box sx={{ mt: 4 }}>
      <Grid container>
        <Grid item xs={5}>
          <AllVehiclesList
            title={"Vehicles available to add"}
            {...allVehiclesListProps}
          />
        </Grid>
        <Grid item xs={2}>
          <Stack
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tooltip title="Add to group">
              <Button
                disabled={allVehiclesSelected?.length < 1}
                color="primary"
                component="label"
                sx={{ mb: 2 }}
                onClick={() => {
                  const allSelected = JSON.parse(
                    JSON.stringify(allVehiclesSelected)
                  );
                  setSelectedVehicles(
                    [...selectedVehicles, ...allSelected].filter(
                      (i) =>
                        !vehiclesInGroupList?.filter(
                          (j) => i.vehicleKey === j.vehicleKey
                        )?.length
                    )
                  );
                  setRemovedVehicles([
                    ...removedVehicles?.filter((i) => {
                      const foundItem = allSelected?.filter(
                        (j) => j?.vehicleKey === i?.vehicleKey
                      )?.length;
                      return !foundItem;
                    }),
                  ]);
                  setAllVehiclesSelected([]);
                }}
              >
                <ArrowForwardIosRounded />
              </Button>
            </Tooltip>
            <Tooltip title="Remove from group">
              <Button
                disabled={existingVehiclesSelected?.length < 1}
                color="primary"
                component="label"
                sx={{ mt: 2 }}
                onClick={() => {
                  const existingSelected = JSON.parse(
                    JSON.stringify(existingVehiclesSelected)
                  );
                  const newList = [...removedVehicles, ...existingSelected];
                  setRemovedVehicles(newList);
                  setSelectedVehicles([
                    ...selectedVehicles?.filter((i) => {
                      const foundItem = existingSelected?.filter(
                        (j) => j?.vehicleKey === i?.vehicleKey
                      )?.length;
                      return !foundItem;
                    }),
                  ]);
                  setExistingVehiclesSelected([]);
                }}
              >
                <ArrowBackIosNewRounded />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>

        <Grid item xs={5}>
          <VehiclesInGroupList
            title={"Vehicles in Group"}
            {...vehiclesInGroupListProps}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default VehiclesTransferList;
