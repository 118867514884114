const configurations = {
  local: {
    api: {
      baseUrl: "http://localhost:3500",
      schedulerUrl: "http://localhost:3600",
    },
  },
  dev: {
    api: {
      baseUrl: "https://api.otadev.visteoncloud.com",
    },
  },
  nextdev: {
    api: {
      baseUrl: "https://api.otanextdev.visteoncloud.com",
    },
  },
  staging: {
    api: {
      baseUrl: "https://api.otastaging.visteoncloud.com",
    },
  },
  prod: {
    api: {
      baseUrl: "https://api.otaprod.visteoncloud.com",
    },
  },
};

const getConfig = function (stage) {
  if (!configurations[stage]) {
    console.error("No configuration defined for this stage");
    return;
  }
  return configurations[stage];
};

let config = { ...getConfig(process.env.REACT_APP_STAGE) };

export default config;
