import { useEffect } from "react";
import { Alert } from "@mui/material";
import Slide from "@mui/material/Slide";

export const Toast = ({
  id,
  type,
  message,
  autoClose,
  direction = "right",
  onClose,
}) => {
  useEffect(() => {
    var timer = null;
    if (autoClose) {
      timer = setTimeout(() => onClose(id), autoClose);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [id, type, message, autoClose, onClose]);
  return (
    <Slide direction={direction} in={id} mountOnEnter unmountOnExit>
      <Alert
        severity={type}
        onClose={() => {
          onClose(id);
        }}
      >
        {message}
      </Alert>
    </Slide>
  );
};
