import React, { useState, useContext, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Skeleton,
  TextField,
  InputAdornment,
  Pagination,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getVehicleData } from "../../../services/deployment";
import { useQuery } from "react-query";
import { WizardContext, ACTIONS } from "../Context";
import { getColumnsData } from "../../vehicle/VehicleColumns";
import { DEPLOYMENT_TARGETS_MAX_LIMIT } from "../../../services/Constants";

const VehicleList = () => {
  const [searchString, setSearchString] = useState("");
  const [paginationData, setPaginationData] = useState({ page: 1 });
  const [page, setPage] = useState(1);
  const handlePageChange = (event, pageNumber) => {
    console.log(event, pageNumber);
    setPage(pageNumber);
  };
  return (
    <VehicleListGrid
      searchString={searchString}
      setPaginationData={setPaginationData}
      paginationData={paginationData}
      page={page}
      handlePageChange={handlePageChange}
      setSearchString={setSearchString}
    />
  );
};

const VehicleListGrid = ({
  searchString,
  setPaginationData,
  paginationData,
  page,
  handlePageChange,
  setSearchString,
}) => {
  const defaultFilters = [
    {
      value: true,
      operatorValue: "equals",
      columnField: "active",
    },
  ];
  const { state, dispatch } = useContext(WizardContext);
  const { vehicles = [] } = state;
  const [checked, setChecked] = useState(vehicles || []);
  const [results, setResults] = useState([]);
  const [filterObject, setFilterObject] = useState({
    value: "",
    operatorValue: "contains",
    columnField: "serialNo",
  });

  const columns = getColumnsData();

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);

    dispatch({
      type: ACTIONS.UPDATE_VEHICLE,
      payload: {
        vehicles: newChecked,
        disableNextBtn: newChecked.length <= 0,
      },
    });
  };

  // Queries
  let { data: { data: vehicleData } = [], isLoading } = useQuery(
    [
      "vehicles",
      page, // current page
      50, // pageSize
      "serialNoCreatedAt", //field to sort by
      "DESC", // sort
      {}, //filterValue,
      columns, //columns,
      [...defaultFilters, filterObject], // search object
    ],
    getVehicleData,
    {
      onSuccess: (response) => {
        const { data: { meta = {} } = {} } = response;
        setPaginationData({
          rowCount: meta.itemCount,
          count: meta.pageCount,
          page: meta.page,
        });
      },
      refetchOnWindowFocus: true,
    }
  );

  const onSearchInput = (value) => {
    if (!!value) {
      setFilterObject({
        value: value,
        operatorValue: "contains",
        columnField: "serialNo",
      });
    } else {
      setFilterObject({
        value: "",
        operatorValue: "contains",
        columnField: "serialNo",
      });
    }
  };

  const loadVehicles = vehicleData?.res?.length ? vehicleData.res : [];

  useEffect(() => {
    onSearchInput(searchString);
  }, [searchString]);

  return (
    <>
      <Grid
        container
        spacing={0}
        style={{
          marginBottom: "18px",
          marginTop: "18px",
          position: "sticky",
          top: "0",
          backgroundColor: "#ffffff",
        }}
      >
        <Grid xs={8} item></Grid>
        <Grid
          item
          xs={4}
          sx={{
            marginBottom: "20px",
            textAlign: "right",
          }}
        >
          <TextField
            style={{ width: "100%" }}
            id="input-with-icon-textfield"
            label="Search Vehicles"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            onChange={(e) => {
              const {
                target: { value },
              } = e;
              setSearchString(value);
            }}
          />
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container spacing={0}>
          {[...Array(20)].map((_, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Skeleton animation="wave" height={50} width={"90%"} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <Grid
            container
            spacing={0}
            sx={{
              background: "#e8e8e894",
              height: "auto",
              padding: "15px",
              paddingLeft: "25px",
              borderRadius: "10px",
              display: "grid",
              gridTemplateColumns: "33.33% 33.33% 33.33%",
              gridAutoRows: "minmax(40px, 40px)",
              rowGap: "8px",
            }}
          >
            {loadVehicles && loadVehicles.length ? (
              loadVehicles?.map((ob) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={ob.serialNo}
                // sx={{ height: "12px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          vehicles?.length &&
                          vehicles?.length === DEPLOYMENT_TARGETS_MAX_LIMIT &&
                          !vehicles.includes(ob.serialNo)
                        }
                        name="vehicles"
                        defaultChecked={
                          vehicles?.includes(ob.serialNo) ? true : false
                        }
                        onClick={() => handleToggle(ob.serialNo)}
                      />
                    }
                    label={
                      <div
                        title={ob.serialNo}
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "176px",
                          fontSize: "0.9em"
                        }}
                      >
                        {ob.serialNo}
                      </div>
                    }
                    key={ob.serialNo}
                  />
                </Grid>
              ))
            ) : (
              <Typography variant="button">No results</Typography>
            )}
          </Grid>
          <Typography variant="caption">{`A maximum of ${DEPLOYMENT_TARGETS_MAX_LIMIT} vehicles can be selected.`}</Typography>
          <Pagination
            sx={{ mt: "6px", marginTop: "3%" }}
            onChange={handlePageChange}
            {...paginationData}
            color="primary"
          />
        </>
      )}
    </>
  );
};

VehicleList.defaultProps = {};

VehicleList.propTypes = {};

export default VehicleList;
