import React from "react";
import { getColumnsData } from "./ReleaseColumns";
import { getReleaseData } from "../../services/Release";
import OTAPagination from "../OTAPagination";

const ReleaseGrid = () => {
  const columns = getColumnsData();

  return (
    <OTAPagination
      columns={columns}
      endpointFn={getReleaseData}
      queryName={"releases"}
      filterMode="server"
      getRowId={(row) => row.releaseId}
      exportCSV
    />
  );
};

export default ReleaseGrid;
