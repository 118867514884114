import React, { useState, useEffect } from "react";
import { Alert, Box, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getVehicleShadow,
  updateVehicleShadow,
  getAllFeatures,
} from "../../../services/vehicle";
import SchemaEditor from "./SchemaEditor";
import { useToast } from "../../toast";
import VehicleShadowList from "./VehicleShadowList";
import { detailsTabsBoxStyle } from "../../../services/Utils";
import { getConfigItems } from "./shadowUtils";
import useModule from "../../../hooks/useModule";
import { MODULES } from "../../../services/Constants";

const VehicleCustomConfigTab = ({ vehicleId, vehicleData }) => {
  const { addToast } = useToast();
  const { validateModuleWithMessage } = useModule();
  const [shadowState, setShadowState] = useState({});
  const [config, setConfig] = useState({});
  const features = [];
  let configItems = [];

  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const {
    data: { data: shadowData = {} } = {},
    isLoading: getShadowLoading,
    refetch,
  } = useQuery(["getVehicleShadow", vehicleId], getVehicleShadow, {
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const {
    data: { data: featuresData = [] } = {},
    isLoading: getFeaturesLoading,
  } = useQuery(["getAllFeatures"], getAllFeatures, {
    refetchOnWindowFocus: false,
    enabled: !!shadowState?.state?.desired,
    retry: 1,
  });

  // Mutations
  const { mutate, isLoading: mutateLoading } = useMutation(
    updateVehicleShadow,
    {
      onSuccess: (data) => {
        if (data) {
          addToast({
            type: "success",
            message: `Shadow state updated.`,
            autoClose: 3000,
          });
        }
      },
      onError: (err) => {
        const {
          response: { data: errorData },
        } = err;
        if (err && errorData) {
          addToast({
            type: "error",
            message: errorData.message,
            autoClose: 3000,
          });
        }
      },
      onSettled: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries("getVehicleShadow");
      },
    }
  );

  const onChange = (updatedObj) => setConfig(updatedObj);

  const submitConfig = () => {
    if (!validateModuleWithMessage(MODULES.CONFIGURATION)) return;
    let shadowData = shadowState;
    shadowData.state.desired.configurations = {
      ...shadowData.state.desired.configurations,
      ...config,
    };
    delete shadowData.state.delta;
    delete shadowData.metadata;
    mutate({
      thingName: vehicleId,
      payload: JSON.stringify({ state: shadowData.state }),
    });
  };

  useEffect(() => {
    if (shadowData && shadowData.payload) {
      const shadowPayload = JSON.parse(shadowData?.payload);
      setShadowState(shadowPayload);
    }
  }, [shadowData]);

  if (!vehicleData?.device?.certAvailable) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="error">
          Device setup incomplete!
        </Alert>
      </Box>
    );
  }

  if (!shadowData.payload && !getShadowLoading) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="warning">
          Vehicle shadow not found!
        </Alert>
      </Box>
    );
  } else {
    configItems = getConfigItems(shadowState, 'configurations');
  }

  const { state: { desired = {} } = {} } = shadowState || {};
  const { configurations = {} } = desired || {};
  featuresData.forEach((i) => features.push(i.featureId));
  const entries = Object.entries(configurations);
  const rows = entries.map((entry, i) => {
    const [key, val] = entry;
    return {
      keyItem: key,
      valueItem: `${val}`,
      removeDelete: true,
    };
  });

  return (
    <Box {...detailsTabsBoxStyle}>
      {getShadowLoading || getFeaturesLoading || mutateLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box {...detailsTabsBoxStyle}>
              <Alert variant="outlined" severity="info">
                Allows OEM to create custom parameters for vehicles
              </Alert>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <VehicleShadowList configItems={configItems} refetch={refetch} />
          </Grid>
          <Grid item xs={6}>
            <SchemaEditor
              keyInputPlaceholder="key"
              valueInputPlaceholder="value"
              rows={rows}
              features={features}
              onChange={onChange}
              submitConfig={submitConfig}
              validateModuleWithMessage={validateModuleWithMessage}
              addToast={addToast}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default VehicleCustomConfigTab;
