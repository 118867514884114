import React, { useState } from "react";
import { useQuery } from "react-query";
import { getVehicleDetails } from "../../services/vehicle";
import { getColumnsData } from "./VehicleDeploymentColumns";
import { DataGrid } from "@mui/x-data-grid";
import Constants, { MODULES, MODULE_TEXT } from "../../services/Constants";
import { Box, Alert } from "@mui/material";
import { detailsTabsBoxStyle } from "../../services/Utils";
import useModule from "../../hooks/useModule";

const VehicleDeploymentsTab = (props) => {
  const { validate } = useModule();
  const { vehicleId } = props;
  const [pageSize, setPageSize] = useState(Constants.PAGE_SIZE);
  const columns = getColumnsData({
    vehicleId,
  });

  // Queries
  const { data: { data: vehicleData = [] } = {}, isLoading } = useQuery(
    ["vehicleDetails", vehicleId],
    getVehicleDetails,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { deployments = [] } = vehicleData;

  if (!validate(MODULES.OTA)) {
    return <Box {...detailsTabsBoxStyle}>
      <Alert variant="outlined" severity="warning">
        {MODULE_TEXT.OTA} module not enabled for {localStorage.selectedCustomer} customer
      </Alert>
    </Box>
  }

  return (
    <Box {...detailsTabsBoxStyle}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "campaignCreationDate", sort: "desc" }],
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPT}
        pagination
        loading={isLoading}
        rows={deployments}
        columns={columns}
        sx={{ minHeight: 600 }}
        checkboxSelection={false}
        getRowId={(row) => row.deploymentId}
      />
    </Box>
  );
};

export default VehicleDeploymentsTab;
