import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FileUpload } from "./index";
import FileUploadDialog from "./FileUploadDialog";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { useToast } from "../toast";
import { IMAGE_UPLOAD_ERRORS } from "../../services/Constants";

const ImageUploadDataTableRowActions = ({ data }) => {
  const {
    row: { uploadStatus },
  } = data || {};
  const [openModal, setOpenModal] = useState(false);
  const [resumeUpload, setResumeUpload] = useState(null);
  const [resumeUpdloadStep, setResumeUpdloadStep] = useState("0");
  const { addToast } = useToast();

  const handleResumeUpload = (id) => {
    if (window.navigator.onLine) {
      if (!!id) {
        openReuploadDialog(id);
      } else {
        setOpenModal(true);
      }
    } else {
      addToast({
        type: "error",
        message: IMAGE_UPLOAD_ERRORS.ERR_NETWORK_ISSUE,
        autoClose: 3000,
      });
    }
  };

  const handleClose = () => {
    resetValues();
  };

  const resetValues = () => {
    setResumeUpload(null);
    setResumeUpdloadStep("0");
    setOpenModal(false);
  };
  const openReuploadDialog = (id) => {
    setResumeUpdloadStep("2");
    setResumeUpload(id);
    setOpenModal(true);
  };

  return (
    <>
      <div>
        <Button size="small" component={RouterLink} to={`/Images/${data.id}`}>
          Details
        </Button>
        {!(uploadStatus === "Failed" || uploadStatus === "Completed") && (
          <>
            <Check I={CAN.MANAGE} a={SUBJECTS.IMAGE}>
              {<span>|</span>}
              <Button size="small" onClick={() => handleResumeUpload(data?.id)}>
                Upload
              </Button>
            </Check>
          </>
        )}
      </div>
      <FileUploadDialog
        openModal={openModal}
        handleClose={handleClose}
        resumeUpload={resumeUpload}
        resumeUpdloadStep={resumeUpdloadStep}
      />
    </>
  );
};
export default ImageUploadDataTableRowActions;
