import React, { useContext } from "react";
import { Button, Tooltip } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { WizardContext, ACTIONS, TABS } from "../wizard/Context";

const MakeDeployment = (props) => {
  const {
    data: { row = {} },
  } = props;
  const { releaseId = "", releaseName = "" } = row;
  const { dispatch } = useContext(WizardContext);

  return (
    <Tooltip title="Make Deployment">
      <Button
        variant="text"
        startIcon={<RocketLaunchIcon />}
        onClick={() =>
          dispatch({
            type: ACTIONS.MAKE_DEPLOYMENT,
            payload: {
              release: { id: releaseId, label: releaseName },
              toggleRelease: TABS.CHOOSE_RELEASE,
            },
          })
        }
      ></Button>
    </Tooltip>
  );
};

MakeDeployment.defaultProps = {};

export default MakeDeployment;
