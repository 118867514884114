import { Amplify } from "aws-amplify";

export function configureAmplify() {
  let ob = {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_OTA_APP_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  };
  console.log(ob);
  Amplify.configure({
    Auth: ob,
  });

  /*axios.get(Constants.SERVER_CONFIG).then((result) => {
    Amplify.configure({
      Auth: {
        region: result.data.cognito.AWS_REGION,
        userPoolId: result.data.cognito.USER_POOL_ID,
        userPoolWebClientId: result.data.cognito.USER_POOL_WEBCLIENT_ID,
        authenticationFlowType: "USER_PASSWORD_AUTH",
      },
    });
  });*/
}
