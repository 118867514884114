import React, { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import "../styles/user.css";
import { Route, Routes } from "react-router-dom";
import SchedulerReport from "../components/reports/SchedulerReport";
import ProvisioningReport from "../components/reports/ProvisioningReport";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`reports-tabpanel-${index}`}
      aria-labelledby={`reports-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Reports = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Routes>
      <Route
        path="/"
        exact
        element={
          <div className="fragmentContainer">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="reports tabs"
              >
                <Tab label="Provisioning" />
                <Tab label="Scheduler" />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <ProvisioningReport />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SchedulerReport />
            </TabPanel>
          </div>
        }
      />
    </Routes>
  );
};

export default Reports;
