import React, { useState, useContext, useEffect } from 'react';
import styled from "styled-components";
import {
    Button,
    Popover,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { WizardContext, ACTIONS } from "../wizard/Context";

const ExportWrapper = styled.div`
  margin: 0% 0% 1% 0.5%;
  padding: 0;
`;

const ExportOption = ({ data, endpointFn, queryName }) => {
    const { dispatch } = useContext(WizardContext);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'export-popover' : undefined;

    const [initiate, setInitiate] = useState(false);

    useEffect(() => {
        if (initiate) {
            dispatch({
                type: ACTIONS.EXPORT_DOWNLOAD,
                payload: {
                    initiateDownload: true,
                    downloadData: {
                        data,
                        endpointFn,
                        queryName
                    }
                }
            })
            setInitiate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initiate]);

    return <ExportWrapper>
        <Button aria-describedby={id}
            variant="text"
            disabled={(initiate) && true}
            startIcon={(initiate) ? <HourglassTopIcon /> : <GetAppIcon />}
            onClick={handleClick}
        >
            Export
        </Button>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { handleClose(); setInitiate(true) }}>
                        <ListItemText primary="Download as CSV" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Popover>
    </ExportWrapper >
}

export default ExportOption;