import { Typography, Breadcrumbs } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const BreadCrumbsRendered = (props) => {
  const { pathname, notifyLinkClick } = props;
  const segments = !!pathname ? pathname.split("/").filter((i) => i) : [];
  const getHref = (segment, index) => {
    if (!!segment && segments?.length) {
      return "/" + segments?.slice(0, index + 1).join("/");
    }
  };
  return (
    <Breadcrumbs aria-label="breadcrumb" className="internal-breadcrumb">
      <RouterLink to="/">App</RouterLink>
      {segments?.length &&
        segments.map((segment, index) => (
          <span>
            {segments.length - 1 === index ? (
              <Typography color="text.primary">{segment}</Typography>
            ) : (
              <>
                <RouterLink
                  to={getHref(segment, index)}
                  onClick={() => notifyLinkClick(getHref(segment, index))}
                >
                  {segment}
                </RouterLink>
              </>
            )}
          </span>
        ))}
    </Breadcrumbs>
  );
};

export default BreadCrumbsRendered;
