import React, { useEffect, useState } from "react";
import { Ability, AbilityBuilder } from "@casl/ability";
import { getUserGroups } from "../services/Users";
import { CAN, USER_GROUPS, SUBJECTS } from "./permissions";
import { Can } from "@casl/react";

const defineAbility = (groups = []) => {
  const { can, build } = new AbilityBuilder(Ability);

  if (groups.includes(USER_GROUPS.GUEST)) {
    can(CAN.READ, SUBJECTS.ALL);
  }
  if (groups.includes(USER_GROUPS.CAMPAIGN_APPROVER)) {
    can(CAN.PUBLISH, SUBJECTS.DEPLOYMENT);
    can(CAN.PUBLISH, SUBJECTS.CONFIGURATION);
    can(CAN.CANCEL, SUBJECTS.DEPLOYMENT);
    can(CAN.CANCEL, SUBJECTS.CONFIGURATION);
  }
  if (groups.includes(USER_GROUPS.CAMPAIGN_CREATOR)) {
    can(CAN.CREATE, SUBJECTS.ALL);
  }
  if (groups.includes(USER_GROUPS.ADMIN)) {
    can(CAN.MANAGE, SUBJECTS.ALL);
  }

  return build();
};

const Check = ({ children, bypass = false, ...rest }) => {
  const [groups, setGroups] = useState([]);
  const ability = defineAbility(groups);
  useEffect(() => {
    getUserGroups().then((data) => setGroups(data));
  }, []);
  if (bypass) {
    return <>{children}</>;
  }
  return (
    <Can {...rest} ability={ability}>
      <>{children}</>
    </Can>
  );
};

export default Check;
