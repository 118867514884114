import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "@mui/material";
import { capitalize, formatDate_12h } from "../../services/Utils";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import { DeploymentStatus } from "../deployment";

const getColumnsData = ({ vehicleGroupId }) => {
  return [
    // {
    //   field: "deploymentId",
    //   headerName: "Deployment ID",
    //   width: 300,
    //   editable: false,
    // },
    {
      field: "deploymentName",
      headerName: "Deployment Name",
      width: 250,
      editable: false,
    },
    {
      field: "campaignStartDate",
      headerName: "Campaign Start Date",
      sortable: false,
      width: 160,
      type: "dateTime",
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "campaignEndDate",
      headerName: "Campaign End Date",
      sortable: false,
      width: 160,
      type: "dateTime",
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      sortable: false,
      width: 150,
      valueFormatter: ({ value = "" }) => capitalize(value),
    },
    {
      field: "campaignCreationDate",
      headerName: "Creation Date",
      sortable: false,
      width: 180,
      type: "dateTime",
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "deploymentStatus",
      headerName: "Status",
      flex: 0.75,
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: (data) => {
        return <DeploymentStatus status={data?.row?.deploymentStatus} />;
      },
    },
    {
      field: "empty",
      headerName: "Actions",
      width: 150,
      editable: false,
      renderCell: (data) => {
        const { row: { deploymentId = "" } = {} } = data;
        return (
          <Button
            size="small"
            component={RouterLink}
            to={`/groups/${vehicleGroupId}/deployments/${deploymentId}`}
          >
            Details
          </Button>
        );
      },
    },
  ];
};

export { getColumnsData };
