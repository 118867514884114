import React, { Fragment, useContext } from "react";
import { WizardContext, ACTIONS } from "../wizard/Context";
import TuneIcon from "@mui/icons-material/Tune";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    CardActionArea,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useQuery } from "react-query";
import { getStatsForDashboard } from "../../services/Dashboard";
import { grey } from "@mui/material/colors";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import useModule from "../../hooks/useModule";
import { MODULES } from "../../services/Constants";
import DottedTile from "../common/DottedTile";

const ConfigurationStats = () => {
    const theme = useTheme();
    const { dispatch } = useContext(WizardContext);
    const { validateModuleWithMessage } = useModule();
    const { data: { configurations = 0 } = {} } = useQuery(
        "getConfigStats",
        getStatsForDashboard,
        {
            refetchOnWindowFocus: false,
        }
    );

    return (
        <Fragment>
            <Grid item xs={6} md={2}>
                <Card>
                    <CardContent className="tile-cards">
                        <Typography color="text.secondary" gutterBottom>
                            Total Configurations
                        </Typography>
                        <Typography
                            className="tile-number"
                            color={theme.palette.primary['A700']}
                            sx={{ fontSize: 30, fontWeight: 400 }}
                        >
                            {configurations}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>

            <Check I={CAN.CREATE} a={SUBJECTS.CONFIGURATION}>
                <Grid item xs={12} sm={6} md={2}>
                    <CardActionArea
                        onClick={() => {
                            if (validateModuleWithMessage(MODULES.CONFIGURATION)) {
                                dispatch({
                                    type: ACTIONS.SHOW_CONFIG_WIZARD,
                                })
                            }
                        }}
                    >
                        <DottedTile title={"CREATE A CONFIGURATION"}>
                            <TuneIcon
                                sx={{ fontSize: "3.5rem", color: grey[500] }}
                            />
                        </DottedTile>
                    </CardActionArea>
                </Grid>
            </Check>
        </Fragment>
    );
};

ConfigurationStats.defaultProps = {};

ConfigurationStats.propTypes = {};

export default ConfigurationStats;
