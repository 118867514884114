import React, { useState, useContext } from "react";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import { Button, Card, ButtonGroup, Typography } from "@mui/material";
import Constants from "../../services/Constants";
import { getColumnsData } from "./VehicleColumns";
import {
  getActiveVehiclesByQuery,
  getVehicleData,
} from "../../services/deployment";
import { useQuery } from "react-query";
import { WizardContext, ACTIONS, TABS } from "../wizard/Context";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import OTAPagination from "../OTAPagination";
import CreateVehicleGroup from "./group/CreateVehicleGroup";

const VehicleList = ({
  vehicleId,
  groupItemsDataArray,
  selectedGroupsItems,
  extraFilters,
  selectedVehiclesSerials,
  setSelectedVehiclesSerials,
  selectedVehiclesList,
  setSelectedVehiclesList,
}) => {
  const [openModal, setOpenModal] = useState(null);
  const [fetchData, setFetchData] = useState(true);
  const [originalRowsData, setOriginalRowsData] = useState([]);
  const handleOpen = (items) => {
    setOpenModal({
      createGroupFromSelectedItems: true,
      items: items?.length ? items : [],
    });
    setFetchData(true);
  }

  const { dispatch } = useContext(WizardContext);
  const [pageSize, setPageSize] = useState(Constants.PAGE_SIZE);
  const [mainList, setMainList] = useState([]);

  const onVehiclesSelected = (items) => {
    setSelectedVehiclesSerials(items);
    const list = items?.map((i) =>
      [...originalRowsData, ...selectedVehiclesList]?.find(
        (el) => el?.serialNo === i
      )
    );
    setSelectedVehiclesList(list);
  };

  const columns = getColumnsData();

  const CustomFooter = () => {
    const [queryActiveVehiclesData, setQueryActiveVehiclesData] = useState();
    const {
      data: { data: { res: paginationData = [] } = {} } = {},
      isLoading,

    } = useQuery(
      [
        "getVehiclesForGroupingByQuery",
        1,
        100,
        "serialNoCreatedAt",
        "DESC",
        extraFilters ? extraFilters : [],
      ],
      getActiveVehiclesByQuery,
      {
        refetchOnWindowFocus: false,
        enabled: fetchData,
        onSuccess: (response) => {
          const { data } = response;
          setQueryActiveVehiclesData(data?.res);
        },
        onSettled: () => {
          setFetchData(false);
        }
      }
    );
    return (
      <>
        <GridFooterContainer>
          <div style={{ marginLeft: "1em" }}>
            <label>
              <Typography variant="button">Create group from :</Typography>
            </label>
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              sx={{ ml: 1 }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  if (queryActiveVehiclesData) {
                    handleOpen(queryActiveVehiclesData);
                  } else {
                    handleOpen([]);
                  }
                }}
              >
                Search result
              </Button>
              <Button
                variant="outlined"
                size="small"
                disabled={selectedVehiclesSerials?.length < 1}
                onClick={() => handleOpen(selectedVehiclesList)}
              >
                {`Selected Vehicles (${selectedVehiclesSerials?.length})`}
              </Button>
            </ButtonGroup>
          </div>

          <GridFooter />
        </GridFooterContainer>
      </>
    );
  };

  return (
    <Card>
      <OTAPagination
        exportRowsData={(d) => {
          setOriginalRowsData(d);
        }}
        extraFilters={extraFilters}
        columns={columns}
        allVehicles
        endpointFn={getVehicleData}
        queryName={"vehicles"}
        filterMode="server"
        sortByDefault={"serialNoCreatedAt"}
        getRowId={(row) => row.serialNo}
        sx={{ height: "calc(100vh - 200px)" }}
        checkboxSelection={true}
        isRowSelectable={(params) =>
          params?.row?.active && params?.row?.certAvailable
        }
        onSelectionModelChange={onVehiclesSelected}
        selectionModel={selectedVehiclesSerials}
        keepNonExistentRowsSelected
        components={{ Footer: CustomFooter }}
        exportCSV
      />
      {openModal && (
        <CreateVehicleGroup
          open={openModal}
          toggleDialog={() => setOpenModal(!openModal)}
        />
      )}
    </Card>
  );
};

export default VehicleList;
