import axios from "axios";
import Constants from "../services/Constants";
import { constructQueryUrl } from "./Utils";

export const getConfigurationData = async ({ queryKey }) => {
    const [_, page, pageSize, field, sort, filterValue, columns] = queryKey;
    let query =
        Constants.LIST_CONFIGURATIONS +
        `?page=${page}&take=${pageSize}&sortby=${field}&order=${sort.toUpperCase()}`;

    query = constructQueryUrl({ filterValue, query, columns });
    return await axios.get(query);
};

export const createConfiguration = async (payload) => {
    return await axios.post(Constants.ADD_CONFIGURATION, payload);
};

export const getConfigurationDetails = async ({ queryKey }) => {
    const [_, configId] = queryKey;
    const url = Constants.DESCRIBE_CONFIGURATION.replace(":id", configId);
    return await axios.get(url);
};

export const getJobDocument = async ({ queryKey }) => {
    const [_, configId] = queryKey;
    const url = Constants.JOB_DOCUMENT_FOR_CONFIGURATION.replace(
        ":id",
        configId
    );
    return await axios.get(url);
};

export const getJobDetails = async ({ queryKey }) => {
    const [_, configId] = queryKey;
    const url = Constants.JOB_DETAILS_FOR_CONFIGURATION.replace(
        ":id",
        configId
    );
    return await axios.get(url);
};

export const getJobStatus = async ({ queryKey }) => {
    const [_, configId] = queryKey;
    const url = Constants.JOB_STATUS_FOR_CONFIGURATION.replace(
        ":id",
        configId
    );
    return await axios.get(url);
};

export const publishConfiguration = async (configId) => {
    return await axios.post(Constants.PUBLISH_CONFIGURATION, {
        configId,
    });
};

export const updateFeatureStatus = async (payload) => {
    return await axios.patch(Constants.UPDATE_FEATURE_STATUS, payload);
};

export const validateConfigName = async ({ queryKey }) => {
    const [_, configName] = queryKey;
    const url = Constants.VALIDATE_CONFIG.replace(":name", configName);
    return await axios.get(url);
};

export const getJobExecutionsforConfig = async ({ queryKey }) => {
    const [_, configId] = queryKey;
    let url = Constants.JOB_EXECUTIONS_FOR_CONFIGURATION.replace(
        ":id",
        configId
    );
    return await axios.get(url);
};