import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function DetailsButton({ id }) {
  return (
    <Button size="small" component={Link} to={`/Releases/${id}`}>
      Details
    </Button>
  );
}

DetailsButton.defaultProps = {
  id: "",
};

export default DetailsButton;
