import React, { useState } from "react";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import VehicleFeaturesAudit from "./VehicleFeaturesAudit";
import VehicleFeatures from "./shadow/VehicleFeatures";

const VehicleConfigTab = (props) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Features" />
                    <Tab label="Feature Audit" />
                </Tabs>
            </Box>
            {value === 1 ? (
                <VehicleFeaturesAudit {...props} />
            ) : (
                <VehicleFeatures {...props} />
            )}
        </>
    );
}

export default VehicleConfigTab;
