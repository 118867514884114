import React, { useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "react-query";
import { WizardContext, ACTIONS } from "../Context";
import SchemaEditor from "../../vehicle/shadow/SchemaEditor";
import { useToast } from "../../toast";
import useModule from "../../../hooks/useModule";
import { getAllFeatures } from "../../../services/vehicle";

const ConfigStateWizard = () => {
    const { validateModuleWithMessage } = useModule();
    const { dispatch, state } = useContext(WizardContext);
    const { configState = {} } = state;
    const { addToast } = useToast();
    const features = [];

    // Queries
    const {
        data: { data: featuresData = [] } = {},
        isLoading: getFeaturesLoading,
    } = useQuery(["getAllFeatures"], getAllFeatures, {
        refetchOnWindowFocus: false,
        retry: 1,
    });

    const onChange = (updatedObj) => {
        if (Object.keys(updatedObj).length > 0) {
            dispatch({
                type: ACTIONS.UPDATE_STEP,
                payload: {
                    disableNextBtn: false,
                    configState: updatedObj
                },
            });
        } else {
            dispatch({
                type: ACTIONS.UPDATE_STEP,
                payload: {
                    disableNextBtn: true,
                    configState: updatedObj
                },
            });
        }
    }

    featuresData.forEach((i) => features.push(i.featureId));
    const entries = Object.entries(configState);
    const rows = entries.map((entry) => {
        const [key, val] = entry;
        return {
            keyItem: key,
            valueItem: `${val}`
        };
    });

    return (
        <div className="config-wizard-wrapper">
            {getFeaturesLoading ? (
                <CircularProgress />
            ) : (
                <SchemaEditor
                    rows={rows}
                    keyInputPlaceholder="key"
                    valueInputPlaceholder="value"
                    onChange={onChange}
                    features={features}
                    disableSubmit
                    validateModuleWithMessage={validateModuleWithMessage}
                    addToast={addToast}
                />
            )}
        </div>
    );
};

ConfigStateWizard.defaultProps = {};

export default ConfigStateWizard;
