import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { TextField, Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { WizardContext, ACTIONS } from "../Context";
import { validateConfigName } from "../../../services/deployment";
import useDebounce from "../../../hooks/useDebounce";
import { getDefaultConfigName } from "../../vehicle/Utils";

const CreateConfigWizard = () => {
    const [validate, setValidate] = useState({
        helperText: "",
        hasError: false,
    });
    const { state, dispatch } = useContext(WizardContext);
    const {
        configName = getDefaultConfigName(),
        configDescription,
    } = state;
    const debounceValidate = useDebounce(configName, 500);

    const validateAndUpdate = (action, value) => {
        dispatch({
            payload: {
                disableNextBtn: false,
            },
        });
        switch (action) {
            case ACTIONS.UPDATE_CONFIG_NAME:
                dispatch({
                    type: action,
                    payload: {
                        configName: value.name,
                        disableNextBtn:
                            !!value.invalid
                    },
                });
                break;

            default:
                break;
        }
    };

    // Queries
    const { isFetching, isLoading, refetch } = useQuery(
        ["validateConfigName", configName],
        validateConfigName,
        {
            enabled: false,
            refetchOnWindowFocus: false,
            retry: 0,
            onError: (error) => {
                const {
                    response: { data: { message = "", statusCode = "" } = {} } = {},
                } = error;
                if (statusCode === 409) {
                    setValidate({
                        helperText: message,
                        hasError: true,
                    });
                    dispatch({
                        type: ACTIONS.UPDATE_CONFIG_NAME,
                        payload: {
                            disableNextBtn: true,
                        },
                    });
                }
            },
        }
    );

    useEffect(() => {
        if (debounceValidate && !validate.hasError) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceValidate]);

    useEffect(() => {
        if (
            configName !== ""
        ) {
            dispatch({
                type: ACTIONS.UPDATE_CAMPAIGN_START_DATE,
                payload: {
                    disableNextBtn: false,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const nameValidation = (value) => {
        let invalid = false;
        if (!!value) {
            const re = /^[a-zA-Z0-9_-]+$/;
            if (!re.test(value)) {
                setValidate({
                    hasError: true,
                    helperText:
                        "White space and special characters not allowed, except underscores (_) and hypens (-).",
                });
                invalid = true;
            } else if (value.length > 32) {
                setValidate({
                    hasError: true,
                    helperText:
                        "Maximum length of the name cannot be over 32 characters!",
                });
                invalid = true;
            } else {
                setValidate({
                    hasError: false,
                    helperText: "",
                });
                invalid = false;
            }
        } else {
            setValidate({
                hasError: false,
                helperText: "",
            });
            invalid = true;
        }
        validateAndUpdate(ACTIONS.UPDATE_CONFIG_NAME, { name: value, invalid });
    };

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            className="wizard-grid"
        >
            <Grid item xs={8}>
                <p className="wizard-heading">Create a configuration</p>
            </Grid>
            <Grid item xs={8}>

                <TextField
                    sx={{ mt: 2 }}
                    id="Configuration_name"
                    label="Configuration Name"
                    variant="standard"
                    fullWidth
                    required
                    value={configName}
                    onInput={(e) => nameValidation(e.target.value)}
                    error={validate.hasError}
                    helperText={validate.helperText}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {(isFetching || isLoading) && <CircularProgress size={20} />}
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    sx={{ mt: 2 }}
                    id="Configuration_description"
                    label="Configuration Description"
                    variant="standard"
                    fullWidth
                    value={configDescription}
                    onInput={(e) => dispatch({
                        type: ACTIONS.UPDATE_CONFIG_DESCRIPTION,
                        payload: {
                            configDescription: e.target.value,
                        }
                    })}
                />

            </Grid>
        </Grid>
    );
};

export default CreateConfigWizard;
