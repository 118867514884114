import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Tooltip, Button } from "@mui/material";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";

const getColumnsData = () => {
  return [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 2,
      sortable: false,
      renderCell: (data) => {
        return (
          <Tooltip title={data?.row?.serialNo}>
            <Button
              size="medium"
              sx={{
                justifyContent: "flex-start",
                textTransform: "none",
              }}
              component={RouterLink}
              to={`/vehicles/${data?.row?.serialNo}`}
            >
              {data?.row?.serialNo}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      flex: 3,
      editable: false,
      sortable: false,
    },
    {
      field: "serialNoCreatedAt",
      headerName: "Created At",
      flex: 1,
      filterable: false,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.serialNoCreatedAt} />
      ),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      filterable: false,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.updatedAt} />
      ),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      filterable: false,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      flex: 1,
      filterable: false,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
    },
  ];
};

export { getColumnsData };
