import React, { useState } from "react";
import { getColumnsData } from "./GroupDeploymentColumns";
import { DataGrid } from "@mui/x-data-grid";
import Constants, { MODULES, MODULE_TEXT } from "../../services/Constants";
import { Box, Alert } from "@mui/material";
import { detailsTabsBoxStyle } from "../../services/Utils";
import useModule from "../../hooks/useModule";

const DeploymentsForGroupTab = (props) => {
  const { validate } = useModule();
  const [pageSize, setPageSize] = useState(Constants.PAGE_SIZE);
  const columns = getColumnsData({
    vehicleGroupId: props?.groupData?.vehicleGroupId,
  });

  if (!validate(MODULES.OTA)) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="warning">
          {MODULE_TEXT.OTA} module not enabled for{" "}
          {localStorage.selectedCustomer} customer
        </Alert>
      </Box>
    );
  }

  return (
    <Box {...detailsTabsBoxStyle}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "campaignCreationDate", sort: "desc" }],
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPT}
        pagination
        rows={props?.groupData?.deployments || []}
        columns={columns}
        sx={{ minHeight: 600 }}
        checkboxSelection={false}
        getRowId={(row) => row.deploymentId}
      />
    </Box>
  );
};

export default DeploymentsForGroupTab;
