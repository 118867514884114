import React from "react";
import { useParams } from "react-router-dom";
import TabPanel from "../Tabpanel";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useQuery } from "react-query";
import { getDeploymentAudits } from "../../services/vehicle";
import DeploymentJobDocumentTab from "../deployment/DeploymentJobDocumentTab";
import { formatDate_12h } from "../../services/Utils";
import GroupDeploymentList from "../deployment/GroupDeploymentList";

const GroupDeploymentDetails = (props) => {
  let { vehicleId, deploymentId } = useParams();

  const deploymentDetail = props?.groupData?.deployments?.find((item) => {
    return item?.deploymentId === deploymentId;
  });

  // jobExecutions Queries
  const { data: { data: jobAuditData = [] } = {} } = useQuery(
    ["deploymentAudits", deploymentId, vehicleId],
    getDeploymentAudits,
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <TabPanel>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mt: "16px" }}>
          <Typography
            style={{ marginBottom: "8px", paddingBottom: "0" }}
            className="details-heading"
          >
            Deployment Detail
          </Typography>
          <GroupDeploymentList vehicles={deploymentDetail?.vehicles} />
        </Grid>

        {jobAuditData.length > 0 && (
          <Grid item xs={12} sx={{ mt: "16px" }}>
            <Typography
              style={{ marginBottom: "8px", paddingBottom: "0" }}
              className="details-heading"
            >
              Job Audit
            </Typography>
            <TableContainer sx={{ maxHeight: 800, overflow: "auto" }}>
              <Table
                stickyHeader
                sx={{ minWidth: 650, border: "1px solid #e2e2e2" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Event Time</TableCell>
                    <TableCell>Android Time</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobAuditData.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {formatDate_12h(row?.createdAt)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatDate_12h(row?.eventTime)}
                      </TableCell>
                      <TableCell>{row.signal}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {deploymentDetail?.job?.status !== "IN_PROGRESS" ? (
          <>
            <Grid item xs={12} sx={{ mt: "16px" }}>
              <Typography
                style={{ marginBottom: "8px", paddingBottom: "0" }}
                className="details-heading"
              >
                Job Document
              </Typography>
              <DeploymentJobDocumentTab />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography className="details-heading">Job Document</Typography>
              <DeploymentJobDocumentTab />
            </Grid>
          </>
        )}
      </Grid>
    </TabPanel>
  );
};

export default GroupDeploymentDetails;
