import * as React from "react";
import { Box, Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  formatDate,
  stringToHslColor,
  getInitialsForAvatar,
  formatDate_12h,
} from "../../services/Utils";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";

export default function UserAvatarCard({ data }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card sx={{ display: "flex" }} style={{ width: "fit-content" }}>
        <Avatar
          children={getInitialsForAvatar(data?.userName)}
          sx={{
            bgcolor: stringToHslColor(
              !!data?.userName ? data.userName : "",
              40,
              40
            ),
            width: 168,
            height: 168,
            fontSize: "2em",
          }}
          variant="rounded"
        ></Avatar>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h5">
              {data?.userName}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              {data?.email}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              {data?.phone}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              component="div"
            >
              Added on {` ` + formatDate_12h(data?.createdDate)}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              component="div"
            >
              Selected Customer : {localStorage.selectedCustomer}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </div>
  );
}
