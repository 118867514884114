import React, { useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import { useQuery } from "react-query";
import { Auth } from "aws-amplify";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { getUserGroups } from "../services/Users";
import Typography from '@mui/material/Typography';

const ValidateRoute = (props) => {
    const { children } = props;
    const [showLoader, setShowLoader] = useState(true)

    const { data: userGroups } = useQuery(
        "getUserGroups",
        getUserGroups,
        {
            refetchOnWindowFocus: false,
            onSettled: () => {
                setShowLoader(false);
            }
        }
    );

    const getContent = () => {
        if (userGroups?.length <= 0) {
            return <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <div>
                    <Typography variant="h6" gutterBottom style={{ marginBottom: "3%" }}>
                        You don't have any roles assigned, please contact your Administrator.
                    </Typography>
                    <Button variant="outlined" color="inherit" onClick={() => Auth.signOut()}>Logout</Button>
                </div>
            </Backdrop>
        }

        return children
    }

    return <>
        {
            showLoader ?
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop> :
                getContent()
        } </>
}


export default ValidateRoute;
