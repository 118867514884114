import React from "react";
import { Grid, Card, CardHeader, Chip } from "@mui/material";
import { DeploymentListTable } from "../components/deployment";
import "../styles/deployment.css";
import DeploymentStats from "../components/deployment/DeploymentStats";

const Deployments = () => {
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <DeploymentStats />
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Deployment List"></CardHeader>
            <DeploymentListTable />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Deployments;
