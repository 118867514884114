import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { DataGrid } from "@mui/x-data-grid";
import { getAllUsersData, getAllUserGroups } from "../../services/Users";
import Constants from "../../services/Constants";
import { getColumnsData } from "./UserColumns";

const UserList = ({ user }) => {
  const [pageSize, setPageSize] = React.useState(Constants.PAGE_SIZE);

  const { data: users = [], isLoading: userLoading } = useQuery("getAllUsersData", getAllUsersData, {
    refetchOnWindowFocus: true,
  });

  const { data: groups = [], isLoading: groupLoading } = useQuery("getAllUserGroups", getAllUserGroups, {
    refetchOnWindowFocus: true,
  });

  const columns = getColumnsData({
    tableData: {
      users,
      groups
    },
    user,
  });

  return (
    <DataGrid    
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPT}
      pagination
      loading={userLoading || groupLoading}
      rows={users}
      columns={columns}
      sx={{ height:"calc(100vh - 290px)" }}
      checkboxSelection={false}
      getRowId={(row) => row.userId}
    />
  );
};

UserList.defaultProps = {
  user: {},
};
UserList.propTypes = {};

export default UserList;
