import { Stack } from "@mui/material";
import { Toast } from "./Toast";

export const ToastList = ({ removeToast, toasts }) => {
  return (
    <Stack
      sx={{ width: "30%" }}
      spacing={2}
      style={{
        position: "fixed",
        left: "10px",
        bottom: "10px",
        zIndex: 3000,
      }}
    >
      {toasts &&
        toasts.length > 0 &&
        toasts.map((item) => {
          return <Toast key={item.id} onClose={removeToast} {...item} />;
        })}
    </Stack>
  );
};
