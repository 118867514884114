import React, { memo } from "react";
import { Button, DialogContentText, Tooltip } from "@mui/material";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmAbortUpload = memo(
  ({
    setShowCancelConfirmPopup,
    uploadInstance,
    handleClose,
    onClose,
    showCancelConfirmPopup,
    resumeUpload,
  }) => {
    const onCancel = () => {
      setShowCancelConfirmPopup(false);
    };
    const toggleDialog = () => {
      setShowCancelConfirmPopup(false);
    };
    const onConfirm = async () => {
      if (!resumeUpload) {
        //new upload abort
        await uploadInstance?.abortController?.abort()?.();
      } else {
        //reupload abort
        await uploadInstance?.abort()?.();
      }
      handleClose?.();
      onClose();
      setShowCancelConfirmPopup(false);
    };
    return (
      <Dialog
        open={showCancelConfirmPopup}
        onClose={onCancel}
        aria-labelledby="abort-confirm-dialog-title"
        aria-describedby="abort-confirm-dialog-description"
      >
        <DialogTitle id="abort-dialog-title">
          {`Abort upload!`}
          <IconButton
            aria-label="close"
            onClick={toggleDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Tooltip title="Close delete dialog">
              <CloseIcon />
            </Tooltip>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="abort-dialog-description">
            {`Do you want to abort the file upload?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default ConfirmAbortUpload;
