import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserGroups } from "../services/Users";
import { USER_GROUPS } from "./permissions";

const ProtectedRoute = ({ redirectPath = "/", children }) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getUserGroups().then((data) => setGroups(data));
  }, []);

  if (!groups.includes(USER_GROUPS.ADMIN) && groups.length > 0) {
    return <Navigate to={redirectPath} replace />;
  } else if (groups.includes(USER_GROUPS.ADMIN) && groups.length > 0) {
    return children;
  }

  return (
    <div className="route-loader">
      <CircularProgress size={30} />
    </div>
  );
};

export default ProtectedRoute;
