import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { TextField, Grid, FormHelperText } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { WizardContext, ACTIONS } from "../Context";
import { validateDeploymentName } from "../../../services/deployment";
import useDebounce from "../../../hooks/useDebounce";
import { isValid } from "date-fns";

const DeploymentWizard = () => {
  const [validate, setValidate] = useState({
    helperText: "",
    hasError: false,
  });

  const [validateEndDate, setValidateEndDate] = useState({
    helperTextEndDate: "",
    hasErrorEndDate: false,
  });

  const { state, dispatch } = useContext(WizardContext);
  const {
    deploymentName = "",
    campaignStartDate,
    campaignEndDate,
    deploymentType,
  } = state;
  const debounceValidate = useDebounce(deploymentName, 500);

  const getMinCampaignEndDate = (dt = new Date()) => {
    const sd = new Date(dt);
    return new Date(sd.setDate(sd.getDate() + 1));
  };

  // Checks if the given date is yesterday or any date earlier than yesterday.
  const isPastDate = (date) => {
    return (
      new Date(date) < new Date(new Date().setDate(new Date().getDate() - 1))
    );
  };

  const isValidStartDate = (dt, end) => {
    if (isPastDate(dt)) return false;
    const ed = new Date(end);
    let result = dt < new Date(ed.setDate(ed.getDate() - 1));
    if (!result) {
      setValidateEndDate({
        hasErrorEndDate: true,
        helperTextEndDate:
          "The end date must be at least 1 days after the start date",
      });
    } else {
      setValidateEndDate({
        hasErrorEndDate: false,
        helperTextEndDate: "",
      });
    }
    return result;
  };

  const isValidEndDate = (dt, start) => {
    const sd = new Date(start);
    let result = dt > new Date(sd.setDate(sd.getDate() + 1));
    return result;
  };

  const isValidCampaignDate = (dt) => {
    if (isValid(dt)) {
      return (
        new Date().setHours(0, 0, 0, 0) <= new Date(dt).setHours(0, 0, 0, 0)
      );
    }
    return false;
  };

  const validateAndUpdate = (action, value) => {
    let isNextBtndisabled = false;
    dispatch({
      payload: {
        disableNextBtn: isNextBtndisabled,
      },
    });
    switch (action) {
      case ACTIONS.UPDATE_DEPLOYMENT_NAME:
        if (campaignEndDate) {
          isNextBtndisabled =
            !!value.invalid ||
            campaignStartDate === null ||
            new Date(campaignStartDate) >= new Date(campaignEndDate) ||
            !isValidEndDate(campaignEndDate, campaignStartDate);
        } else {
          isNextBtndisabled = !!value.invalid || campaignStartDate === null;
        }
        dispatch({
          type: action,
          payload: {
            deploymentName: value.name,
            disableNextBtn: isNextBtndisabled,
          },
        });
        break;

      case ACTIONS.UPDATE_DEPLOYMENT_TYPE:
        if (campaignEndDate) {
          isNextBtndisabled =
            !!value.invalid ||
            campaignStartDate === null ||
            new Date(campaignStartDate) >= new Date(campaignEndDate);
        } else {
          isNextBtndisabled = !!value.invalid || campaignStartDate === null;
        }
        dispatch({
          type: action,
          payload: {
            deploymentType: value,
            disableNextBtn: isNextBtndisabled,
          },
        });
        break;

      case ACTIONS.UPDATE_CAMPAIGN_START_DATE:
        if (campaignEndDate) {
          isNextBtndisabled =
            value === null ||
            !isValidCampaignDate(value) ||
            !isValidStartDate(value, campaignEndDate) ||
            deploymentName === "";
        } else {
          isNextBtndisabled =
            value === null ||
            deploymentName === "" ||
            !isValidCampaignDate(value);
        }
        dispatch({
          type: action,
          payload: {
            campaignStartDate: value,
            disableNextBtn: isNextBtndisabled,
          },
        });
        break;

      case ACTIONS.UPDATE_CAMPAIGN_END_DATE:
        if (value) {
          isNextBtndisabled =
            campaignStartDate === null ||
            !isValidCampaignDate(value) ||
            //!isValidStartDate(value, campaignEndDate) ||
            !isValidStartDate(campaignStartDate, value) ||
            !isValidEndDate(value, campaignStartDate) ||
            deploymentName === "";
        } else {
          isNextBtndisabled =
            deploymentName === "" || campaignStartDate === null;
        }

        dispatch({
          type: action,
          payload: {
            campaignEndDate: value,
            disableNextBtn: isNextBtndisabled,
          },
        });
        break;

      default:
        break;
    }
  };

  // Queries
  const { isFetching, isLoading, refetch } = useQuery(
    ["validateDeploymentName", deploymentName],
    validateDeploymentName,
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (error) => {
        const {
          response: { data: { message = "", statusCode = "" } = {} } = {},
        } = error;
        if (statusCode === 409) {
          setValidate({
            helperText: message,
            hasError: true,
          });
          dispatch({
            type: ACTIONS.UPDATE_DEPLOYMENT_NAME,
            payload: {
              disableNextBtn: true,
            },
          });
        }
      },
    }
  );

  useEffect(() => {
    if (debounceValidate && !validate.hasError) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValidate]);

  useEffect(() => {
    if (deploymentName !== "" && campaignStartDate !== null) {
      dispatch({
        type: ACTIONS.UPDATE_CAMPAIGN_START_DATE,
        payload: {
          disableNextBtn: false,
        },
      });
    }
    if (campaignEndDate !== null) {
      isValidStartDate(campaignStartDate, campaignEndDate);
      validateAndUpdate(ACTIONS.UPDATE_CAMPAIGN_END_DATE, campaignEndDate);
    }

    if (deploymentName.length === 0) {
      setValidate({
        hasError: true,
        helperText: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nameValidation = (value) => {
    let invalid = false;
    if (!!value) {
      const re = /^[a-zA-Z0-9_-]+$/;
      if (!re.test(value)) {
        setValidate({
          hasError: true,
          helperText:
            "White space and special characters not allowed, except underscores (_) and hypens (-).",
        });
        invalid = true;
      } else if (value.length > 32) {
        setValidate({
          hasError: true,
          helperText:
            "Maximum length of the name cannot be over 32 characters!",
        });
        invalid = true;
      } else {
        setValidate({
          hasError: false,
          helperText: "",
        });
        invalid = false;
      }
    } else {
      setValidate({
        hasError: true,
        helperText: "",
      });
      invalid = true;
    }
    validateAndUpdate(ACTIONS.UPDATE_DEPLOYMENT_NAME, { name: value, invalid });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
      className="wizard-grid"
    >
      <Grid item xs={8}>
        <p className="wizard-heading">Create Deployment</p>
      </Grid>
      <Grid item xs={8}>
        <TextField
          sx={{ mt: 2 }}
          id="Deployment name"
          label="Deployment Name"
          variant="standard"
          fullWidth
          required
          value={deploymentName}
          onInput={(e) => nameValidation(e.target.value)}
          error={validate.hasError}
          helperText={validate.helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {(isFetching || isLoading) && <CircularProgress size={20} />}
              </InputAdornment>
            ),
          }}
        />
        {/* uncommit the below code to enable the select job type component */}
        {/* <FormControl style={{ marginTop: "24px" }}>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            style={{ fontSize: "0.8em" }}
          >
            Deployment Type
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            defaultValue={DEPLOYMENT_TYPES.SNAPSHOT}
            name="controlled-radio-buttons-group"
            onChange={(event) => {
              console.log(event?.target?.value);
              validateAndUpdate(
                ACTIONS.UPDATE_DEPLOYMENT_TYPE,
                event?.target?.value
              );
            }}
          >
            <FormControlLabel
              value={DEPLOYMENT_TYPES.SNAPSHOT}
              control={<Radio />}
              label="Snapshot"
            />
            <FormHelperText style={{ marginTop: "-1em", marginLeft: "32px" }}>
              (vehicles in the execution list cannot be added/removed after the
              deployment is created)
            </FormHelperText>
            <FormControlLabel
              style={{ marginTop: "1em" }}
              value={DEPLOYMENT_TYPES.CONTINUOUS}
              control={<Radio />}
              label="Continuous"
            />
            <FormHelperText style={{ marginTop: "-1em", marginLeft: "32px" }}>
              (vehicles in the execution list can be added/removed after the
              deployment is created while using vehicle groups)
            </FormHelperText>
          </RadioGroup>
        </FormControl> */}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="campaign-date-picker">
            <DateTimePicker
              label="Campaign Start Date"
              value={campaignStartDate}
              onChange={(campaignStartDate) =>
                validateAndUpdate(
                  ACTIONS.UPDATE_CAMPAIGN_START_DATE,
                  campaignStartDate
                )
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  required
                  type="date"
                  fullWidth
                  {...params}
                />
              )}
              disablePast
            />
            <DateTimePicker
              label="Campaign End Date"
              value={campaignEndDate}
              onChange={(campaignEndDate) =>
                validateAndUpdate(
                  ACTIONS.UPDATE_CAMPAIGN_END_DATE,
                  campaignEndDate
                )
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  fullWidth
                  {...params}
                  error={validateEndDate.hasErrorEndDate}
                  helperText={validateEndDate.helperTextEndDate}
                />
              )}
              disablePast
              minDate={getMinCampaignEndDate(campaignStartDate)}
            />
          </div>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default DeploymentWizard;
