import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Skeleton } from "@mui/material";
import styled from "styled-components";

const SkeletonWrapper = styled.div`
  margin-top:5%;
`;

const InfoTile = (props) => {
    const { title = '', info = '', isLoading = false } = props;
    const theme = useTheme();

    return (
        <Card>
            <CardContent className="tile-cards">
                {isLoading ?
                    <>
                        <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: 14 }}
                            width={200}
                        />
                        <SkeletonWrapper>
                            <Skeleton
                                animation="wave"
                                variant="rounded"
                                width={100}
                                height={44}
                            />
                        </SkeletonWrapper>
                    </>
                    :
                    <>
                        <Typography color="text.secondary">
                            {title}
                        </Typography>
                        <Typography
                            className="tile-number"
                            color={theme.palette.primary['A700']}
                            sx={{ fontSize: 30, fontWeight: 400 }}
                        >
                            {info}
                        </Typography>
                    </>
                }
            </CardContent>
        </Card>
    );
}

export default InfoTile;