import React from "react";
import { useQuery } from "react-query";
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CardContent,
  Card,
  CardHeader,
  Typography,
} from "@mui/material";
import { getGroupsForUserId } from "../../services/Users";
import { GROUPS } from "../../services/Constants";
import { getInitialsForAvatar } from "../../services/Utils";

export default function UserGroupList({ data, isMyProfile = false, theme }) {
  const { data: userGroups = [] } = useQuery(
    ["userGroups", data?.cognitoId],
    isMyProfile ? getGroupsForUserId : getGroupsForUserId,
    {
      refetchOnWindowFocus: false,
    }
  );

  const filterGroups = userGroups.filter((g) => g !== "ota-dev-group");

  return (
    <Card>
      <CardHeader
        title={<Typography color="text.secondary">Groups</Typography>}
      ></CardHeader>
      <CardContent>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {filterGroups.map((i) => (
            <>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-end">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: theme.palette.primary[400] }} children={getInitialsForAvatar(data?.userName)} />
                </ListItemAvatar>
                <ListItemText primary={GROUPS[i]} />
              </ListItem>
            </>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
