import React from "react";
import { object, shape } from "prop-types";
import { ResponsivePie } from "@nivo/pie";
import { useQuery } from "react-query";
import { Grid, Typography, CircularProgress, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getJobDetails } from "../../services/configuration";
import { JOB_STATUS } from "../../services/Constants";
import { CustomTooltip } from "../deployment/DeploymentAnalytics";
import VehicleConfigurationList from "./VehicleConfigurationList";

const ConfigurationVehicleTab = (props) => {
    const summary = [];
    let totalVehicles = 0;
    const theme = useTheme();
    const { configurationDetails = {} } = props;
    const { vehicles = [], configId = "" } = configurationDetails || {};

    // Queries
    const {
        data: {
            data: {
                job: {
                    jobProcessDetails = {}
                } = {}
            } = {},
        } = {},
    } = useQuery(["configJobDetails", configId], getJobDetails, {
        enabled: !!configId,
        refetchInterval: 5000,
    });

    const jobTypes = {
        "numberOfCanceledThings": "CANCELED",
        "numberOfSucceededThings": "SUCCEEDED",
        "numberOfFailedThings": "FAILED",
        "numberOfRejectedThings": "REJECTED",
        "numberOfQueuedThings": "QUEUED",
        "numberOfInProgressThings": "INPROGRESS",
        "numberOfRemovedThings": "REMOVED",
        "numberOfTimedOutThings": "TIMEDOUT"
    };

    for (const property in jobProcessDetails) {
        if (jobProcessDetails[property] !== 0) {
            summary.push({
                id: jobTypes[property],
                label: jobTypes[property],
                value: jobProcessDetails[property]
            });
            totalVehicles += jobProcessDetails[property];
        }
    }

    const commonProperties = {
        margin: { top: 40, right: 40, bottom: 40, left: 40 },
        sortByValue: true,
        innerRadius: 0.5,
        padAngle: 1,
        cornerRadius: 1,
        activeOuterRadiusOffset: 8,
        colors: theme.custom.pieChartColors,
        animate: true,
        borderWidth: 1,
        borderColor: {
            from: "color",
            modifiers: [["darker", 0.2]],
        },
        arcLinkLabelsSkipAngle: 10,
        arcLinkLabelsTextColor: "#333333",
        arcLinkLabelsThickness: 2,
        arcLinkLabelsColor: { from: "color" },
        arcLabelsSkipAngle: 10,
        arcLabelsTextColor: {
            from: "color",
            modifiers: [["darker", 2]],
        },
    };

    const getProgressValue = (value, base) => {
        return Math.round((value / base) * 100);
    };

    return (
        <Grid container spacing={2}>

            {summary.length > 0 && (
                <Grid item md={5}>
                    <div style={{ height: "350px" }}>
                        <ResponsivePie
                            data={summary}
                            {...commonProperties}
                            tooltip={CustomTooltip}
                        />
                    </div>
                </Grid>
            )}

            {summary?.length > 0 && (
                <Grid item md={6} container spacing={2}>
                    {summary.map((i) => (
                        <Grid item md={4}>
                            <Box sx={{ position: "relative", display: "inline-flex" }}>
                                <CircularProgress
                                    sx={{
                                        color: (theme) => theme.palette.grey[200],
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    variant="determinate"
                                    value={100}
                                    size={120}
                                />
                                <CircularProgress
                                    variant="determinate"
                                    value={getProgressValue(i.value, totalVehicles)}
                                    size={120}
                                />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        color="text.secondary"
                                    >
                                        <Typography align="center" variant="h5">
                                            {i.value}
                                        </Typography>
                                        <Typography align="center" variant="caption">
                                            {JOB_STATUS[i.id]}
                                        </Typography>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}

            <VehicleConfigurationList vehicles={vehicles} />
        </Grid>
    );
};

ConfigurationVehicleTab.defaultProps = {
    configurationDetails: shape({
        vehicles: [],
    }),
};

ConfigurationVehicleTab.propTypes = {
    configurationDetails: object,
};

export default ConfigurationVehicleTab;
