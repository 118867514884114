import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { useQuery } from "react-query";
import { getVehicleGroups } from "../../services/vehicle";

const MultipleGroupSelect = ({
  selectedGroups,
  handleGroupMultiSelectChange,
}) => {
  // Queries
  const {
    data: vehicleGroups = [],
    isLoading,
    refetch,
  } = useQuery("vehicleGroup", getVehicleGroups, {
    refetchOnWindowFocus: false,
  });

  return (
    <FormControl
      variant="standard"
      style={{ marginTop: "12px", marginBottom: "12px", width: "100%" }}
    >
      <InputLabel id="vehicle-groups-multiselect-label">
        Vehicle Groups
      </InputLabel>
      <Select
        multiple
        style={{ width: "100%" }}
        labelId="vehicle-groups-multiselect-label"
        id="vehicle-groups-multiselect"
        value={selectedGroups}
        onChange={handleGroupMultiSelectChange}
        renderValue={(selected) =>
          selected.map((i) => i.vehicleGroupName).join(", ")
        }
        label="Vehicle Groups"
      >
        {vehicleGroups?.length ? (
          vehicleGroups.map((vehicleGroup) => {
            return (
              <MenuItem value={vehicleGroup} key={vehicleGroup.vehicleGroupId}>
                <Checkbox
                  checked={
                    selectedGroups.filter(
                      (i) => i.vehicleGroupId === vehicleGroup.vehicleGroupId
                    ).length
                      ? true
                      : false
                  }
                />
                <ListItemText primary={vehicleGroup.vehicleGroupName} />
              </MenuItem>
            );
          })
        ) : (
          <MenuItem disabled>
            <ListItemText primary="No Groups Available" />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default MultipleGroupSelect;
