import React, { useState } from "react";
import { string, object, bool } from "prop-types";
import axios from "axios";
import Constants from "../../services/Constants";
import { Button, Menu, MenuItem, Checkbox, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { updateUserOrgs, getAllAvailableOrgs } from "../../services/Users";
import { useMutation, useQuery } from "react-query";
import { useToast } from "../toast";

const UserOrgManager = (props) => {
  const { addToast } = useToast();
  const { tableData, userId, enabled, userId: currentUserID = "" } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const addOrg = new Set();
  const removeOrg = new Set();

  const { data: organizations = [] } = useQuery(
    ["getallorgs"],
    getAllAvailableOrgs,
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleClose = () => {
    setAnchorEl(null);
    addOrg.clear();
    removeOrg.clear();
    setOrgs([]);
  };

  const { mutate: updateOrgsForUser } = useMutation(updateUserOrgs, {
    onSuccess: (res) => {
      addToast({
        type: "success",
        message: `User organization updated successfully.`,
        autoClose: 3000,
      });
      handleClose();
    },
    onError: (e) => {
      addToast({
        type: "error",
        message: "Failed to update organization for the user!",
        autoClose: 3000,
      });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleClick = async (event) => {
    setLoading(true);
    const target = event.currentTarget;
    let url = Constants.GET_USER_ORGS.replace(":userId", userId);
    const { data } = await axios.get(url);
    setOrgs(data);
    setAnchorEl(target);
    setLoading(false);
  };

  const updateUserOrganizations = (event, selectedOrg) => {
    const checked = event.target.checked;
    const orgIncludes = organizations?.includes(selectedOrg);
    if (checked) {
      const orgsList = new Set([...orgs, selectedOrg?.customerId]);
      setOrgs(Array.from(orgsList));
    } else {
      const orgsList = new Set([...orgs]);
      orgsList.delete(selectedOrg?.customerId);
      setOrgs(Array.from(orgsList));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    updateOrgsForUser({ userId, payload: { add: [...orgs] } });
    setLoading(false);
  };

  const getMenuItems = () => {
    let menuItems = [];
    if (organizations?.length) {
      menuItems = organizations?.map((eachOrg) => (
        <MenuItem key={eachOrg} disableRipple>
          <Checkbox
            defaultChecked={orgs.includes(eachOrg?.customerId) ? true : false}
            onChange={(event) => updateUserOrganizations(event, eachOrg)}
            disableRipple
          />
          <span className="role-title">{eachOrg?.customerName}</span>
        </MenuItem>
      ));
      menuItems.push(
        <MenuItem key="update-btn" disableRipple>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleSubmit()}
            endIcon={loading && <HourglassTopIcon />}
            disabled={loading}
          >
            Update
          </Button>
        </MenuItem>
      );
    } else {
      menuItems.push(
        <MenuItem key="loading" disableRipple>
          <Typography>{loading && `Loading...`}</Typography>
          <Typography>
            {loading === false && orgs.length === 0 && `No data`}
          </Typography>
        </MenuItem>
      );
    }
    return menuItems;
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        id="basic-button"
        variant="text"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={loading ? <HourglassTopIcon /> : <KeyboardArrowDownIcon />}
        disabled={loading || !enabled}
      >
        Organization
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="role-menu-list"
      >
        {getMenuItems()}
      </Menu>
    </>
  );
};

UserOrgManager.defaultProps = {
  userId: "",
  enabled: false,
  tableData: {},
};
UserOrgManager.propTypes = {
  userId: string,
  enabled: bool,
  tableData: object,
};

export default UserOrgManager;
