import { grey, indigo, orange, teal } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// pie chart colors generated from https://www.learnui.design/tools/data-color-picker.html

const purple = {
  50: '#f4e6f2',
  100: '#e3c0e0',
  200: '#d196cc',
  300: '#be6db7',
  400: '#af4fa8',
  500: '#a2319b',
  600: '#962d96',
  700: '#84268e',
  800: '#752186',
  900: '#5a1778',
  A100: '#e3c0e0',
  A200: '#a2319b',
  A700: '#962d96',
}

export const earlsGreen = {
  50: '#fbfde9',
  100: '#f4f9c8',
  200: '#edf6a4',
  300: '#e6f280',
  400: '#dfee65',
  500: '#d9ea4a',
  600: '#ccd844',
  700: '#bbc23b',
  800: '#aaaa34',
  900: '#8e8426',
  A100: '#edf6a4',
  A200: '#ccd844',
  A700: '#8e8426',
}

export const gimblet = {
  50: '#f9fcee',
  100: '#f1f8d3',
  200: '#e9f4b8',
  300: '#e2ef9f',
  400: '#dceb8d',
  500: '#d6e67e',
  600: '#c9d476',
  700: '#b7be6d',
  800: '#a5a766',
  900: '#86815c',
  A100: '#fef6cc',
  A200: '#d6e67e',
  A700: '#b7be6d',
}

const orangeTheme = createTheme({
  palette: {
    primary: { ...orange, main: orange[800] },
    secondary: grey,
    tertiary: orange[50],
  },
  custom: {
    pieChartColors: [
      orange[800],
      orange[600],
      orange[400],
      orange[300],
      orange[200],
    ],
    // dashboardCardGradient: `linear-gradient(135deg, transparent 0%,${orange[200]} 100%)`,
    logoClass: "orange",
    selectedItemColor: orange[800],
    barChartColor: orange[200],
    dualTheme: false,
  },
});

const indigoOffset = 100;
const indigoTheme = createTheme({
  palette: {
    primary: indigo,
    secondary: grey,
    tertiary: indigo[50],
  },
  custom: {
    pieChartColors: [
      indigo[800 - indigoOffset],
      indigo[600 - indigoOffset],
      indigo[400 - indigoOffset],
      indigo[300 - indigoOffset],
      indigo[200 - indigoOffset],
    ],
    // dashboardCardGradient: `linear-gradient(135deg, transparent 0%,${indigo[200]} 100%)`,
    logoClass: "indigo",
    barChartColor: indigo[200],
    selectedItemColor: indigo[800],
    dualTheme: false,
  },
});

const tealTheme = createTheme({
  palette: {
    primary: teal,
    secondary: grey,
    tertiary: teal[50],
  },
  custom: {
    pieChartColors: [teal[800], teal[600], teal[400], teal[300], teal[200]],
    // dashboardCardGradient: `linear-gradient(135deg, transparent 0%,${teal[200]} 100%)`,
    logoClass: "teal",
    barChartColor: teal[300],
    selectedItemColor: teal[800],
    dualTheme: false,
  },
});

const peachTheme = createTheme({
  palette: {
    primary: purple,
    secondary: grey,
    tertiary: earlsGreen[50],
  },
  custom: {
    pieChartColors: [earlsGreen[800], earlsGreen[600], earlsGreen[400], earlsGreen[300], earlsGreen[200]],
    // dashboardCardGradient: `linear-gradient(135deg, transparent 0%,${earlsGreen[200]} 100%)`,
    barChartColor: earlsGreen[700],
    logoClass: "purple",
    selectedItemColor: earlsGreen[900],
    dualTheme: true,
    nextColor: earlsGreen
  },
});

const themes = {
  orangeTheme,
  indigoTheme,
  tealTheme,
  peachTheme,
};

const getThemeByName = (themeName) => themes[themeName];

export { themes, getThemeByName };
