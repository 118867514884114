import React from "react";
import { Box } from "@mui/material";
import Lottie from "react-lottie";
import successAnimation from "../../lottieAnimations/checkMarkSuccessData.json";

function FileUploadCompletePane({ handleClose }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: 600,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Box>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: successAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={300}
          width={300}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => {
                setTimeout(() => {
                  handleClose();
                }, 2000);
              },
            },
          ]}
        />
      </Box>
    </Box>
  );
}

export default FileUploadCompletePane;
