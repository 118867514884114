import React, { useState, useContext } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Skeleton,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getVehicleGroupData } from "../../../services/deployment";
import { useQuery } from "react-query";
import { WizardContext, ACTIONS } from "../Context";
import { DEPLOYMENT_TARGETS_MAX_LIMIT } from "../../../services/Constants";

const VehicleGroupList = () => {
  const { state, dispatch } = useContext(WizardContext);
  const { vehicleGroups = [] } = state;
  const [checked, setChecked] = useState(vehicleGroups || []);
  const [results, setResults] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [checkedIds, setCheckedIds] = useState(
    checked?.map((i) => i.vehicleGroupId)
  );

  const handleToggle = (value, id) => {
    const currentIndex = checked.indexOf(value);
    const idCurrentIndex = checkedIds.indexOf(id);
    const newChecked = [...checked];
    const newCheckedIds = [...checkedIds];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (idCurrentIndex === -1) {
      newCheckedIds.push(value);
    } else {
      newCheckedIds.splice(idCurrentIndex, 1);
    }

    setChecked(newChecked);
    setCheckedIds(newCheckedIds);
    dispatch({
      type: ACTIONS.UPDATE_VEHICLE_GROUP,
      payload: {
        vehicleGroups: newChecked,
        disableNextBtn: newChecked.length <= 0,
      },
    });
  };

  // Queries
  const { data: vehicleGroupData, isLoading } = useQuery(
    "vehicleGroups",
    getVehicleGroupData,
    {
      refetchOnWindowFocus: false,
    }
  );

  const onSearchInput = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      setResults(vehicleGroupData);
    } else {
      setResults(
        vehicleGroupData.filter((g) =>
          g?.vehicleGroupName?.includes(e.target.value)
        )
      );
    }
  };

  if (isLoading) {
    return (
      <Grid container spacing={0}>
        {[...Array(20)].map((_, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Skeleton animation="wave" height={50} width={"90%"} />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "18px",
          marginTop: "18px",
          position: "sticky",
          top: "0",
          backgroundColor: "#ffffff",
        }}
      >
        <Grid xs={8} item></Grid>
        <Grid
          item
          xs={4}
          sx={{
            marginBottom: "20px",
            textAlign: "right",
          }}
        >
          <TextField
            id="input-with-icon-textfield"
            label="Search Vehicle Groups"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            sx={{ width: "100%" }}
            onChange={(e) => onSearchInput(e)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        sx={{
          background: "#e8e8e894",
          height: "auto",
          padding: "15px",
          paddingLeft: "25px",
          borderRadius: "10px",
          display: "grid",
          gridTemplateColumns: "33.33% 33.33% 33.33%",
          gridAutoRows: "minmax(40px, 40px)",
          rowGap: "8px",
        }}
      >
        {(searchText?.length ? results : vehicleGroupData)?.length ? (
          (searchText?.length ? results : vehicleGroupData)
            .filter((g) => g?.devices?.length) // Remove/hide the groups that are empty - (HDA99MY24-6219)
            .map((ob) => (
              <Grid item xs={12} sm={6} md={4} key={ob.vehicleGroupId}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={
                        checkedIds?.length &&
                        checkedIds?.length === DEPLOYMENT_TARGETS_MAX_LIMIT &&
                        !checkedIds.includes(ob.vehicleGroupId)
                      }
                      name="vehicles"
                      defaultChecked={
                        checkedIds.includes(ob.vehicleGroupId) ? true : false
                      }
                      onClick={() => handleToggle(ob, ob.vehicleGroupId)}
                    />
                  }
                  label={
                    <div
                      title={ob.vehicleGroupName}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "162px",
                      }}
                    >
                      {ob.vehicleGroupName}
                    </div>
                  }
                  key={ob.vehicleGroupId}
                />
              </Grid>
            ))
        ) : (
          <Typography variant="button">No results</Typography>
        )}
      </Grid>
      <Typography variant="caption">{`A maximum of ${DEPLOYMENT_TARGETS_MAX_LIMIT} groups can be selected.`}</Typography>
    </>
  );
};

VehicleGroupList.defaultProps = {};

VehicleGroupList.propTypes = {};

export default VehicleGroupList;
