import React from "react";
import { Card, CardHeader, CardContent, Grid } from "@mui/material";
import {
  VehicleGroupsDataGrid,
  VehicleGroupsStats,
} from "../components/vehicle";

const VehicleGroups = () => {
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <VehicleGroupsStats />
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Available Vehicle Groups"></CardHeader>
            <CardContent sx={{
              padding: 0, ":last-child": {
                padding: 0
              }
            }}>
              <VehicleGroupsDataGrid />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default VehicleGroups;
