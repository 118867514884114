import React from "react";
import { Paper, List, ListItem, ListItemText, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { formatDate } from "../../services/Utils";
import { getDeploymentDetails } from "../../services/deployment";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "react-query";

const DeploymentJobStatusTab = () => {
  const params = useParams();
  const { deploymentId = "" } = params;

  /**
   * comment: null                
    description: "Visteon OTA Job"
    forceCanceled: null
    jobArn: "arn:aws:iot:ap-south-1:326947976637:job/2d72e067-7810-471c-9d17-d4a2eb3eac45"
    jobExecutionsRolloutConfig: {maximumPerMinute: null}
    jobId: "2d72e067-7810-471c-9d17-d4a2eb3eac45"
    jobProcessDetails: {numberOfCanceledThings: 0, numberOfSucceededThings: 1, numberOfFailedThings: 0, numberOfRejectedThings: 0, numberOfQueuedThings: 0, …}
    jobTemplateArn: null        
    namespaceId: null
    presignedUrlConfig: {roleArn: null, expiresInSec: null}
    reasonCode: null        
    targetSelection: "SNAPSHOT"
    targets: ['arn:aws:iot:ap-south-1:326947976637:thing/visteon_poc_car_4']
    timeoutConfig: {inProgressTimeoutInMinutes: null}
        */

  // Queries
  const { data: { data: jobDetails = {} } = {}, isLoading } = useQuery(
    ["jobDetails", deploymentId],
    getDeploymentDetails
  );

  const {
    deploymentId: jobId = "",
    deploymentStatus = "",
    campaignStartDate = "",
    campaignEndDate = "",
    campaignCreationDate = "",
    jobArn = "",
    jobDescription = "",
    createdBy = "",
  } = jobDetails || {};

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <List>
          <ListItem>
            <ListItemText primary="Job ID" secondary={jobId} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Job ARN" secondary={jobArn} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Status" secondary={deploymentStatus} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Job Description"
              secondary={jobDescription}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Campaign Start Date"
              secondary={formatDate(campaignStartDate)}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Campaign End Date"
              secondary={formatDate(campaignEndDate)}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Created At"
              secondary={formatDate(campaignCreationDate)}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Created By" secondary={createdBy} />
          </ListItem>
        </List>
      )}
    </>
  );
};

DeploymentJobStatusTab.defaultProps = {};

DeploymentJobStatusTab.propTypes = {};

export default DeploymentJobStatusTab;
