import axios from "axios";
import Constants from "../services/Constants";

export const downloadfile = async (payload) => {
  console.log(payload);
  const { filePath, fileName } = payload;
  let url = Constants.DOWNLOAD_PROVISIONING_CSV_FILE;
  const response = await axios.post(url, { filePath, fileName });
  return response;
};
