import React from "react";
import { Box, Alert } from "@mui/material";
import Lottie from "react-lottie";
import failedAnimation from "../../lottieAnimations/deployment_failed.json";

function FileUploadFailedPane({ handleClose, errorMsg, setErrorMsg }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: 600,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Box>
        {!!errorMsg ? (
          <Alert severity="error" style={{ width: "100%" }}>
            {errorMsg}
          </Alert>
        ) : null}
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: failedAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={300}
          width={300}
          // eventListeners={[
          //   {
          //     eventName: "complete",
          //     callback: () => {
          //       setTimeout(() => {
          //         handleClose();
          //       }, 2000);
          //     },
          //   },
          // ]}
        />
      </Box>
    </Box>
  );
}

export default FileUploadFailedPane;
