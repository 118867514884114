import React, { useState } from "react";
import { DialogContentText, TextField } from "@mui/material";
import { VEHICLE_GROUP_NAME_REGEX } from "../../../services/Constants";

const GroupNameInput = (props) => {
  const {
    isUpdate,
    vehicleGroupName,
    setVehicleGroupName,
    vehicleGroups = [],
    selectedVehicleGroup,
    setSelectedVehicleGroup,
  } = props;

  const [error, setError] = useState("");

  const onGroupNameChange = ({ target }) => {
    if (!target?.value.match(VEHICLE_GROUP_NAME_REGEX)) {
      setError("");
      setVehicleGroupName(target?.value); // only set when successful
    } else {
      setError("Special characters are not allowed, except (-) hyphens");
    }
  };

  const handleGroupChange = (e) => {
    setSelectedVehicleGroup(e.target.value);
  };

  return (
    <>
      {!isUpdate && (
        <DialogContentText style={{ width: "50%" }}>
          Please enter a name for a group of vehicles, which can be managed
          together.
        </DialogContentText>
      )}
      <TextField
        id="vehicle-group-name"
        label="Vehicle Group Name"
        placeholder="Type in a name for the group"
        type="text"
        autoFocus
        margin="dense"
        value={vehicleGroupName}
        onChange={onGroupNameChange}
        helperText={error}
        error={!!error}
        variant="standard"
        multiline
        style={{ width: "50%" }}
        rows={1}
      />
    </>
  );
};

export default GroupNameInput;
