import React from "react";
import { Typography, CardHeader } from "@mui/material";

const DataGridTitle = (props) => (
    <CardHeader sx={{ paddingBottom: '10px' }} title={
        <Typography variant="h6">
            {props?.title}
        </Typography>
    }></CardHeader>
);

export default DataGridTitle;
