import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  Pagination,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";

const AllVehiclesList = (props) => {
  const {
    isUpdate,
    isSearchable,
    title,
    items,
    meta,
    selectedVehicles = [],
    setSelectedVehicles,
    removedVehicles = [],
    setRemovedVehicles,
    selected,
    setSelected,
    onSearchInput,
    paginationData,
    setPaginationData,
    handlePageChange,
    vehiclesInGroupList = [],
  } = props;
  const [searchText, setSearchText] = useState("");
  const handleToggleAll = (i) => {
    const {
      target: { checked },
    } = i;
    if (checked) {
      const allItemsNoInGroupAlready = items.filter((i) => {
        const isVehicleAlreadyInGroup = [
          ...vehiclesInGroupList?.filter(
            (j) =>
              !removedVehicles?.filter((r) => r.vehicleKey === j.vehicleKey)
                .length
          ),
          ...selectedVehicles,
        ]?.filter((v) => v.vehicleKey === i.vehicleKey);
        return !isVehicleAlreadyInGroup?.length;
      });
      setSelected([...allItemsNoInGroupAlready]);
    } else {
      setSelected([]);
    }
  };

  const numberOfChecked = (i) => {
    return 0;
  };

  const handleToggle = (vehicle) => {
    if (vehicle?.vehicleKey) {
      const itemAlreadyPresent = selected?.filter(
        (i) => i?.vehicleKey === vehicle.vehicleKey
      )?.length;
      if (itemAlreadyPresent) {
        const otherItems = selected?.filter(
          (i) => i?.vehicleKey !== vehicle.vehicleKey
        );
        setSelected([...otherItems]);
      } else {
        setSelected([...selected, vehicle]);
      }
    }
  };

  const onSearchInputLocal = (text) => {
    if (!!text) {
      setSearchText(text);
    } else {
      setSearchText("");
    }
  };

  const handlePageChangeLocal = () => {
    // TODO
  };

  const isDisabled = (value) => {
    const isInSelected = selectedVehicles?.filter(
      (i) => value?.vehicleKey === i?.vehicleKey
    )?.length;
    if (isInSelected) {
      return true; // disable
    } else {
      const isInGroup = vehiclesInGroupList?.filter(
        (i) => value?.vehicleKey === i?.vehicleKey
      )?.length;
      if (isInGroup) {
        const isInRemoved = removedVehicles?.filter(
          (r) => r.vehicleKey === value.vehicleKey
        )?.length;
        if (isInRemoved) {
          return false; // enable
        } else {
          return true; // disable
        }
      } else {
        return false; // enable
      }
    }
  };

  const itemsAvailableForSelection = () => {
    return items.filter((i) => {
      const isVehicleAlreadyInGroup = [
        ...vehiclesInGroupList?.filter(
          (j) =>
            !removedVehicles?.filter((r) => r?.vehicleKey === j?.vehicleKey)
              .length
        ),
        ...selectedVehicles,
      ]?.filter((v) => v?.vehicleKey === i?.vehicleKey);
      return !isVehicleAlreadyInGroup?.length;
    });
  };

  return (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, pb: 0 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll}
            checked={
              selected?.length &&
              selected?.length === itemsAvailableForSelection()?.length
            }
            indeterminate={
              items?.length > 0 &&
              selected?.length &&
              selected?.length < itemsAvailableForSelection()?.length
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${selected?.length}/${
          itemsAvailableForSelection()?.length
        } selected`}
      />
      <CardHeader
        sx={{ px: 2, py: 1, pt: 0 }}
        avatar={
          isSearchable ? (
            <SearchOutlined style={{ width: "42px" }} />
          ) : (
            <div style={{ width: "42px", height: "26px" }} />
          )
        }
        subheader={
          isSearchable ? (
            <TextField
              placeholder="Search"
              size="small"
              variant="standard"
              onChange={(e) => {
                const {
                  target: { value },
                } = e;
                if (meta) {
                  onSearchInput(value);
                } else {
                  onSearchInputLocal(value);
                }
              }}
            />
          ) : null
        }
      />
      <Divider />
      <CardContent>
        <List
          sx={{
            width: "auto",
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          style={{
            height: isUpdate ? "calc(100vh - 586px)" : "calc(100vh - 612px)",
          }}
          dense
          component="div"
          role="list"
        >
          {items?.length > 0 &&
            items?.map((value) => {
              const labelId = `transfer-list-all-item-${value?.serialNo}-label`;

              return (
                <ListItem
                  key={value?.serialNo}
                  role="listitem"
                  style={{ paddingLeft: "0px" }}
                >
                  <ListItemIcon>
                    <>
                      {isDisabled(value) ? (
                        <Tooltip
                          title={`${value?.serialNo} is already added to group.`}
                        >
                          <DisabledByDefaultRoundedIcon
                            style={{ margin: "9px" }}
                            color="disabled"
                          />
                        </Tooltip>
                      ) : (
                        <Checkbox
                          checked={selected.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={() => handleToggle(value)}
                        />
                      )}
                    </>
                  </ListItemIcon>
                  <Tooltip title={value?.serialNo} placement="bottom-start">
                    <ListItemText
                      style={{ whiteSpace: "nowrap" }}
                      id={value?.serialNo}
                      primary={value?.serialNo}
                    />
                  </Tooltip>
                </ListItem>
              );
            })}

          <ListItem />
        </List>
      </CardContent>
      <Divider />
      <CardActions>
        <Pagination
          sx={{ mt: "6px" }}
          onChange={handlePageChange}
          {...paginationData}
          color="primary"
        />
      </CardActions>
    </Card>
  );
};

export default AllVehiclesList;
