import React from "react";
import { useTheme } from "@mui/material/styles";

const AppLogo = () => {
  const theme = useTheme();
  const logoClass = theme?.custom?.logoClass || "orange";
  return (
    <div>
      <div style={{ height: "48px" }}></div>
      <div className="logo-container">
        <div className={`logoicon ${logoClass}`}></div>
      </div>
    </div>
  );
};

export { AppLogo };
