import React from "react";
import { publishConfiguration } from "../../services/configuration";
import { useQueryClient, useMutation } from "react-query";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";

const PublishConfiguration = ({ data, listView = true }) => {
    const { row: { configId, configStatus } = {} } = data || {};
    // Access the client
    const queryClient = useQueryClient();

    // Mutations
    const { mutate: handlePublish, isLoading } = useMutation(publishConfiguration, {
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries("configurations");
        },
    });

    return (
        <div>
            {listView &&
                <Button size="small" component={RouterLink} to={`/custom-configurations/${configId}`}>
                    Details
                </Button>
            }
            {configStatus === "NOT_PUBLISHED" && (
                <Check I={CAN.PUBLISH} a={SUBJECTS.CONFIGURATION}>
                    <>
                        {listView && <span>|</span>}
                        <Button
                            size="small"
                            onClick={() => handlePublish(configId)}
                            disabled={isLoading}
                        >
                            {isLoading ? <HourglassTopIcon /> : "Publish"}
                        </Button>
                    </>
                </Check>
            )}
        </div>
    );
};

PublishConfiguration.defaultProps = {
    data: {},
};

PublishConfiguration.propTypes = {};

export default PublishConfiguration;