import { Tooltip } from "@mui/material";
import React from "react";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import SuccessFailStatus from "./SuccessFailStatus";
import DownloadCsv from "../common/DownloadCsv";

const getColumnsData = () => {
  // Column props reference available at - ota-portal_v2\node_modules\.pnpm\@mui+x-data-grid@5.15.2_rrttaqilrsb3aifveu7wjrwxnm\node_modules\@mui\x-data-grid\models\colDef\gridColDef.d.ts
  return [
    // {
    //   field: "schedulerLogId",
    //   headerName: "Log Id",
    //   flex: 0.5,
    //   renderCell: (data) => (
    //     <Tooltip title={data?.value}>
    //       <p
    //         style={{
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //         }}
    //       >
    //         {data?.value}
    //       </p>
    //     </Tooltip>
    //   ),
    // },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1.5,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "batchId",
      headerName: "Batch ID",
      flex: 1,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value || "-"}
          </p>
        </Tooltip>
      ),
    },
    // {
    //   field: "filePath",
    //   headerName: "File Path",
    //   flex: 1.5,
    //   renderCell: (data) => (
    //     <Tooltip title={data?.value}>
    //       <p
    //         style={{
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //         }}
    //       >
    //         {data?.value}
    //       </p>
    //     </Tooltip>
    //   ),
    // },
    {
      field: "createdAt",
      headerName: "Created",
      type: "dateTime",
      flex: 0.5,
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.createdAt} />
      ),
    },
    // {
    //   field: "updatedAt",
    //   headerName: "Updated",
    //   flex: 0.5,
    //   renderCell: (data) => (
    //     <DateAndTimeGridCell value={data?.row?.updatedAt} />
    //   ),
    // },
    {
      field: "status",
      headerName: "Status",
      type: "boolean",
      flex: 0.5,
      renderCell: ({ row }) => (
        <SuccessFailStatus
          text={row.status === "PASSED" ? "Success" : "Error"}
          color={row.status === "PASSED" ? "green" : "red"}
        />
      ),
    },
    {
      field: "statusCode",
      headerName: "Status code",
      flex: 1,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (d) => <DownloadCsv data={d?.row} />,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "sentMail",
    //   headerName: "Email Sent",
    //   flex: 0.5,
    // },
  ];
};

export { getColumnsData };
