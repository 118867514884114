import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { deleteVehiclesInGroup } from "../../../services/vehicle";
import { useToast } from "../../../components/toast";
import CloseIcon from "@mui/icons-material/Close";

const DeleteGroupConfirmDialog = (props) => {
  const { addToast } = useToast();
  // Access the client
  const queryClient = useQueryClient();
  const { id, name, type = "vehicle group", toggleDialog } = props;

  const { mutate: updateMutation, isLoading: updateLoading } = useMutation(
    deleteVehiclesInGroup,
    {
      onSuccess: ({ data }) => {
        addToast({
          type: "success",
          message: `Vehicle group "${data?.vehicleGroupName}" deleted successfully.`,
          autoClose: 3000,
        });
        queryClient.invalidateQueries("vehicleGroup");
        queryClient.invalidateQueries("vehicleGroupsList");
        queryClient.invalidateQueries("getStatsForVehicleGroupsView");
        toggleDialog();
      },
      onError: (e) => {
        const { response } = e;
        addToast({
          type: "error",
          message: response?.data?.message || "Failed to delete group!",
          autoClose: 3000,
        });
      },
    }
  );
  const onConfirm = () => {
    updateMutation({
      vehicleGroupId: id,
    });
    toggleDialog();
  };
  const onCancel = () => {
    toggleDialog();
  };
  return (
    <Dialog
      open={!!id}
      onClose={onCancel}
      aria-labelledby="delete-confirm-dialog-title"
      aria-describedby="delete-confirm-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Confirm Delete`}
        <IconButton
          aria-label="close"
          onClick={toggleDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Tooltip title="Close delete dialog">
            <CloseIcon />
          </Tooltip>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Do you really want to delete the ${type} "${name}"?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGroupConfirmDialog;
