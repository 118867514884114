import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
  VehicleList,
  VehicleStats,
  VehicleSearch,
} from "../components/vehicle";
import VehicleGroupDetails from "../components/vehicle/VehicleGroupDetails";
import "../styles/vehicle.css";

const Vehicles = () => {
  const [vehicleId, setVehicleId] = useState();
  const [selectedGroupsItems, setSelectedGroupsItems] = useState([]);
  const [refetchGroupsList, setRefetchGroupsList] = useState(false);
  const [open, setOpen] = useState(false);
  const [queryGroupName, setQueryGroupName] = useState("");
  const [extraFilters, setExtraFilters] = useState([]);

  const [selectedVehiclesSerials, setSelectedVehiclesSerials] = useState([]);
  const [selectedVehiclesList, setSelectedVehiclesList] = useState([]);

  const resetDataTableSelectionItems = () => {
    setSelectedVehiclesList([]); // Reset the data table selection
    setSelectedVehiclesSerials([]); // Reset the data table selection
  };

  const openQueryBuilder = (name) => {
    if (name) {
      setQueryGroupName(name);
    }
    setOpen(true);
  };

  const onSuccess = () => {
    setTimeout(() => {
      if (doRefetchGroupsList) doRefetchGroupsList();
    }, 1000);
  };

  const handleClose = () => {
    setOpen(false);
    setQueryGroupName("");
    onSuccess();
  };

  const vehicleSearchprops = {
    vehicleId,
    setVehicleId,
    openQueryBuilder,
    extraFilters,
    setExtraFilters,
    resetDataTableSelectionItems,
  };

  const doRefetchGroupsList = () => {
    setRefetchGroupsList(true);
  };

  useEffect(() => {
    if (refetchGroupsList) {
      setTimeout(() => {
        setRefetchGroupsList(false);
      }, 1000);
    }
  }, [refetchGroupsList]);

  const vehicleListProps = {
    vehicleId,
    groupItemsDataArray: selectedGroupsItems,
    selectedGroupsItems,
    doRefetchGroupsList,
    refetchGroupsList,
    extraFilters,
    setExtraFilters,
    selectedVehiclesSerials,
    setSelectedVehiclesSerials,
    selectedVehiclesList,
    setSelectedVehiclesList,
  };

  const vehicleGroupListProps = {
    selectedGroupsItems,
    setSelectedGroupsItems,
    doRefetchGroupsList,
    refetchGroupsList,
    openQueryBuilder,
    extraFilters,
    setExtraFilters,
  };

  const queryBuilderProps = {
    openQueryBuilder,
    onClose: handleClose,
    onSuccess: onSuccess,
    open,
    doRefetchGroupsList,
    refetchGroupsList,
    queryGroupName,
    setQueryGroupName,
  };

  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <VehicleStats />
        <VehicleSearch {...vehicleSearchprops} />
        <Grid item xs={12} md={9}>
          <VehicleList {...vehicleListProps} />
        </Grid>
      </Grid>
      <VehicleGroupDetails {...queryBuilderProps} />
    </div>
  );
};

export default Vehicles;
