import React from "react";
import { Paper, Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import { getJobDocument } from "../../services/configuration";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";

const ConfigurationJobDocumentTab = () => {
    const params = useParams();
    const { configId = "" } = params;

    // Queries
    const {
        data: { data: { document: jobDocument = null } = {} } = {},
        isLoading,
    } = useQuery(["jobDocument", configId], getJobDocument);

    const PrettyPrintJson = (document) => {
        if (document && document.data) {
            return (
                <div className="job-document-container">
                    <pre style={{ fontSize: 12 }}>
                        {JSON.stringify(JSON.parse(document.data), null, 2)}
                    </pre>
                </div>
            );
        }
        return (
            <Alert variant="outlined" severity="warning">
                Document not found!
            </Alert>
        );
    };

    return (
        <>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Paper elevation={0} sx={{ overflow: "auto", maxHeight: 700 }}>
                    <PrettyPrintJson data={jobDocument}></PrettyPrintJson>
                </Paper>
            )}
        </>
    );
};

ConfigurationJobDocumentTab.defaultProps = {};

ConfigurationJobDocumentTab.propTypes = {};

export default ConfigurationJobDocumentTab;
