import React, { useEffect, useState } from "react";
import {
  useParams,
  Link as RouterLink,
  useLocation,
  Routes,
  Route,
} from "react-router-dom";
import {
  Typography,
  Breadcrumbs,
  Link,
  Tabs,
  Box,
  List,
  ListItem,
  ListItemText,
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Tab,
  Paper,
} from "@mui/material";
import axios from "axios";
import Constants from "../services/Constants";
import {
  detailsTabsBoxStyle,
  formatDate,
  getCurrentPathTab,
  overviewListItemStyles,
} from "../services/Utils";
import BreadCrumbsRendered from "../components/BreadCrumbsRendered";
import { DateAndTimeGridCell } from "../components/common/DateAndTimeGridCell";

const PATHS = [
  "overview",
  // "releases",
  "images",
];

const Release = () => {
  let params = useParams();
  const { pathname } = useLocation();
  const [value, setValue] = useState(
    getCurrentPathTab(pathname, PATHS, "overview")
  );
  const [releaseDetails, setReleaseDetails] = useState({ images: [] });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const notifyLinkClick = (toPath) => {
    setValue(getCurrentPathTab(toPath, PATHS, "overview"));
  };

  useEffect(() => {
    async function getReleaseDetails() {
      const releaseDetails = await axios.get(
        Constants.DESCRIBE_RELEASE.replace(":id", params.releaseId)
      );
      setReleaseDetails(releaseDetails.data);
    }
    getReleaseDetails();
  }, [params.releaseId]);

  const Overview = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <List>
          <ListItem {...overviewListItemStyles}>
            <ListItemText primary="Id" secondary={releaseDetails.releaseId} />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="Release Name"
              secondary={releaseDetails.releaseName}
            />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="Created By"
              secondary={releaseDetails.createdBy}
            />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="Created At"
              secondary={formatDate(releaseDetails.createdAt)}
            />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="No. of images"
              secondary={releaseDetails.images.length}
            />
          </ListItem>
        </List>
      </Box>
    );
  };

  const Releases = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        {" "}
        <div>ECU Details</div>
      </Box>
    );
  };

  const ReleaseImages = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
          <Table
            stickyHeader
            sx={{ minWidth: 650, border: "1px solid #e2e2e2" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell align="right">File Type</TableCell>
                <TableCell align="right">File Size</TableCell>
                <TableCell align="right">Version</TableCell>
                <TableCell align="right">Created At</TableCell>
                <TableCell align="right">Uploaded By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {releaseDetails.images.map((image) => (
                <TableRow
                  key={image.id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {image.fileName}
                  </TableCell>
                  <TableCell align="right">{image.fileType}</TableCell>
                  <TableCell align="right">{image.fileSize}</TableCell>
                  <TableCell align="right">{image.version}</TableCell>
                  <TableCell align="right">
                    <DateAndTimeGridCell value={image?.createdAt} />
                  </TableCell>
                  <TableCell align="right">{image.uploadedBy}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <div className="fragmentContainer">
      <BreadCrumbsRendered
        pathname={pathname}
        notifyLinkClick={notifyLinkClick}
      />

      <Box
        sx={{ width: "100%", marginTop: 4, padding: "2%" }}
        component={Paper}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs onChange={handleChange} value={value}>
            <Tab
              label="Overview"
              value={"overview"}
              component={RouterLink}
              to={"overview"}
            />
            {/* <Tab
              label="Releases"
              value={"releases"}
              component={RouterLink}
              to={"releases"}
            /> */}
            <Tab
              label="Images"
              value={"images"}
              component={RouterLink}
              to={"images"}
            />
          </Tabs>
        </Box>
        <div className="tabs-container">
          <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="overview" element={<Overview />} />
            <Route path="releases" element={<Releases />} />
            <Route path="images" element={<ReleaseImages />} />
          </Routes>
        </div>
      </Box>
    </div>
  );
};

export default Release;
