import { useContext } from "react";
import { WizardContext } from "../components/wizard/Context";
import { useToast } from "../components/toast";
import { MODULE_TEXT } from "../services/Constants";

const useModule = () => {
    const { state } = useContext(WizardContext);
    const { addToast } = useToast();
    const { customerData = [] } = state;
    const allModules = customerData.map(m => m.moduleId);

    const validateModuleWithMessage = (currentModule) => {
        if (allModules.includes(currentModule)) {
            return true;
        }
        addToast({
            type: "warning",
            message: `${MODULE_TEXT[currentModule]} module is not enabled for ${localStorage.selectedCustomer} customer.`,
            autoClose: 5000,
        });
        return false;
    }

    const validate = (currentModule) => {
        if (allModules.includes(currentModule)) {
            return true;
        }
        return false;
    }

    return {
        validateModuleWithMessage,
        validate
    };
}

export default useModule;
