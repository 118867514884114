import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Checkbox,
  TextField,
  Radio,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { updateVehiclesInGroup } from "../../services/vehicle";
import { useToast } from "../toast";
import CloseIcon from "@mui/icons-material/Close";
import { getVehicleGroupData } from "../../services/deployment";

const AddGroupToVehiclePopup = (props) => {
  const { addToast } = useToast();
  // Access the client
  const queryClient = useQueryClient();
  const {
    serialNo,
    vehicleKey,
    toggleDialog,
    singleSelectMode = false, // if TRUE, allows selection of only one vehicle group from the list.
    groupsToExclude = [],
  } = props;

  const [checked, setChecked] = React.useState([]);
  const [vehicleGroupData, setVehicleGroupData] = useState([]);
  const [groupsList, setGroupsList] = useState([]);

  const toogle = () => {
    setChecked([]);
    setVehicleGroupData([]);
    setGroupsList([]);
    toggleDialog?.();
  };

  const handleGroupToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    if (singleSelectMode) {
      if (currentIndex === -1) {
        setChecked([value]);
      } else {
        setChecked([]);
      }
    } else {
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    }
  };

  const { isLoading } = useQuery("vehicleGroups", getVehicleGroupData, {
    onSuccess: (data = []) => {
      const itemsToExclude = groupsToExclude?.map((i) => i.vehicleGroupId);
      const newGroups = data?.filter(
        (i) => !itemsToExclude.includes(i?.vehicleGroupId)
      );
      setVehicleGroupData(newGroups);
      setGroupsList(newGroups);
    },
    refetchOnWindowFocus: false,
  });

  const { mutate: updateMutation, isLoading: updateLoading } = useMutation(
    updateVehiclesInGroup,
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries("associatedVehicles");
        toogle();
        addToast({
          type: "success",
          message: `Vehicle ${serialNo} added to group ${""} successfully.`,
          autoClose: 3000,
        });
      },
      onError: (e) => {
        const { response } = e;
        addToast({
          type: "error",
          message: response?.data?.message || "Failed to add!",
          autoClose: 3000,
        });
      },
    }
  );
  const onAdd = () => {
    const selectedItem = vehicleGroupData?.find((i) => {
      return i?.vehicleGroupId === checked[0];
    });

    updateMutation({
      vehicleGroupId: selectedItem?.vehicleGroupId,
      vehicleGroupName: selectedItem?.vehicleGroupName,
      vehicleKeys: {
        addVehicles: [vehicleKey],
      },
    });
  };
  const onCancel = () => {
    toogle();
  };

  const onSearchInput = (e) => {
    setChecked([]);
    if (e.target.value === "") {
      setGroupsList([]);
    } else {
      setGroupsList(
        vehicleGroupData.filter((g) =>
          g.vehicleGroupName.includes(e.target.value)
        )
      );
    }
  };

  return (
    <Dialog
      open={!!vehicleKey}
      onClose={onCancel}
      aria-labelledby="delete-confirm-dialog-title"
      aria-describedby="delete-confirm-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Add to vehicle group`}
        <IconButton
          aria-label="close"
          onClick={toogle}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Tooltip title="Close add dialog">
            <CloseIcon />
          </Tooltip>
        </IconButton>
        <TextField
          style={{ marginTop: "1em" }}
          type="search"
          label="Search Vehicle Groups"
          id="outlined-size-small"
          size="small"
          fullWidth
          onChange={(e) => onSearchInput(e)}
        />
      </DialogTitle>

      <DialogContent>
        <List
          disablePadding
          dense
          sx={{
            width: "100%",
            minWidth: 360,
            height: 460,
            bgcolor: "background.paper",
          }}
        >
          {isLoading ? (
            <CircularProgress color="primary" />
          ) : (
            <>
              {groupsList?.map((group) => {
                const labelId = `checkbox-list-secondary-label-${group?.vehicleGroupId}`;
                return (
                  <ListItem
                    key={group?.vehicleGroupId}
                    secondaryAction={
                      <>
                        {singleSelectMode ? (
                          <Radio
                            checked={
                              checked.indexOf(group?.vehicleGroupId) !== -1
                            }
                            onChange={handleGroupToggle(group?.vehicleGroupId)}
                            value={group?.vehicleGroupId}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                          />
                        ) : (
                          <Checkbox
                            edge="end"
                            onChange={handleGroupToggle(group?.vehicleGroupId)}
                            checked={
                              checked.indexOf(group?.vehicleGroupId) !== -1
                            }
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        )}
                      </>
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemText
                        id={labelId}
                        primary={group?.vehicleGroupName}
                        secondary={group?.vehicleGroupId}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button disabled={updateLoading} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={updateLoading || !checked?.length}
          onClick={onAdd}
          autoFocus
        >
          {updateLoading ? (
            <CircularProgress size="1rem" color="secondary" />
          ) : (
            "Add"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddGroupToVehiclePopup;
