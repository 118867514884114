import {
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid";

const CustomToolbar = () => {
    return (
        <GridToolbarContainer sx={{ padding: '0.5% 1%' }}>
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </GridToolbarContainer>
    );
}

export default CustomToolbar;