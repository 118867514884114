import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { WizardContext, TABS } from "../Context";
import VehicleShadowList from "../../vehicle/shadow/VehicleShadowList";

const ConfigReviewDetails = () => {
    const { state } = useContext(WizardContext);
    const configItems = [];
    const {
        toggleVehicle,
        vehicles,
        vehicleGroups,
        configDescription,
        configName,
        configState,
    } = state;

    configItems.push({
        title: "Key",
        description: Object.keys(configState).map(i => { return { value: i } }),
    });
    configItems.push({
        title: "Value",
        description: Object.values(configState).map(i => { return { value: i } }),
    });

    return (
        <Grid container justifyContent="center" spacing={2} className="wizard-grid">
            <Grid item xs={10}>
                <p className="wizard-heading">Configuration summary</p>
            </Grid>
            <Grid item xs={10}>
                <Grid container spacing={4} className="wizard-grid">
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "500" }}
                            gutterBottom
                            component="div"
                        >
                            Configuration name
                        </Typography>
                        <Typography
                            variant="body2"
                            gutterBottom
                            sx={{ marginBottom: "5%" }}
                        >
                            {configName}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "500" }}
                            gutterBottom
                            component="div"
                        >
                            Configuration description
                        </Typography>
                        <Typography
                            variant="body2"
                            gutterBottom
                            sx={{ marginBottom: "5%" }}
                        >
                            {configDescription}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "500" }}
                            gutterBottom
                            component="div"
                        >
                            Selected{" "}
                            {toggleVehicle === TABS.CHOOSE_VEHICLES
                                ? `Vehicles (${vehicles.length})`
                                : `Vehicle Groups (${vehicleGroups.length})`}
                        </Typography>
                        {toggleVehicle === TABS.CHOOSE_VEHICLES && (
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ marginBottom: "5%" }}
                            >
                                {vehicles.map((v, i) => (
                                    <span key={v}>{`${v}${vehicles.length - 1 > i ? ", " : ""
                                        }`}</span>
                                ))}
                            </Typography>
                        )}
                        {toggleVehicle === TABS.CHOOSE_VEHICLE_GROUPS && (
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ marginBottom: "5%" }}
                            >
                                {vehicleGroups.map((g, i) => (
                                    <span key={g}>{`${g.split("_").pop()}${vehicleGroups.length - 1 > i ? ", " : ""
                                        }`}</span>
                                ))}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={8}>
                <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500" }}
                    gutterBottom
                    component="div"
                >
                    Configuration state
                </Typography>
                <VehicleShadowList configItems={configItems} />
            </Grid>
        </Grid>
    );
};

export default ConfigReviewDetails;
