import React from "react";
import { capitalize } from "../../services/Utils";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import ConfigurationStatus from "./ConfigurationStatus";
import PublishConfiguration from "./PublishConfiguration";

const getColumnsData = () => {
  return [
    {
      field: "configName",
      headerName: "Configuration Name",
      flex: 2,
      editable: false,
    },
    {
      field: "vehicleNos",
      headerName: "Vehicle Nos",
      flex: 0.75,
      editable: false,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      sortable: true,
      flex: 0.75,
      valueFormatter: ({ value }) => capitalize(value),
    },
    {
      field: "campaignCreationDate",
      headerName: "Created On",
      sortable: true,
      flex: 0.75,
      type: "dateTime",
      renderCell: (data) => <DateAndTimeGridCell {...data} />,
    },
    {
      field: "configStatus",
      headerName: "Status",
      flex: 0.75,
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: (data) => {
        return <ConfigurationStatus status={data.row.configStatus} />;
      },
    },
    {
      field: "empty",
      headerName: "Actions",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (data) => {
        return <PublishConfiguration data={data} />;
      },
    },
  ];
};

export { getColumnsData };
