import React from "react";
import { useParams } from "react-router-dom";
import { cancelVehicleDeployment } from "../../services/deployment";
import { useMutation } from "react-query";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
} from "@mui/material";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { useToast } from "../toast";

const CancelVehicleDeployment = ({
  data: { row: jobExecutionSummary = {} } = {},
}) => {
  const [open, setOpen] = React.useState(false);
  const { addToast } = useToast();
  const [isCancelSuccess, setIsCancelSuccess] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let { deploymentId } = useParams();
  // const { jobExecutionSummary = {} } = data || {};
  const vehicle = jobExecutionSummary?.serialNo;
  const payload = {
    deploymentId,
    vehicle,
  };

  const { mutate: handleCancelVehicle, isLoading: cancelIsLoading } =
    useMutation(cancelVehicleDeployment, {
      onSuccess: () => {
        setIsCancelSuccess(true);
      },
      onError: (err) => {
        const {
          response: { data: errorData },
        } = err;
        if (err && errorData) {
          if (errorData.statusCode === 404) {
            setErrorMsg(true);
            handleOpen();
            // addToast({
            //   type: "error",
            //   message: errorData.message,
            //   autoClose: 5000,
            // });
          }
        }
      },
    });

  return (
    <div>
      {(jobExecutionSummary?.jobExecutionStatus === "QUEUED" ||
        jobExecutionSummary?.jobExecutionStatus === "IN_PROGRESS") && (
        <Check I={CAN.CANCEL} a={SUBJECTS.DEPLOYMENT}>
          <Button
            size="small"
            onClick={() => {
              if (jobExecutionSummary?.jobExecutionStatus === "IN_PROGRESS") {
                handleOpen();
              } else {
                handleCancelVehicle(payload);
              }
            }}
            disabled={cancelIsLoading || isCancelSuccess}
          >
            {cancelIsLoading ? <HourglassTopIcon /> : "Cancel"}
          </Button>
        </Check>
      )}
      <InprogressCancelAlert
        {...{ open, handleClose, handleCancelVehicle, payload, errorMsg }}
      />
    </div>
  );
};

CancelVehicleDeployment.defaultProps = {
  data: {},
};

CancelVehicleDeployment.propTypes = {};

export default CancelVehicleDeployment;

const InprogressCancelAlert = ({
  open,
  handleClose,
  handleCancelVehicle,
  payload,
  errorMsg,
}) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Cancel Deployment
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {errorMsg ? (
            <>Please wait for the deployment to finish.</>
          ) : (
            <>
              The deployment on this vehicle is IN_PROGRESS.Are you sure you
              want to cancel it?
            </>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {!errorMsg && (
          <>
            <Button
              onClick={() => {
                handleCancelVehicle(payload);
                handleClose();
              }}
            >
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
