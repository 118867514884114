import React, { Fragment } from "react";
import {
  Grid,
  CardActionArea,
} from "@mui/material";
import { useQuery } from "react-query";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getImagesData } from "../../services/Image";
import { grey } from "@mui/material/colors";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { MODULES } from "../../services/Constants";
import useModule from "../../hooks/useModule";
import FileUploadDialog from "./FileUploadDialog";
import DottedTile from "../common/DottedTile";
import InfoTile from "../common/InfoTile";

const ImageStats = () => {
  const { validateModuleWithMessage } = useModule();
  const { data: { data: { imageCount = 0, totalBytes = "0" } = {} } = {}, isLoading, isFetching } =
    useQuery("getImagesData", getImagesData, {
      refetchOnWindowFocus: false,
    });

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => {
    if (!validateModuleWithMessage(MODULES.OTA)) return;
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);

  return (
    <Fragment>
      <Grid item xs={6} md={2}>
        <InfoTile title={"Total Uploaded Images"} info={imageCount} isLoading={isLoading || isFetching} />
      </Grid>
      <Grid item xs={6} md={2}>
        <InfoTile title={"Total Uploaded Bytes"} info={totalBytes} isLoading={isLoading || isFetching} />
      </Grid>
      <Check I={CAN.CREATE} a={SUBJECTS.IMAGE}>
        <Grid item xs={6} md={2}>
          <CardActionArea onClick={handleOpen}>
            <DottedTile title={"UPLOAD A NEW IMAGE"}>
              <CloudUploadIcon
                sx={{ fontSize: "3.5rem", color: grey[500] }}
              />
            </DottedTile>
          </CardActionArea>
          <FileUploadDialog handleClose={handleClose} openModal={openModal} />
        </Grid>
      </Check>
      <Grid item xs={3}></Grid>
    </Fragment>
  );
};

ImageStats.defaultProps = {};

ImageStats.propTypes = {};

export default ImageStats;
