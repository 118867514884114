import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import CancelVehicleDeployment from "./CancelVehicleDeployment";
import {
  Button,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";

const getColumnsData = ({ vehicleId, deploymentId, type }) => {
  return [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 2,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (vehicle) => {
        const { row = {} } = vehicle;
        if (vehicleId) {
          //Vehicle Deployment
          return row?.serialNo;
        } else {
          //Deployment Vehicle
          return (
            <Tooltip title={row.serialNo}>
              <Button
                size="medium"
                disabled={!row?.vehicleKey}
                sx={{
                  justifyContent: "flex-start",
                  textTransform: "none",
                }}
                component={RouterLink}
                to={`/vehicles/${row.serialNo}/deployments/${deploymentId}`}
              >
                {row.serialNo}
              </Button>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      flex: 3,
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (vehicle) => {
        if (!vehicleId) {
          return vehicle.vehicleKey;
        }
      },
    },
    {
      field: "jobExecutionStatus",
      headerName: "Job Status",
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (data) => {
        const { row: { jobExecutionStatus = "", description = "" } = {} } =
          data || {};
        return (
          <>
            {!!description ? (
              <Tooltip title={description}>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant="caption">
                        {jobExecutionStatus}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "small",
                          textWrap: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "grey",
                        }}
                      >
                        {description}
                      </Typography>
                    }
                  />
                </ListItem>
              </Tooltip>
            ) : (
              <ListItem sx={{ p: 0 }}>
                <ListItemText
                  primary={
                    <Typography variant="caption">
                      {jobExecutionStatus}
                    </Typography>
                  }
                />
              </ListItem>
            )}
          </>
        );
      },
    },
    {
      field: "vehicleGroupName",
      headerName: "Group Name",
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (data) => {
        const { row: vehicle = {} } = data || {};
        if (!!vehicle?.vehicleGroupName && !!vehicle?.vehicleGroupId) {
          return (
            <Tooltip title={vehicle?.vehicleGroupName}>
              <Button
                disabled={vehicle?.vehicleGroupIsDeleted}
                size="medium"
                sx={{
                  justifyContent: "flex-start",
                  textTransform: "none",
                }}
                component={RouterLink}
                to={`/groups/${vehicle.vehicleGroupId}/deployments/${deploymentId}`}
              >
                {vehicle?.vehicleGroupName}
              </Button>
            </Tooltip>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "createdAt",
      headerName: "Started At",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (vehicle) => {
        const { row: { createdAt = "" } = {} } = vehicle || {};
        return createdAt ? <DateAndTimeGridCell value={createdAt} /> : "-";
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated At",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (vehicle) => {
        const { row: { updatedAt = "" } = {} } = vehicle || {};
        return updatedAt ? <DateAndTimeGridCell value={updatedAt} /> : "-";
      },
    },
    {
      field: "none",
      headerName: "Actions",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (data) => <CancelVehicleDeployment data={data} />,
    },
  ];
};

export { getColumnsData };
