import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import { getJobExecutionsForVehicles_v1 } from "../../services/vehicle";
import { getColumnsData } from "./VehicleDeploymentColumnsV1";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { getDeploymentVehicleLogDetails_v1 } from "../../services/deployment";

const VehicleDeploymentListV1 = (data = {}) => {
  let { vehicleId, deploymentId } = useParams();
  const queryClient = useQueryClient();
  const {
    vehicles = {},
    type,
    setErrMsg = () => {},
    setShowJobDocument,
  } = data;

  const { vehicle } = vehicles;

  const getFn =
    type === "vehicle"
      ? getJobExecutionsForVehicles_v1
      : getDeploymentVehicleLogDetails_v1;

  const columns = getColumnsData({
    vehicleId,
    deploymentId,
    type,
  });

  // jobExecutions Queries
  let {
    data: { data: { executionSummaries = [] } = {} } = {},
    isLoading: summaryLoading,
  } = useQuery(
    ["jobExecutions", deploymentId, vehicle?.vehicleKey],
    getFn, // to get the execution summary of the whole deployment
    {
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: () => {
        setShowJobDocument?.(true);
      },
      onError: (error) => {
        const { response: { data: { message = "" } = {} } = {} } = error;
        setErrMsg(message);
      },
    }
  );

  const allVehicles = executionSummaries.map((execution) => {
    return {
      show: true,
      serialNo: execution?.thingArn.split("/")[1],
      ...execution,
    };
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries("jobExecutions");
    };
  }, [queryClient]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  };

  return (
    <Grid item xs={12}>
      <Box sx={{ height: "calc(100vh - 232px)", width: "100%" }}>
        <DataGrid
          columnVisibilityModel={{
            deploymentId: vehicleId ? true : false,
            serialNo: !vehicleId ? true : false,
            vehicleKey: !vehicleId ? true : false,
            group: !vehicleId ? true : false,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          sx={{ minHeight: 400 }}
          loading={summaryLoading}
          columns={columns}
          rows={allVehicles}
          paginationMode="client"
          getRowId={(row) => row.serialNo}
          {...(!vehicleId && { components: { Toolbar: CustomToolbar } })}
        />
      </Box>
    </Grid>
  );
};

export default VehicleDeploymentListV1;
