import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  Card,
  Box,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  CardHeader,
  Typography,
} from "@mui/material";
import {
  DataGrid
} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getReportsForProvisioningByDateRange } from "../../services/Dashboard";
import { getColumns } from "./ProvisioningColumns";
import { isValidDate } from "../../services/Utils";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import DataGridTitle from "../common/DataGridTitle";
import CustomToolbar from "../common/CustomToolbar";

const REPORT_TYPES = [
  { label: "HU Manufactured date", value: "hu-manufactured-date" },
  { label: "Vehicle Manufactured date", value: "vehicle-manufactured-date" },
  // { label: "Vehicle purchase date", value: "vehicle-purchased-date" },
];

const ProvisioningReport = (props) => {
  const [columns, setColumns] = useState(getColumns("hu-manufactured-date"));
  const [refresh, setRefresh] = useState(false);
  const [rows, setRows] = useState([]);
  const [fetchData, setFetchData] = useState(true);
  const [selected, setSelected] = useState(REPORT_TYPES[0].value);
  const from = new Date(
    new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(
      0,
      0,
      0,
      0
    )
  );
  const to = new Date(new Date().setHours(23, 59, 59, 59));
  const [dateRange, setDateRange] = useState({
    from: from,
    to: to,
  });

  const [fromDateInputErrorText, setFromDateInputErrorText] = useState(null);
  const [toDateInputErrorText, setToDateInputErrorText] = useState(null);
  const [searchObj, setSearchObj] = useState({
    type: selected,
    dateRange,
  });
  /*
    const processRows = (data) => {
      const result = data?.map((i) => {
        let features = "-";
        if (selected === "hu-manufactured-date") {
          const vehicleFeatures = i?.vehicle?.vehicleFeatures;
          i.vehicleCreationDate = i?.vehicle?.vehicleCreationDate;
          features = vehicleFeatures?.length
            ? vehicleFeatures
                ?.map((i) =>
                  i?.active ? i?.feature?.featureName.toUpperCase() || "-" : "-"
                )
                .join(",")
            : "-";
        } else {
          const vehicleFeatures = i?.vehicleFeatures;
          features = vehicleFeatures?.length
            ? vehicleFeatures
                ?.map((i) =>
                  i?.active ? i?.feature?.featureName.toUpperCase() || "-" : "-"
                )
                .join(",")
            : "-";
        }
        return { ...i, features: features };
      });
      return result;
    };
  */
  const { isLoading, isFetching } = useQuery(
    ["fetchProvisioningReport", searchObj],
    getReportsForProvisioningByDateRange,
    {
      enabled: fetchData,
      onSuccess: (data) => {
        // const result = processRows(data);
        setRows(data);
      },
      onSettled: () => {
        setFetchData(false);
      },
      refetchOnWindowFocus: false,
    }
  );
  const handleChange = (event) => {
    const {
      target: { value: type },
    } = event;
    setSelected(type);
    setColumns(getColumns(type));
    processSearch(type);
  };

  const handleDateChange = (newValue = null, type) => {
    const newDates = dateRange;
    const newFrom = !!newValue ? new Date(newValue.setHours(0, 0, 0, 0)) : null;
    const newTo = !!newValue
      ? new Date(newValue.setHours(23, 59, 59, 59))
      : null;
    if (!!newValue && !!type && type === "from") {
      if (moment(newValue).isBefore("2001-01-01")) {
        setFromDateInputErrorText("Error");
        setDateRange({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      if (moment(newValue).isAfter(newDates.to)) {
        setFromDateInputErrorText("Date greater than To date!");
        setDateRange({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      if (!isValidDate(new Date(newValue))) {
        setFromDateInputErrorText("Invalid date!");
        setDateRange({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      setDateRange({ from: newFrom, to: newDates.to });
      setFromDateInputErrorText(null);
    } else if (!!newValue && !!type && type === "to") {
      if (moment(newValue).isBefore(newDates.from)) {
        setToDateInputErrorText("Date less than From date!");
        setDateRange({ from: newDates.from, to: newTo });
        return;
      }
      if (!isValidDate(new Date(newValue))) {
        setToDateInputErrorText("Invalid date!");
        setDateRange({ from: newDates.from, to: newTo });
        return;
      }
      setDateRange({ from: newDates.from, to: newTo });
      setToDateInputErrorText(null);
    } else if (!newValue && type === "to") {
      setDateRange({ from: newDates.from, to: newTo });
    } else if (!newValue && type === "from") {
      setDateRange({ from: newFrom, to: newDates.to });
    }
  };

  const processSearch = (type) => {
    const searchData = JSON.parse(
      JSON.stringify({ type: selected, dateRange })
    );
    setSearchObj({
      type: type || searchData?.type,
      dateRange: {
        from: new Date(searchData?.dateRange?.from),
        to: new Date(searchData?.dateRange?.to),
      },
    });
    setFetchData(true);
  };

  const commonProps = { refresh, setRefresh };

  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Card sx={{ height: '100px' }}>
            <CardContent className="tile-cards">
              <Grid container spacing={2}>
                <Grid item xs="auto">
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Report Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={selected}
                      onChange={handleChange}
                      label="Report Type"
                    >
                      {REPORT_TYPES.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs="auto">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container>
                      <Grid item xs="auto">
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <DatePicker
                            disableFuture
                            label="From"
                            value={dateRange.from}
                            onChange={(val) => handleDateChange(val, "from")}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                id="standard-basic"
                                variant="standard"
                                error={!!fromDateInputErrorText}
                                helperText={fromDateInputErrorText}
                              />
                            )}
                            maxDate={new Date()}
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs="auto">
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <DatePicker
                            disableFuture
                            label="To"
                            value={dateRange.to}
                            onChange={(val) => handleDateChange(val, "to")}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                id="standard-basic"
                                variant="standard"
                                error={!!toDateInputErrorText}
                                helperText={toDateInputErrorText}
                              />
                            )}
                            minDate={dateRange.from}
                            required
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs="auto">
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={
                        !!fromDateInputErrorText || !!toDateInputErrorText
                      }
                      variant="outlined"
                      startIcon={<SearchIcon />}
                      onClick={() => processSearch()}
                    >
                      Search
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <DataGridTitle title="Provisioning Report"></DataGridTitle>
            <CardContent sx={{
              padding: 0, ":last-child": {
                padding: 0
              }
            }}>
              <Box sx={{ height: "calc(100vh - 280px)", width: "100%" }}>
                <DataGrid
                  key={selected}
                  loading={isLoading || isFetching}
                  getRowId={(row) =>
                    row?.serialNoCreatedAt + row?.vehicleKey + row?.serialNo
                  }
                  rows={rows || []}
                  columns={columns}
                  checkboxSelection
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  components={{ Toolbar: CustomToolbar }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProvisioningReport;
