import React, { useState } from "react";
import { string, func, bool } from "prop-types";
import axios from "axios";
import { useQueryClient } from "react-query";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { Button } from "@mui/material";
import Constants from "../../services/Constants";

const UserConfirmBtn = (props) => {
    const { userId, status } = props;
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const confirmUser = async ({ userId }) => {
        setLoading(true);
        let url = Constants.CONFIRM_USER.replace(":userId", userId);
        await axios.patch(url);
        // Invalidate and refetch
        queryClient.invalidateQueries('getAllUsersData');
        queryClient.invalidateQueries('getAllUserGroups');
        setLoading(false);
    };
    if (status !== 'UNCONFIRMED') return null;
    if (loading)
        return <Button disabled startIcon={<HourglassTopIcon />}></Button>;
    return (
        <Button
            variant="text"
            onClick={() => confirmUser({ userId })}
        >
            Confirm
        </Button>
    );
};

UserConfirmBtn.defaultProps = {
    userId: "",
    enabled: false,
};
UserConfirmBtn.propTypes = {
    userId: string,
    enabled: bool,
};

export default UserConfirmBtn;
