import React from "react";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import StatusActiveInactive from "../common/StatusActiveInactive";

const getHuManufacturedCols = () => {
  // Column props reference available at - ota-portal_v2\node_modules\.pnpm\@mui+x-data-grid@5.15.2_rrttaqilrsb3aifveu7wjrwxnm\node_modules\@mui\x-data-grid\models\colDef\gridColDef.d.ts
  return [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 1,
      // editable: true,
      renderCell: (d) => d.value || "-",
    },
    {
      field: "serialNoCreatedAt",
      headerName: "Serial No Created At",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      flex: 1,
    },
    {
      field: "vehicleKeyUpdatedAt",
      headerName: "Vehicle Key Mapped On",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "featureStatus",
      headerName: "Available Feature",
      flex: 0.75,
      renderCell: ({ row }) => {
        return row.featureStatus ? row.featureName.toUpperCase() : "-";
      },
    },
    {
      field: "lastSeen",
      headerName: "Last Seen",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "status",
      headerName: "Status",
      type: "boolean",
      flex: 0.5,
      renderCell: (d) => {
        return <StatusActiveInactive active={d.value} />;
      },
    },
  ];
};

const getVehicleManufacturedCols = () => {
  // Column props reference available at - ota-portal_v2\node_modules\.pnpm\@mui+x-data-grid@5.15.2_rrttaqilrsb3aifveu7wjrwxnm\node_modules\@mui\x-data-grid\models\colDef\gridColDef.d.ts
  return [
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      flex: 1,
    },
    {
      field: "vehicleCreationDate",
      headerName: "Vehicle Key Created At",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "feature",
      headerName: "Features",
      flex: 0.75,
      renderCell: ({ row }) => {
        return row?.feature?.toUpperCase() || "-";
      },
    },
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 1,
      renderCell: (d) => d.value || "-",
    },
    {
      field: "serialNoCreatedAt",
      headerName: "Serial No Created At",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "lastSeen",
      headerName: "Last seen",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
  ];
};

const getColumns = (type) => {
  if (type === "hu-manufactured-date") {
    return getHuManufacturedCols();
  } else if (type === "vehicle-manufactured-date") {
    return getVehicleManufacturedCols();
  } else if (type === "vehicle-purchased-date") {
    return getVehicleManufacturedCols();
  }
};
export { getColumns };
