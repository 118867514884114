import React from "react";
import { Box, Typography } from "@mui/material";

const UserNameFromUserId = ({ value }) => {
  return (
    <Box>
      <Typography sx={{ fontSize: "1em" }}>{value?.userName}</Typography>
    </Box>
  );
};

export { UserNameFromUserId };
