import React from "react";
import styled from "styled-components";
import { Button, Chip, Tooltip, Box, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";

const ActiveText = styled.span`
  color: #43a047;
`;

const InactiveText = styled.span`
  color: #c1c1c1;
`;

const getColumnsData = () => {
  // Column props reference available at - ota-portal_v2\node_modules\.pnpm\@mui+x-data-grid@5.15.2_rrttaqilrsb3aifveu7wjrwxnm\node_modules\@mui\x-data-grid\models\colDef\gridColDef.d.ts
  return [
    {
      field: "serialNo",
      headerName: "Serial No",
      width: 200,
      renderCell: (data) => {
        const { row: { serialNo = "" } = {} } = data;
        return (
          <Tooltip title={data?.value}>
            <Button
              size="medium"
              sx={{ justifyContent: "flex-start", textTransform: "none" }}
              component={RouterLink}
              to={`/Vehicles/${serialNo}`}
            >
              {data?.value}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: "active",
      headerName: "Status",
      type: "boolean",
      width: 80,
      renderCell: ({ row }) =>
        row.active ? (
          <ActiveText>Active</ActiveText>
        ) : (
          <InactiveText>Inactive</InactiveText>
        ),
    },
    {
      field: "certAvailable",
      headerName: "Certificate",
      type: "boolean",
      width: 100,
      renderCell: ({ row }) =>
        row.certAvailable ? (
          <ActiveText>Available</ActiveText>
        ) : (
          <InactiveText>Not Available</InactiveText>
        ),
    },
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      width: 220,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "serialNoCreatedAt",
      headerName: "HU Manufactured",
      width: 120,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.serialNoCreatedAt} />
      ),
    },
    {
      field: "vehicleKeyUpdatedAt",
      headerName: "Vehicle Manufactured",
      width: 120,
      editable: false,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.vehicleKeyUpdatedAt} />
      ),
    },
    {
      field: "vehicle.vehiclePurchaseDetails.purchaseDate",
      headerName: "Purchased",
      width: 120,
      editable: false,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell
          value={data?.row?.vehicle?.vehiclePurchaseDetails?.purchaseDate || ""}
        />
      ),
    },

    // {
    //   field: "thingTypeName",
    //   headerName: "Vehicle Type",
    //   width: 150,
    //   editable: false,
    //   renderCell: (data) => {
    //     const {
    //       row: {
    //         thingTypeName = "-",
    //         attributes: { device_type = "-" } = {},
    //       } = {},
    //     } = data;
    //     return thingTypeName;
    //   },
    // },

    // {
    //   field: "vehicle",
    //   headerName: "Created By",
    //   width: 100,
    //   editable: false,
    //   renderCell: (data) => data?.value?.createdBy,
    // },
    /*{
      field: "actions",
      headerName: "Actions",
      // width: 78,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (data) => {
        const { row: { serialNo = "" } = {} } = data;
        return (
          <Button
            size="small"
            component={RouterLink}
            to={`/Vehicles/${serialNo}`}
          >
            Details
          </Button>
        );
      },
    },*/
  ];
};

export { getColumnsData };
