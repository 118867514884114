import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import { getJobExecutionsForVehicles } from "../../services/vehicle";
import { getDeploymentVehicleLogDetails } from "../../services/deployment";
import { getColumnsData } from "./VehicleDeploymentColumns";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "../common/CustomToolbar";

const VehicleDeploymentList = (props = {}) => {
  let { vehicleId, deploymentId } = useParams();
  useQueryClient();
  const {
    vehicles = [],
    type,
    setErrMsg = () => {},
    setShowJobDocument,
  } = props;

  const [vehicle] = vehicles;

  const getFn =
    type === "vehicle"
      ? getJobExecutionsForVehicles
      : getDeploymentVehicleLogDetails;
  const queryArray =
    type === "vehicle"
      ? ["jobExecutionsForVehicle", deploymentId, vehicle?.vehicleKey]
      : ["vehicleLogDetails", deploymentId];

  const columns = getColumnsData({
    vehicleId,
    deploymentId,
    type,
  });

  // jobExecutions Queries
  let { data: { data: allVehicles = [] } = {}, isLoading: summaryLoading } =
    useQuery(
      queryArray,
      getFn, // to get the execution summary of the whole deployment
      {
        refetchOnWindowFocus: true,
        retry: 0,
        refetchInterval: 5000,
        onSuccess: () => {
          setShowJobDocument?.(true);
        },
        onError: (error) => {
          const { response: { data: { message = "" } = {} } = {} } = error;
          setErrMsg(message);
        },
      }
    );

  return (
    <Grid item xs={12}>
      <Box
        sx={
          type === "vehicle"
            ? {
                height: "auto",
                width: "100%",
              }
            : {}
        }
      >
        <DataGrid
          columnVisibilityModel={{
            vehicleKey: !vehicleId ? true : false,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          sx={
            type === "vehicle"
              ? {
                  minHeight: 300,
                  maxHeight: "calc(100vh - 232px)",
                  height: "auto",
                }
              : { minHeight: 400 }
          }
          loading={summaryLoading}
          columns={columns}
          rows={allVehicles}
          paginationMode="client"
          getRowId={(row) => row.deploymentLogId}
          {...(!vehicleId && { components: { Toolbar: CustomToolbar } })}
        />
      </Box>
    </Grid>
  );
};

export default VehicleDeploymentList;
