import React, { useState } from "react";
import { object, shape } from "prop-types";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import VehicleDeploymentList from "./VehicleDeploymentList";
import DeploymentJobStatus from "./DeploymentJobStatus";
import VehicleDeploymentListV1 from "./VehicleDeploymentListV1";
import DeploymentJobStatusV1 from "./DeploymentJobStatusV1";

const DeploymentVehicleTab = (props) => {
  const [showProgress, setShowProgress] = useState(false);
  let params = useParams();
  const theme = useTheme();
  const { deploymentDetails = {} } = props;
  const {
    vehicles = [],
    deploymentId = "",
    jobId = "",
    deploymentStatus = "",
    deploymentVersion = "V2",
  } = deploymentDetails || {};

  return (
    <Grid container spacing={2}>
      {deploymentVersion === "V1" ? (
        <>
          <DeploymentJobStatusV1 deploymentId={deploymentId} />
          <VehicleDeploymentListV1
            vehicles={vehicles}
            type="deployment"
            deploymentVersion={deploymentVersion}
          />
        </>
      ) : (
        <>
          <DeploymentJobStatus deploymentId={deploymentId} />
          <VehicleDeploymentList
            vehicles={vehicles}
            type="deployment"
            deploymentVersion={deploymentVersion}
          />
        </>
      )}
    </Grid>
  );
};

DeploymentVehicleTab.defaultProps = {
  deploymentDetails: shape({
    vehicles: [],
  }),
};

DeploymentVehicleTab.propTypes = {
  deploymentDetails: object,
};

export default DeploymentVehicleTab;
