import React, { useState } from "react";
import { Button } from "@mui/material";
import DeleteGroupConfirmDialog from "./group/DeleteGroupConfirmDialog";
import CreateVehicleGroup from "./group/CreateVehicleGroup";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";

const VehicleGroupDataTableRowActions = ({ data, listView = true }) => {
  const { row: { vehicleGroupId } = {} } = data || {};
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openStaticGroupCreateDialog, setOpenStaticGroupCreateDialog] =
    useState(false);
  const handleDelete = () => {
    setOpenDeleteDialog(data?.row);
  };
  const handleEdit = () => {
    setOpenStaticGroupCreateDialog(data?.row);
  };

  return (
    <div>
      <Check I={CAN.MANAGE} a={SUBJECTS.GROUP}>
        <Button size="small" onClick={() => handleEdit(vehicleGroupId)}>
          Edit
        </Button>
        <>
          {listView && <span>|</span>}
          <Button size="small" onClick={() => handleDelete()}>
            Delete
          </Button>
        </>

        {openDeleteDialog && (
          <DeleteGroupConfirmDialog
            id={openDeleteDialog?.vehicleGroupId}
            name={openDeleteDialog?.vehicleGroupName}
            type="vehicle group"
            toggleDialog={() => setOpenDeleteDialog(false)}
          />
        )}
        {openStaticGroupCreateDialog && (
          <CreateVehicleGroup
            open={openStaticGroupCreateDialog}
            toggleDialog={() =>
              setOpenStaticGroupCreateDialog(!openStaticGroupCreateDialog)
            }
          />
        )}
      </Check>
    </div>
  );
};

export default VehicleGroupDataTableRowActions;
