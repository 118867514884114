import React from "react";
import { capitalize } from "../../services/Utils";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import DeploymentStatus from "./DeploymentStatus";
import PublishDeployment from "./PublishDeployment";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DEPLOYMENT_STATUS, DEPLOYMENT_TYPES } from "../../services/Constants";

const DeploymentStatusInput = (props) => {
  return (
    <>
      <TextField
        label="Value"
        variant="standard"
        style={{ minWidth: 120 }}
        select
        SelectProps={{
          native: true,
        }}
        onChange={(event) =>
          props.applyValue({ ...props.item, value: event.target.value })
        }
      >
        {Object.entries(DEPLOYMENT_STATUS).map(([key, value]) => (
          <option key={key} value={value}>
            {value}
          </option>
        ))}
      </TextField>
    </>
  );
};

const DeploymentTypeInput = (props) => {
  return (
    <>
      <TextField
        label="Value"
        variant="standard"
        style={{ minWidth: 120 }}
        select
        SelectProps={{
          native: true,
        }}
        onChange={(event) =>
          props.applyValue({ ...props.item, value: event.target.value })
        }
      >
        {Object.entries(DEPLOYMENT_TYPES).map(([key, value]) => (
          <option key={key} value={value}>
            {value}
          </option>
        ))}
      </TextField>
    </>
  );
};

const getColumnsData = () => {
  const customFilterForDeploymentStatus = [
    {
      value: "equals",
      label: "Equals",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (params) => {
          return params.value.toLowerCase() === filterItem.value.toLowerCase();
        };
      },
      InputComponent: DeploymentStatusInput,
      InputComponentProps: { type: "string" },
    },
  ];

  const customFilterForDeploymentType = [
    {
      value: "equals",
      label: "Equals",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (params) => {
          return params.value.toLowerCase() === filterItem.value.toLowerCase();
        };
      },
      InputComponent: DeploymentTypeInput,
      InputComponentProps: { type: "string" },
    },
  ];

  return [
    {
      field: "deploymentName",
      headerName: "Deployment Name",
      flex: 2,
      editable: false,
    },
    {
      field: "release",
      headerName: "Release",
      flex: 1.5,
      editable: false,
      renderCell: (data) => data.value.releaseName,
    },
    {
      field: "campaignStartDate",
      headerName: "Campaign Start",
      sortable: true,
      flex: 0.75,
      type: "dateTime",
      renderCell: (data) => <DateAndTimeGridCell {...data} />,
    },
    {
      field: "campaignEndDate",
      headerName: "Campaign End",
      sortable: true,
      flex: 0.75,
      type: "dateTime",
      renderCell: (data) => <DateAndTimeGridCell {...data} />,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      sortable: true,
      flex: 0.75,
      valueFormatter: ({ value }) => capitalize(value),
    },
    {
      field: "campaignCreationDate",
      headerName: "Created On",
      sortable: true,
      flex: 0.75,
      type: "dateTime",
      renderCell: (data) => <DateAndTimeGridCell {...data} />,
    },
    {
      field: "deploymentStatus",
      headerName: "Status",
      flex: 0.75,
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      filterOperators: customFilterForDeploymentStatus,
      renderCell: (data) => {
        return <DeploymentStatus status={data.row.deploymentStatus} />;
      },
    },
    {
      field: "deploymentType",
      headerName: "Type",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      editable: false,
      align: "center",
      filterOperators: customFilterForDeploymentType,
      renderCell: ({ row }) => {
        const type = row?.deploymentType || "snapshot";
        return <Typography variant="caption">{type.toUpperCase()}</Typography>;
      },
    },
    {
      field: "none",
      headerName: "Actions",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (data) => {
        return <PublishDeployment data={data} />;
      },
    },
  ];
};

export { getColumnsData };
